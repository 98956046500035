(function () {
  app.controller("OrderShowCtrl", [
    "$scope",
    "$timeout",
    "$analytics",
    "$http",
    "$interval",
    function ($scope, $timeout, $analytics, $http, $interval) {
      $scope.user = null;
      $scope.evidenceFileList = [];
      $scope.order = null;
      $scope.evidenceUploadFiles = [];
      $scope.disputes = [];
      $scope.isSubmitingRepresentment = false;
      $scope.disputesSelection = [];
      $scope.evidenceSource = {
        active: "portal_order",
        options: [
          { value: "portal_order", label: "Portal - Order" },
          { value: "google_drive", label: "Google Drive" },
          { value: "zoho_crm", label: "ZOHO CRM" },
        ],
      };
      $scope.evidenceType = {
        active: "cancellation_policy",
        options: [
          {
            value: "cancellation_policy",
            label: "Cancellation policy",
            disabled: false,
          },
          {
            value: "customer_communication",
            label: "Customer communication",
            disabled: false,
          },
          {
            value: "customer_signature",
            label: "Customer signature",
            disabled: false,
          },
          {
            value: "duplicate_charge_documentation",
            label: "Duplicate charge document",
            disabled: false,
          },
          { value: "receipt", label: "Receipt", disabled: false },
          { value: "refund_policy", label: "Refund policy", disabled: false },
          {
            value: "service_documentation",
            label: "Service documentation",
            disabled: false,
          },
          {
            value: "shipping_documentation",
            label: "Shipping documentation",
            disabled: false,
          },
          { value: "uncategorized_file", label: "Other", disabled: false },
        ],
      };

      $scope.getStatusConfig = (status) => {
        return (
          {
            1: { label: "OPEN", color: "danger" },
            2: { label: "PENDING", color: "orange" },
            3: { label: "WON", color: "success" },
            4: { label: "LOST", color: "purple" },
          }[status] || {
            label: status?.toUpperCase() || "UNKNOWN",
            color: "gray",
          }
        );
      };

      $scope.getUserName = () =>
        $scope.user?.first_name + " " + $scope.user?.last_name;

      $scope.onReady = async () => {
        $scope.updateDisputeAndEvidenceList();
      };

      $scope.sortDefenseDocumentTypes = (defenseDocumentTypes) => {
        const requirementOrder = {
          Required: 1,
          AlternativeRequired: 2,
          Optional: 3,
        };
        defenseDocumentTypes.sort((a, b) => {
          const requirementA =
            requirementOrder[a.requirementLevel] || Number.MAX_SAFE_INTEGER;
          const requirementB =
            requirementOrder[b.requirementLevel] || Number.MAX_SAFE_INTEGER;
          if (a.defenseDocumentTypeCode === "AlternativeDefenseMaterial") {
            return -1;
          }
          if (b.defenseDocumentTypeCode === "AlternativeDefenseMaterial") {
            return 1;
          }
          return requirementA - requirementB;
        });
        return defenseDocumentTypes;
      };

      $scope.differentiateSelectedDisputes = async () => {
        const selectedDisputes = $scope.disputesSelection
          .map((disputeId) => {
            return $scope.disputes.find(({ id }) => id === disputeId);
          })
          .filter((x) => !!x);
        const selectedStripeDisputes = selectedDisputes.filter(
          ({ platform }) => platform === "stripe"
        );
        const selectedAdyenDisputes = selectedDisputes.filter(
          ({ platform }) => platform === "adyen"
        );
        let selectedAdyenSingleReasonDisputes = [];
        let selectedAdyenMultipleReasonsDisputes = [];
        try {
          const adyenDisputesInfo = await Promise.all(
            selectedAdyenDisputes.map(({ extern_id }) =>
              window.evidenceApi
                .retrieveAdyenInfo({ extern_id })
                .then((res) => res.data)
            )
          );
          adyenDisputesInfo.forEach(({ defenseReasons }, index) => {
            const adyenDispute = selectedAdyenDisputes[index];
            if (
              defenseReasons.length === 1 &&
              defenseReasons[0].defenseDocumentTypes?.length === 1
            ) {
              const defenseReason = defenseReasons[0];
              const defenseDocumentType = defenseReason.defenseDocumentTypes[0];
              selectedAdyenSingleReasonDisputes.push({
                ...adyenDispute,
                defense_reason_code: defenseReason.defenseReasonCode,
                defense_document_type_code:
                  defenseDocumentType.defenseDocumentTypeCode,
              });
            } else if (
              defenseReasons.length > 1 ||
              (defenseReasons.length === 1 &&
                defenseReasons[0].defenseDocumentTypes.length > 1)
            ) {
              const defenseDocumentTypes = defenseReasons.reduce(
                (accu, curr) => {
                  accu[curr.defenseReasonCode] =
                    $scope.sortDefenseDocumentTypes(curr.defenseDocumentTypes);
                  return accu;
                },
                {}
              );
              const defaultSelectedDefenseReasonCode =
                defenseReasons[0].defenseReasonCode;
              const defaultSelectedDefenseDocumentTypeCode =
                defenseDocumentTypes[defaultSelectedDefenseReasonCode][0]
                  .defenseDocumentTypeCode;
              selectedAdyenMultipleReasonsDisputes.push({
                ...adyenDispute,
                defenseReasons,
                defenseDocumentTypes,
                selectedDefenseReasonCode: defaultSelectedDefenseReasonCode,
                selectedDefenseDocumentTypeCode:
                  defaultSelectedDefenseDocumentTypeCode,
              });
            }
          });
        } catch (err) {
          toastr.error(
            `Failed to retrieve Adyen information${
              err.message ? `, ${err.message}` : ""
            }`
          );
        }
        return {
          selectedStripeDisputes,
          selectedAdyenSingleReasonDisputes,
          selectedAdyenMultipleReasonsDisputes,
        };
      };

      $scope.handleSelectedDefenseReasonCodeChange = (currDispute) => {
        const firstRequiredDefenseDocumentType =
          currDispute.defenseDocumentTypes?.[
            currDispute.selectedDefenseReasonCode
          ][0];
        if (
          firstRequiredDefenseDocumentType &&
          firstRequiredDefenseDocumentType.defenseDocumentTypeCode
        ) {
          currDispute.selectedDefenseDocumentTypeCode =
            firstRequiredDefenseDocumentType.defenseDocumentTypeCode;
        }
      };

      $scope.submitRepresentment = async () => {
        if ($scope.isSubmitingRepresentment) return;
        if (!$scope.disputesSelection.length) {
          toastr.info("Please select at least one dispute");
          return;
        }
        try {
          $scope.isSubmitingRepresentment = true;
          const {
            selectedStripeDisputes,
            selectedAdyenSingleReasonDisputes,
            selectedAdyenMultipleReasonsDisputes,
          } = await $scope.differentiateSelectedDisputes();
          // Submit Stripe disputes
          if (selectedStripeDisputes?.length) {
            await Promise.all(
              selectedStripeDisputes.map(({ id, platform }) => {
                return window.evidenceApi.sendRepresentment({
                  order_number: $scope.order.order_number,
                  event_id: $scope.order.event_id,
                  dispute_id: id,
                  platform,
                });
              })
            );
          }
          // Submit adyen single-reason & single-documentType disputes
          if (selectedAdyenSingleReasonDisputes?.length) {
            await Promise.all(
              selectedAdyenSingleReasonDisputes.map(
                ({
                  id,
                  platform,
                  defense_reason_code,
                  defense_document_type_code,
                }) => {
                  return window.evidenceApi.sendRepresentment({
                    order_number: $scope.order.order_number,
                    event_id: $scope.order.event_id,
                    dispute_id: id,
                    platform,
                    defense_reason_code,
                    defense_document_type_code,
                  });
                }
              )
            );
          }
          // Ask admin to select reason and documentType before submitting
          $scope.selectedAdyenMultipleReasonsDisputes =
            selectedAdyenMultipleReasonsDisputes;
          if (selectedAdyenMultipleReasonsDisputes?.length) {
            $("#representment-modal").modal("show");
            $scope.$apply();
          } else {
            $scope.isSubmitingRepresentment = false;
            toastr.success("Representment submitted");
          }
        } catch (err) {
          console.error(err);
          toastr.error("Failed to submit representment");
          $scope.isSubmitingRepresentment = false;
        } finally {
          if (!$scope.selectedAdyenMultipleReasonsDisputes?.length) {
            // clear selection
            $scope.disputesSelection = [];
            $scope.$apply();
            $scope.updateDisputeAndEvidenceList();
          }
        }
      };

      $scope.submitMultipleChoicesAdyenRepresentment = async () => {
        try {
          await Promise.all(
            $scope.selectedAdyenMultipleReasonsDisputes.map(
              ({
                id,
                platform,
                selectedDefenseReasonCode,
                selectedDefenseDocumentTypeCode,
              }) => {
                return window.evidenceApi.sendRepresentment({
                  order_number: $scope.order.order_number,
                  event_id: $scope.order.event_id,
                  dispute_id: id,
                  platform,
                  defense_reason_code: selectedDefenseReasonCode,
                  defense_document_type_code: selectedDefenseDocumentTypeCode,
                });
              }
            )
          );
          toastr.success("Representment submitted");
        } catch {
          toastr.error("Failed to submit representment");
        } finally {
          $scope.isSubmitingRepresentment = false;
          $scope.disputesSelection = [];
          $scope.$apply();
          $scope.updateDisputeAndEvidenceList();
        }
      };

      $scope.getIsSelectedAllDispute = () => {
        const canSelect = $scope.disputes.filter(
          (dispute) => !dispute.disabled
        );
        if (!canSelect.length) return false;
        return canSelect.every(function (dispute) {
          return $scope.disputesSelection.includes(dispute.id);
        });
      };

      $scope.toggleAllDisputeSelection = () => {
        if ($scope.getIsSelectedAllDispute()) {
          $scope.disputesSelection = [];
        } else {
          const canSelect = $scope.disputes.filter(
            (dispute) => !dispute.disabled
          );
          $scope.disputesSelection = canSelect.map(function (dispute) {
            return dispute.id;
          });
        }
      };

      $scope.toggleDisputeSelection = (disputeId) => {
        const index = $scope.disputesSelection.indexOf(disputeId);
        const dispute = $scope.disputes.find(
          (dispute) => dispute.id === disputeId
        );
        if (dispute.disabled) return;
        if (index > -1) {
          $scope.disputesSelection.splice(index, 1);
        } else {
          $scope.disputesSelection.push(disputeId);
        }
      };

      $scope.handleAddEvidenceClick = () => {
        $("#disputes-modal").modal("show");
        $(".loading").fadeOut();
      };

      $scope.getEvidenceFolderName = () => {
        return `orders/${($scope.order && $scope.order.order_number) || ""}`;
      };

      $scope.previewEvidenceFile = async (file) => {
        const res = await window.evidenceApi.getDownloadUrl({
          path: file.path,
        });
        const url = res.data?.url;
        window.open(url, "_blank");
      };

      $scope.downloadEvidenceFile = async (file) => {
        try {
          $(".loading").fadeIn();
          const res = await window.evidenceApi.getDownloadUrl({
            path: file.path,
          });
          const url = res.data?.url;
          const blobUrl = await fetch(url)
            .then((r) => r.blob())
            .then((blobFile) => URL.createObjectURL(blobFile));
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = file.name;
          a.click();
          a.remove();
        } catch (err) {
          toastr.error("Failed to download" + err);
        } finally {
          $(".loading").fadeOut();
        }
      };

      $scope.onEvidenceUploadFileSelected = (inputEl) => {
        const fileList = Array.from(inputEl.files);
        if (!fileList.length) return;
        $scope.evidenceUploadFiles.push(...fileList);
        $scope.$apply();
      };

      // for upload modal --> remove button
      $scope.removeEvidenceUploadFile = (file) => {
        const index = $scope.evidenceUploadFiles.indexOf(file);
        if (index < 0) return;
        $scope.evidenceUploadFiles.splice(index, 1);
        if (!$scope.$$phase) $scope.$apply();
      };

      $scope.uploadEvidenceFiles = async () => {
        if (!$scope.evidenceUploadFiles.length) return;

        const newFileList = $scope.evidenceUploadFiles.map((file) => {
          const [name, ext] = file.name.match(/(.+)\.(\w+)$/).slice(1);
          const hashFilename = window.cyrb53(name) + "." + ext;
          return {
            ...file,
            name: hashFilename,
            ext,
            path: $scope.getEvidenceFolderName() + "/" + hashFilename,
            hashFilename: hashFilename,
            sourceFilename: file.name,
            source: file,
          };
        });

        try {
          await window.disputeAwsUtils.uploadFiles({
            fileList: newFileList,
            folderName: $scope.getEvidenceFolderName(),
          });

          await Promise.all(
            newFileList.map((item) => {
              return window.evidenceApi.addOrderEvidence({
                order_number: $scope.order.order_number,
                event_id: $scope.order.event_id,
                path: item.path,
                name: item.sourceFilename,
                added_by: $scope.getUserName(),
                source: $scope.evidenceSource.active,
                type: $scope.evidenceType.active,
                file_format: item.ext || "null",
              });
            })
          );
          await $scope.updateDisputeAndEvidenceList();
          toastr.success("Evidence files uploaded");
          // remove uploaded files
          $scope.evidenceUploadFiles = [];
        } catch (err) {
          console.warn(err);
          toastr.error("Failed to upload files");
        }
      };

      // for table --> delete icon button
      $scope.deleteEvidenceFile = (file) => {
        if (file.disabledDelete) {
          toastr.error("Please delete the evidence in event level page");
          return;
        }

        bootbox.confirm("Are you sure delete?", async function (rs) {
          if (rs) {
            try {
              await window.evidenceApi.deleteOrderEvidence({
                order_number: $scope.order.order_number,
                evidence_id: file.id,
              });
              toastr.success("Deleted");
              // update list
              await $scope.updateDisputeAndEvidenceList();
            } catch (err) {
              console.warn(err);
              toastr.error("Failed to delete");
            }
          }
        });
      };

      $scope.getDisputeExternId = (dispute) => {
        if (dispute.platform === "adyen") {
          return dispute.extern_id;
        }
        return dispute.extern_charge_id;
      };

      $scope.updateDisputeAndEvidenceList = async () => {
        const res = await window.evidenceApi.getDisputeList({
          order_number: $scope.order && $scope.order.order_number,
          event_id: $scope.order?.event_id,
        });

        const actableTypeInfoMap = {
          creditCard: {
            getName: (dispute) =>
              `Credit Card ( ${$scope.getDisputeExternId(dispute)} )`,
            getLink: (dispute) => {
              const externId = $scope.getDisputeExternId(dispute);
              const { platform } = dispute;

              if (platform === "adyen") {
                if ($scope.isProduction) {
                  return `https://ca-live.adyen.com/ca/ca/accounts/showTx.shtml?txType=Dispute&pspReference=${externId}`;
                }
                return `https://ca-test.adyen.com/ca/ca/accounts/showTx.shtml?txType=Dispute&pspReference=${externId}`;
              }
              if (platform === "stripe") {
                if ($scope.isProduction) {
                  return `https://dashboard.stripe.com/payments/${externId}`;
                }
                return `https://dashboard.stripe.com/test/payments/${externId}`;
              }
            },
          },
        };

        $scope.disputes = res.data?.disputes?.map((dispute) => {
          const actableType = "creditCard";
          const actableInfo = actableTypeInfoMap[actableType];
          const isSubmitted = !!dispute.submitted_at;
          const isFraud = dispute.status === "NOTIFICATION_OF_FRAUD";
          const isNotDefendable = dispute.note?.defendable === false;
          const isClosed = /^(won|lost)$/i.test(dispute.status);
          const disabled = [
            isSubmitted,
            isFraud,
            isNotDefendable,
            isClosed,
          ].some(Boolean);
          const autoDefended = dispute.note?.auto_defended === true;
          const submittedAt = (() => {
            let tempText = "-";
            if (isFraud) {
              tempText = "No need to defend";
            }
            if (isNotDefendable) {
              tempText = "Not defendable";
            }
            if (isSubmitted) {
              tempText = moment(dispute.submitted_at).format(
                "YYYY-MM-DD HH:mm"
              );
            }
            if (autoDefended) {
              tempText = `${tempText} 🤖`;
            }
            return tempText;
          })();
          const respondBy = (() => {
            if (dispute.due_at) {
              return moment(dispute.due_at).format("YYYY-MM-DD");
            }
            return "-";
          })();

          return {
            ...dispute,
            disabled,
            submittedAt,
            respondBy,
            details: {
              name: actableInfo.getName(dispute),
              link: actableInfo.getLink(dispute),
            },
            note: dispute.note || {},
          };
        });

        // when disputes length > 0, open the order disputed switch
        if (!$scope.order.disputed && $scope.disputes.length > 0) {
          // document.querySelector('label[for="order_disputed"]').click();
          $scope.order.disputed = true;
        }

        const disabledEvidenceTypeOptions = new Set();
        $scope.evidenceFileList = res.data?.evidences?.map((file) => {
          const sourceFormatName =
            [
              ...$scope.evidenceSource.options,
              {
                value: "portal_event",
                label: "Portal - Event",
              },
            ].find((item) => item.value === file.source)?.label || "Other";

          const typeFormatName =
            $scope.evidenceType.options.find((item) => item.value === file.type)
              ?.label || "Other";
          disabledEvidenceTypeOptions.add(file.type);
          return {
            ...file,
            name: file.name,
            ext: file.file_format || "null",
            path: file.s3_path,
            createdAt: moment(file.created_at).format("YYYY-MM-DD"),
            disabledDelete: file.source === "portal_event",
            sourceFormatName,
            typeFormatName,
          };
        });

        // update evidenceType options disabled
        let firstActiveType = null;
        $scope.evidenceType.options = $scope.evidenceType.options.map(
          (item) => {
            const disabled = disabledEvidenceTypeOptions.has(item.value);
            if (!firstActiveType && !disabled) firstActiveType = item.value;
            return {
              ...item,
              disabled,
            };
          }
        );
        $scope.evidenceType.active = firstActiveType;

        $scope.$apply();
      };

      $scope.toastNotImplemented = () => {
        toastr.error("Not implemented yet, but coming soon!");
      };

      const ANY_DRAWER_DEFAULT = {
        $name: "ANY_DRAWER_DEFAULT",
        $self: null,
        $container: null,
        init() {
          this.$self.on("click", (event) => {
            if (event.target === this.$self[0]) {
              this.close();
            }
          });
        },
        open(args) {
          $scope.$broadcast(`${this.$name}.beforeShow`, args);
          this.$self.removeClass("d-none");
          this.$container.removeClass("slide-out-right");
          this.$container.addClass("slide-in-right");
          $scope.$broadcast(`${this.$name}.afterShow`, args);
        },
        close(args) {
          $scope.$broadcast(`${this.$name}.beforeHide`, args);
          this.$container.removeClass("slide-in-right");
          this.$container.addClass("slide-out-right");
          this.$container.one("animationend", () => {
            this.$self.addClass("d-none");
            $scope.$broadcast(`${this.$name}.afterHide`, args);
          });
        },
      };

      const ANY_ROOMS_SUB_DRAWER_DEFAULT = {
        ...ANY_DRAWER_DEFAULT,
        open(args) {
          $scope.modifyRoomsDrawer.$container.animate(
            {
              right: this.$container.css("width"),
            },
            300,
            "easeInCubic"
          );
          ANY_DRAWER_DEFAULT.open.call(this, args);
        },
        close(args) {
          ANY_DRAWER_DEFAULT.close.call(this, args);
          if (args?.isSubstitute) return;
          $scope.modifyRoomsDrawer.$container.animate(
            {
              right: 0,
            },
            300,
            "easeOutCubic"
          );
        },
      };

      $scope.modifyRoomsDrawer = {
        ...ANY_DRAWER_DEFAULT,
        $name: "modifyRoomsDrawer",
        $self: $("#order_modify_rooms_drawer"),
        $container: $("#order_modify_rooms_drawer__container"),
        async open(type) {
          switch (type) {
            case "order-consolidate-rooms":
              $scope.$broadcast("order-consolidate-rooms");
              break;
            case "order-remove-and-rebook-rooms":
              $scope.$broadcast("order-remove-and-rebook-rooms");
              break;
            case "order-modify-travelers":
              $scope.$broadcast("order-modify-travelers");
              break;
            default:
              break;
          }
          ANY_DRAWER_DEFAULT.open.call(this);
        },
      };
      $scope.modifyRoomsDrawer.init();

      $scope.availableRoomsDrawer = {
        ...ANY_ROOMS_SUB_DRAWER_DEFAULT,
        $name: "availableRoomsDrawer",
        $self: $("#order_available_rooms_drawer"),
        $container: $("#order_available_rooms_drawer__container"),
        /**
         * Available Rooms
         */
        openAvailableRoomsDrawer() {
          $scope.$broadcast("order-available-rooms");
          this.open();
        },
      };
      $scope.availableRoomsDrawer.init();

      $scope.updateMembersAndRoomsDrawer = {
        ...ANY_DRAWER_DEFAULT,
        $name: "updateMembersAndRoomsDrawer",
        $self: $("#update_members_and_rooms_drawer"),
        $container: $("#update_members_and_rooms_drawer__container"),
      };
      $scope.updateMembersAndRoomsDrawer.init();

      $scope.addTravelerDrawer = {
        ...ANY_ROOMS_SUB_DRAWER_DEFAULT,
        $name: "addTravelerDrawer",
        $self: $("#add_traveler_drawer"),
        $container: $("#add_traveler_drawer__container"),
      };
      $scope.addTravelerDrawer.init();

      $scope.removeTravelerDrawer = {
        ...ANY_ROOMS_SUB_DRAWER_DEFAULT,
        $name: "removeTravelerDrawer",
        $self: $("#remove_traveler_drawer"),
        $container: $("#remove_traveler_drawer__container"),
      };
      $scope.removeTravelerDrawer.init();

      $scope.modifyAddonsDrawer = {
        ...ANY_DRAWER_DEFAULT,
        $name: "modifyAddonsDrawer",
        $self: $("#order_modify_addons_drawer"),
        $container: $("#order_modify_addons_drawer__container"),
      };
      $scope.modifyAddonsDrawer.init();
    },
  ]);
}.call(this));
