(function () {
  app.controller("InvitationsCtrl", [
    "$scope",
    "$timeout",
    "$http",
    function ($scope, $timeout, $http) {
      $scope.accountPattern = {
        emailFormat:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        password_min: 6,
        password_max: 128,
      };
      $scope.payment_option = 1; // 0: Pay everything today, 1: Pay installment
      $scope.payment_plan_selected_option = 0; // Payment plan option between 0 (default) 2 depending on payment plan selected
      $scope.error_message = "";
      $scope.amount = 0; // for summary of each person in split bill // paid mine also for split
      $scope.save_personal_info = true;
      $scope.payment_plans = [];
      $scope.payment_plan_names = [];
      $scope.affirm_position_plan = -1;
      $scope.credit_card = {};
      $scope.billing_address = {};
      $scope.shipping_address = {};
      $scope.billingStates = {};
      $scope.shippingStates = {};
      $scope.include_insurance = false;
      $scope.copy_billing_address = false;
      $scope.event_additional_fees = {};
      $scope.hotel_additional_fees = {};
      $scope.addon_additional_fees = {};
      $scope.event_booking_path = "";
      $scope.payment_data = {
        order_price: 0,
        grand_amount: 0,
        tax_amount: 0,
        processing_fee: 0,
        insurance_amount: 0,
        payment_amount: 0,
      };
      $scope.account_form = {
        same_info_billing: false,
        signup_user: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
          active: true,
        },
        signin_user: {
          email: "",
          password: "",
        },
      };
      $scope.password_reset = {
        email: "",
      };
      $scope.show_signup = true;
      $scope.show_signin = false;
      $scope.show_password_reset = false;
      $scope.signin_error_msg = "";
      $scope.signup_error_msg = {};
      $scope.password_reset_msg = null;
      $scope.password_reset_error_msg = {};
      $scope.terms = false;
      $scope.booking_conditions = false;
      $scope.privacy_policy = false;
      $scope.travel_packages = false;
      $scope.groupedSelectedTickets = {};
      $scope.user_locale = 'en';
      $scope.event_currency = window.event_currency || 'USD';

      $scope.init = function () {
        $scope.acceptInvitation();
      };

      $scope.showSignin = function () {
        $scope.show_signin = true;
        $scope.show_signup = false;
        $scope.show_password_reset = false;
      };
      $scope.showSignup = function () {
        $scope.show_signin = false;
        $scope.show_signup = true;
        $scope.show_password_reset = false;
      };
      $scope.showPasswordReset = function () {
        $scope.show_signin = false;
        $scope.show_signup = false;
        $scope.show_password_reset = true;
      };

      //========Watch=============
      $scope.$watchGroup(
        [
          "copy_billing_address",
          "billing_address.full_name",
          "billing_address.phone_number",
          "billing_address.address_1",
          "billing_address.address_2",
          "billing_address.city",
          "billing_address.zipcode",
          "billing_address.state_id",
        ],
        function () {
          if ($scope.copy_billing_address) {
            $scope.shipping_address = angular.copy($scope.billing_address);
          }
        }
      );
      //========== end ===============

      $scope.sendPasswordReset = function () {
        const isValidEmail = $scope.accountPattern.emailFormat.test(
          $scope.password_reset.email
        );
        if (isValidEmail) {
          $.post(
            "/users/password",
            {
              user: $scope.password_reset,
              json: true,
              invitation_key: $scope.split_order.invitation_key,
            },
            function (rs) {
              $timeout(function () {
                if (rs.success) {
                  window.localStorage.setItem("password_reset", true);
                  location.reload();
                } else {
                  $scope.password_reset_msg = rs.message;
                }
              });
            }
          );
        } else {
          $scope.password_reset_error_msg.email = "Email is invalid";
        }
      };

      function rebuildPaymentPlans() {
        if ($scope.payment_option == $scope.payment_options.PAY_INSTALLMENT) {
          if ($scope.payment_plans.length > 1) {
            $.each($scope.payment_plans, function (i, x) {
              x.amount = (
                ($scope.payment_data.payment_amount * x.percentage) /
                100
              ).toFixed(2);
            });
          }
        }
      }

      $scope.groupSelectedTickets = function () {
        if ($scope.layout) {
          $scope.groupedSelectedTickets = $scope.layout.reduce((r, a) => {
            var section_name = "";
            var row_array = [];
            $scope.seating_sections.forEach((section) => {
              if (section.id == a.seating_section_id) {
                section_name = section.name;
                $scope.layout.forEach((seat) => {
                  if (
                    seat.row == a.row &&
                    seat.seating_section_id == a.seating_section_id
                  ) {
                    row_array.push(seat);
                  }
                });
              }
            });
            r[section_name] = {
              ...(r[section_name] || []),
              [a.row]: row_array,
            };
            return r;
          }, {});
        }
      };

      $scope.selectPaymentOption = function (option) {
        $scope.payment_option = option;
      };

      $scope.selectPaymentPlanOption = function (option, plan_option) {
        $scope.payment_plan_selected_option = plan_option;
        $scope.payment_option = option;
      };

      $scope.acceptInvitation = function () {
        $.post(
          "/invitations/" + $scope.split_order_id + "/accept_invitation",
          function (rs) {
            $timeout(function () {
              if (rs.succeed) {
              } else {
                bootbox.alert(rs.message);
              }
            });
          }
        );
      };

      $scope.doPayment = function () {
        var isPrecznAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.PRECZN && $scope.credit_card.is_new_one;
        var isStripeAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.STRIPE && $scope.credit_card.is_new_one;
        var isBraintreeAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.BRAINTREE && $scope.credit_card.is_new_one;
        var isAuthorize_netAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.AUTHORIZE_NET && $scope.credit_card.is_new_one;

        $scope.error_message = "";
        if (!$scope.current_user && !validUserInfo()) {
          $scope.error_message = "Your Account info is invalid!";
          return bootbox.alert($scope.error_message);
        }
        if (!$scope.stripe_stress_test && isPrecznAndNewCard) {
          $scope.credit_card = $scope.validatePrecznCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();
          var callbackFn = function (credit_card) {
            if ($.trim(credit_card.preczn_single_use_token) == '' || credit_card.error_message) {
              $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
              $('.loading').fadeOut();
              bootbox.alert($scope.error_message);
              return false;
            }
  
            checkoutOrder();
          }
          $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
        } else if (!$scope.stripe_stress_test && isStripeAndNewCard) {
          $scope.credit_card = $scope.validateCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();
          var callbackFn = function (credit_card) {
            if ($.trim(credit_card.token) == '' || credit_card.error_message) {
              $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
              $('.loading').fadeOut();
              bootbox.alert($scope.error_message);
              return false;
            }
  
            checkoutOrder();
          }
          $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
        } else if (!$scope.stripe_stress_test && isBraintreeAndNewCard) {
          braintree.instance.requestPaymentMethod(function (
            requestPaymentMethodErr,
            payload
          ) {
            $scope.payment_method_nonce = payload.nonce;
            checkoutOrder();
          });
        } else if (!$scope.stripe_stress_test && isAuthorize_netAndNewCard) {
          fuseAuthorizeNetCheck(({ valid, token }) => {
            if (valid) {
              $scope.payment_method_nonce = token.dataValue;
              checkoutOrder();
            }
          });
        } else {
          checkoutOrder();
        }
      };

      $scope.toggleShowLogin = function () {
        $scope.show_signin = !$scope.show_signin;
      };

      $scope.logOut = function () {
        $.ajax({
          url: "/users/sign_out",
          type: "DELETE",
          success: function (result) {
            location.reload();
          },
          error: function (response) {
            alert("Error. while deleting user Try Again!");
          },
        });
      };

      $scope.rebuildSplitOrderItems = function () {
        Object.keys($scope.split_order_items).forEach((product_type) => {
          $scope.split_order_items[product_type].map((item) => {
            if (
              item.product_description &&
              item.product_description.length > 90
            ) {
              item.description_text =
                item.product_description.slice(0, 90) + "...";
              item.desc_read_more = true;
            }
            return item;
          });
        });
      };

      $scope.toggleCopyBillingAddress = function () {
        $scope.copy_billing_address = !$scope.copy_billing_address;
        $scope.shippingStates = $scope.billingStates;
        // reset these fields
        if (!$scope.copy_billing_address) {
          $scope.shipping_address = {};
        }
      };

      $scope.getBillingStates = function (country_id) {
        $.post(
          $scope.event_booking_path + "/get_states",
          { country_id: country_id },
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.billingStates = rs.states;
              } else {
                console.error(rs.message);
              }
            });
          }
        );
      };

      $scope.getShippingStates = function (country_id) {
        $.post(
          $scope.event_booking_path + "/get_states",
          { country_id: country_id },
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.shippingStates = rs.states;
              } else {
                console.error(rs.message);
              }
            });
          }
        );
      };

      function validUserInfo() {
        if ($scope.show_signin) {
          return validateSignin();
        }
        return validateSignup();
      }

      function validateSignin() {
        const { email, password } = $scope.account_form.signin_user;
        const valid =
          email?.length &&
          $scope.accountPattern.emailFormat.test(email) &&
          password?.length;
        $scope.signin_error_msg =
          (!valid && "Invalid username or password.") || "";
        return valid;
      }

      function validateSignup() {
        const {
          first_name,
          last_name,
          email,
          password,
          password_confirmation,
        } = $scope.account_form.signup_user;

        $scope.signup_error_msg.first_name =
          (!first_name?.length && ["cannot be blank"]) || "";
        $scope.signup_error_msg.last_name =
          (!last_name?.length && ["cannot be blank"]) || "";
        $scope.signup_error_msg.email =
          (!email?.length && ["cannot be blank"]) ||
          ($scope.accountPattern.emailFormat.test(email) === false && [
            "is invalid",
          ]) ||
          "";
        $scope.signup_error_msg.password =
          (!password?.length && ["cannot be blank"]) ||
          (password.length < $scope.accountPattern.password_min && [
            `is too short (minimum is ${$scope.accountPattern.password_min} characters)`,
          ]) ||
          (password.length > $scope.accountPattern.password_max && [
            `is too long (maximum is ${$scope.accountPattern.password_max} characters)`,
          ]) ||
          "";
        $scope.signup_error_msg.password_confirmation =
          (!password_confirmation?.length && ["cannot be blank"]) ||
          (password !== password_confirmation && ["do not match password"]) ||
          "";

        return Object.values($scope.signup_error_msg).every(
          (item) => item.length === 0
        );
      }
      function checkoutOrder() {      
        var opt = {
          invitation_id: $scope.split_order.id,
          event_id: $scope.event.id,
          token: $scope.braintree.active
            ? $scope.credit_card.fingerprint
            : $scope.credit_card.token,
          preczn_single_use_token: (!$scope.credit_card.is_new_one && $scope.credit_card.preczn_multi_use_token) 
          ? null 
          : $scope.credit_card.preczn_single_use_token,
          stripe_customer_id: $scope.credit_card.stripe_customer_id,
          braintree_payment_method_nonce: $scope.braintree_payment_method_nonce,
          credit_card_id: $scope.credit_card.id,
          card_name: $scope.credit_card.name,
          payment_option: $scope.payment_option,
          payment_plan_selected_option: $scope.payment_plan_selected_option,
          payment_data: $scope.payment_data,
          payment_method_nonce: $scope.payment_method_nonce,
          include_insurance: $scope.include_insurance,
          billing_address: $scope.billing_address,
          shipping_address: $scope.shipping_address,
          user_locale: window.getLocalizeLang(),
          user:
            ($scope.show_signin && $scope.account_form.signin_user) ||
            $scope.account_form.signup_user,
        };

        if (typeof diagnostics_service != "undefined") {
          opt = Object.assign(opt, diagnostics_service);
        }

        $.post(
          "/invitations/" + $scope.split_order_id + "/checkout",
          opt,
          function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                location.href = "/dashboard" + '?language=' + window.getLocalizeLang();
              } else {
                if (!$scope.current_user) {
                  if (rs.type === "signin") {
                    $scope.signin_error_msg = rs.message;
                  } else if (rs.type === "signup") {
                    $scope.signup_error_msg = rs.message;
                  }
                }

                if (
                  $scope.event.payment_gateway ==
                  $scope.payment_gateways.BRAINTREE
                ) {
                  braintree.instance.clearSelectedPaymentMethod();
                }

                if (typeof rs.message === "string") {
                  var err_message = rs.message;
                } else {
                  var err_message = `${
                    Object.keys(rs.message)[0][0].toUpperCase() +
                    Object.keys(rs.message)[0].slice(1)
                  } ${Object.values(rs.message)[0][0]}`;
                }
                bootbox.alert(err_message);
              }
              $(".loading").fadeOut();
            });
          }
        );
      }
      $(document).ready(function () {
        $scope.groupSelectedTickets();

        if (Object.keys($scope.billing_address).length > 1) {
          $scope.getBillingStates($scope.billing_address.country_id);
        }
        if (Object.keys($scope.shipping_address).length > 1) {
          $scope.getShippingStates($scope.shipping_address.country_id);
        }
        var billing_phone_input = document.querySelector(
            "#billing_address_phone_number"
          ),
          errorMsgBilling = document.querySelector("#error-msg-billing"),
          validMsgBilling = document.querySelector("#valid-msg-billing");

        var errorMap = [
          "Invalid number",
          "Invalid country code",
          "Too short",
          "Too long",
          "Invalid number",
        ];
        var iti = window.intlTelInput(billing_phone_input, {
          initialCountry: "us",
          nationalMode: true,
          utilsScript:
            "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544",
        });
        var reset = function () {
          billing_phone_input.classList.remove("error");
          errorMsgBilling.innerHTML = "";
          errorMsgBilling.classList.add("hidden");
          validMsgBilling.classList.add("hidden");
        };
        billing_phone_input.addEventListener("blur", function () {
          reset();
          if (billing_phone_input.value.trim()) {
            if (iti.isValidNumber()) {
              validMsgBilling.classList.remove("hidden");
            } else {
              billing_phone_input.classList.add("error");
              var errorCode = iti.getValidationError();
              errorMsgBilling.innerHTML = errorMap[errorCode];
              errorMsgBilling.classList.remove("hidden");
            }
          }
          $scope.billing_address.phone_number = iti.getNumber();
        });
        billing_phone_input.addEventListener("change", reset);
        billing_phone_input.addEventListener("keyup", reset);

        if (document.querySelector("#shipping_address_phone_number")) {
          var shipping_phone_input = document.querySelector(
              "#shipping_address_phone_number"
            ),
            errorMsgShipping = document.querySelector("#error-msg-shipping"),
            validMsgShipping = document.querySelector("#valid-msg-shipping");

          var iti_shipping = window.intlTelInput(shipping_phone_input, {
            initialCountry: "us",
            nationalMode: true,
            utilsScript:
              "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544",
          });
          var resetShipping = function () {
            shipping_phone_input.classList.remove("error");
            errorMsgShipping.innerHTML = "";
            errorMsgShipping.classList.add("hidden");
            validMsgShipping.classList.add("hidden");
          };
          shipping_phone_input.addEventListener("blur", function () {
            resetShipping();
            if (shipping_phone_input.value.trim()) {
              if (iti_shipping.isValidNumber()) {
                validMsgShipping.classList.remove("hidden");
              } else {
                shipping_phone_input.classList.add("error");
                var errorCode = iti_shipping.getValidationError();
                errorMsgShipping.innerHTML = errorMap[errorCode];
                errorMsgShipping.classList.remove("hidden");
              }
            }
            $scope.shipping_address.phone_number = iti_shipping.getNumber();
          });
          shipping_phone_input.addEventListener("change", resetShipping);
          shipping_phone_input.addEventListener("keyup", resetShipping);
        }     
        if (window.isDisplayLocalize && Localize) {
          Localize.on('setLanguage', function({ to }) {
            $scope.user_locale = to;
          });
        };
      });
    },
  ]);
}.call(this));
