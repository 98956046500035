const { isArray } = require("angular");

(function () {
  app.controller('BookingsCtrl', ['$scope', '$timeout', '$analytics', '$http', '$interval', function ($scope, $timeout, $analytics, $http, $interval) {
    const OWL_CAROUSEL_OPTIONS = {
      dots: false,
      items: 1,
      stagePadding: 30,
      lazyLoad: true,
      responsiveClass: true,
      responsiveBaseElement: 'body',
    };
    const EMAIL_PATTERN = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
    
    const Amplitude = AmplitudeModule.getInstance()

    const getParams = (string) =>{
      const params = new URLSearchParams(window.location.search)
      return params.get(string);

    }
    // Scope definition
    $scope.event_store = {
      CURRENT_STEP: 'current_step',
      CURRENT_TRACKED_STEP: 'current_tracked_step',
      CUSTOMER_SELECT: 'customer_select',
      SELECTED_PACKAGES: 'selected_packages',
      SELECTED_TICKETS: 'selected_tickets',
      SELECTED_TICKETS_WITHOUT_SEATS: 'selected_tickets_without_seats',
      CUSTOMER_ROOM_TYPES: 'customer_room_types',
      SPLIT_ORDER_DATA: 'split_order_data',
      PAYMENT_METHOD: 'payment_method',
      PAYMENT_PLAN_OPTION: 'payment_plan_option',
      PASSWORD_RESET: 'password_reset',
      CUSTOMER_HOTELS: 'customer_hotels',
      CONFIRM_PRICING: 'confirm_pricings',
      PAYMENT_OPTION: 'payment_option',
      NUMBER_OF_TRAVELLERS: 'number_of_travellers',
      ALREADY_HAVE_TICKETS: 'already_have_tickets',
      SELECTED_TICKETS_TYPE: 'selected_tickets_type',
      TICKETS_SELECTION_METHOD: 'tickets_selection_method',
      SELECTED_TICKETS_SECTION: 'selected_tickets_section',
      FLIGHT_DATA: 'flight_data',
      FORCE_SELECT_ORPHAN_SEAT: 'force_select_orphan_seat',
      GUID: 'guid',
      PAY_FULL_ORDER_DATA: 'pay_full_order_data',
      REQUIRE_NAMES_PAY_FULL: 'require_names_pay_full'
    };
    $scope.braintree = {};
    $scope.package_box_fixed = false;
    $scope.owl_carousel_item_started_drag = 0;
    $scope.owl_carousel_item_selected = null;
    $scope.event_additional_fee_apply_type = {
      PER_ORDER: 0,
      PER_PERSON: 1,
      PER_CHECKOUT: 2,
      PER_SUBTOTAL: 3,
    };
    $scope.event_discount_code_apply_type = {
      PER_ORDER: 0,
      PER_CHECKOUT: 1,
      PER_PERSON: 2,
    };
    $scope.ampKey = ''
    // Any additional event properties should be added here, which should update obj automatically
    $scope.initAmplitude = ()=>{
      const deviceId = getParams('deviceid');
      const configOptions = {includeUtm: true};
      if(deviceId){ configOptions.deviceId = deviceId };
      Amplitude.init($scope.ampKey, null, configOptions);
    };
    $scope.stripe_stress_test = false;
    $scope.steps = ['Your Party', 'Package', 'Add-on', 'Confirm', 'Payment'];
    $scope.step_keys = {
      your_party: 0,
      package: 1,
      addon: 2,
      confirm: 3,
      payment: 4,
    };
    $scope.step_pages = [
      { url: 'parties', title: 'Party' },
      { url: 'packages', title: 'Packages' },
      { url: 'add-ons', title: 'Add-ons' },
      { url: 'confirm', title: 'Confirm' },
      { url: 'confirm-checkout', title: 'Confirm Checkout' },
      { url: 'checkout', title: 'Checkout' },
    ];
    $scope.tracking_steps = ['Your Party', 'Package', 'Package RoomType Select', 'Add-on', 'Confirm', 'Confirm Checkout', 'Payment', 'Payment Success'];
    $scope.current_tracked_step = 'Your Party';
    $scope.current_step = 0;
    $scope.event = null;
    $scope.number_of_travellers = 0;
    $scope.guest_unassigned = 0;
    $scope.customer_packages = {};   // { customer_type: packages[] }
    $scope.selected_packages = {};   // { customer_type: { quantity: number, package: {}} }
    $scope.customer_select = 'default';   // current customer select
    $scope.customer_room_types = {}; // { customer_type: room_types }
    $scope.addons = [];
    $scope.customer_hotels = {};     // { customer_type: { hotel_id, package_id, room_type_data{}, number_of_rooms, total_room_pax, valid_room_member } }
    $scope.addon_data = {};          // { customer_type: { addon_id: addon } }
    $scope.amend_addon_data = {};
    $scope.payment_data = {
      original_price: 0,             // total product price without product fees
      order_price: 0,                // total product price with product fees
      grand_amount: 0,               // total paid product price with product fees
      discountable_order_price: 0,
      discountable_grand_amount: 0,
      insurance_amount: 0,
      total_event_fee: 0,
      total_event_discount: 0,
      payment_amount: 0, // total paid product price with product fees and event fees and insurance
      subtotal: 0,
      amendment_credits: {
        mine: 0.0,
        usable: 0.0,
        split_orders_credits: {},
        applied: 0.0
      },
    };
    $scope.show_packages = true;
    $scope.show_room_types = false;
    $scope.show_allocate_guests = false;
    $scope.current_user = null;
    $scope.payment_plans = [];
    $scope.payment_plan_names = [];
    $scope.affirm_position = -1;
    $scope.credit_card = {};
    $scope.billing_address = {};
    $scope.shipping_address = {};
    $scope.billingStates = {};
    $scope.shippingStates = {};
    $scope.include_insurance = false;
    $scope.payment_method = 1; // 1: Pay All (default), 0: Split
    $scope.payment_option = 1; // 1: Pay installments (default), 0: Pay everything today
    $scope.payment_plan_option = 0; // Payment plan option between 0 (default) 2 depending on payment plan selected
    $scope.pay_full = false;
    // variable for split bill screen
    $scope.enable_allocate_next_room = false;
    $scope.split_bill_error_message = 'Please select your room';
    $scope.addon_count = 0;
    $scope.flight_count = 0;
    $scope.rooms = [];
    $scope.save_personal_info = true;
    $scope.copy_billing_address = false;
    $scope.error_messarge = '';
    $scope.show_signup = true;
    $scope.show_signin = false;
    $scope.show_password_reset = false;
    $scope.signin_error_msg = '';
    $scope.signup_error_msg = {};
    $scope.password_reset_msg = null;
    $scope.password_reset_error_msg = {};
    $scope.checkout_option1 = false;
    $scope.checkout_option2 = false;
    $scope.checkout_option3 = false;
    $scope.checkout_option4 = false;
    $scope.signup_user = {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      active: true,
    };
    $scope.signin_user = {
      email: '',
      password: '',
    };
    $scope.password_reset = {
      email: '',
    };
    $scope.amend_order = '';

    $scope.init = function () {};
    $scope.error_message = '';
    $scope.order_id = '';
    $scope.event_additional_fees = [];
    $scope.hotel_additional_fees = [];
    $scope.addon_additional_fees = [];
    $scope.flight_additional_fees = [];
    $scope.choose_hotel_id = 0;
    $scope.choose_room_type_id = 0;
    $scope.trip_dates = [];
    $scope.my_trip_date = null;
    $scope.event_package_options = [];
    $scope.allocating_room_number = 1;
    $scope.event_customer_types = [];
    $scope.order_customer_types = [];
    $scope.pre_booking_has_order = false;
    $scope.has_addon_setting = false;
    $scope.pre_booking_customer_hotel = {};
    $scope.hotel_order_items = {};
    $scope.discount_code = [];
    $scope.discount = {code: ''};
    $scope.toggle_discount_code = true;
    $scope.confirm_pricings_transition = {};
    $scope.total_paid = 0;
    $scope.totalTicketsCost = 0.0;
    $scope.seating_section = {};
    $scope.selected_seats = [];
    $scope.layout = {};
    $scope.selectedTickets = [];
    $scope.selectedTicketsWithoutSeats = [];
    $scope.groupedSelectedTickets = {};
    $scope.calculatedSelectedTickets = {};
    $scope.haveTickets = false;
    $scope.timeRemaining = 0;
    $scope.timerStarted = false;
    $scope.seatingMapUpdateInterval = 1000;
    $scope.availableSeatsLoadPromise = null;
    $scope.active_tickets_type_name = '';
    $scope.active_tickets_type_price = 0;
    $scope.force_select_orphan_seat = false;
    $scope.ticket_select_method = {
      MANUALLY_SELECT_SEATS: 'manually_select_seats',
      ASSIGN_BEST_AVAILABLE_SEATS: 'assign_best_available_seats',
    }
    $scope.has_flight_ticket_products = false;
    $scope.flight_product = {}
    $scope.flight_product_specific = {}
    $scope.flight_states = {}
    $scope.flight_state_id = null;
    $scope.selected_airport = [];
    $scope.airports = [];
    $scope.available_departure_date = [];
    $scope.available_return_date = [];
    $scope.flights = [];
    $scope.flight_ticket = {
      flight_data: {},
      selected_airport: null,
      state_id: null,
      departure_date: null,
      return_date: null
    };
    $scope.flight_ticket_data = {
      flight_data: {},
    }
    $scope.flight_data = {};
    $scope.total_flight_tickets = 0;
    $scope.source_airports = [];
    $scope.destination_airports = [];
    $scope.firstDepartureDate = '';
    $scope.lastDepartureDate = '';
    $scope.firstReturnDate = '';
    $scope.lastReturnDate = '';
    $scope.calendarHandler = {};
    // Hotelbeds new scopes
    $scope.package_button_enable = true;
    $scope.hotel_beds_availability_return = {};
    $scope.customer_data_on_availability_call = {};
    $scope.hotel_beds_availability_call_ongoing = false;
    $scope.current_redirect_step_after_availability_return = 0;
    $scope.require_guest_names_on_pay_full = false;
    $scope.occupants = [];
    $scope.user_locale = 'en';
    $scope.event_currency = window.event_currency || 'USD';
    $scope.gaClientId = '';
    referrer_url_val = (document.cookie.match(/^(?:.*;)?\s*referrer_url\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1];
    current_referrer = document.referrer;
    
    if (referrer_url_val == null) {
      document.cookie = referrer_url_cookie();
      $scope.referrerURL = current_referrer;
    } else if ((referrer_url_val != current_referrer) && (current_referrer != '')) {
      document.cookie = referrer_url_cookie();
      $scope.referrerURL = current_referrer;
    } else {
      $scope.referrerURL = referrer_url_val;
    }

    //=========End===============
    
    //========Watch=============
    $scope.$watchGroup(
      [
        'copy_billing_address',
        'billing_address.full_name',
        'billing_address.phone_number',
        'billing_address.address_1',
        'billing_address.address_2',
        'billing_address.city',
        'billing_address.zipcode',
        'billing_address.state_id',
        'billing_address.country_id',
      ],
      function () {
        if ($scope.copy_billing_address) {
          $scope.shipping_address = angular.copy($scope.billing_address);
        }
      }
    );

    // ========== End ============= #

    // TODO: the following package folding animation is clunky,
    // looks terrible and is a horrible UX. I turned it off.
    // It needs to either get implemented better or we are
    // are not going to bring this back.
    //
    // var timer;
    // $(window).scroll(function () {
    //   var is_package_selection =
    //     Object.keys($scope.selected_packages).length > 0 &&
    //     $scope.current_step == $scope.step_keys.package &&
    //     $scope.show_packages;
    //   if (
    //     is_package_selection &&
    //     $scope.event_options.length > 0 &&
    //     $(window).width() < 768
    //   ) {
    //     clearTimeout(timer);
    //     const fix_package_scroll = $('.package-option-included').offset().top;
    //     var currentScroll = $(window).scrollTop();
    //     if (
    //       currentScroll >= fix_package_scroll + 300 &&
    //       !$scope.package_box_fixed
    //     ) {
    //       $('#bookingWrapper').addClass('package-box-fixed');
    //       $('#bookingWrapper').removeClass('package-box-expand');
    //       $scope.package_box_fixed = true;
    //       setTimeout(function () {
    //         window.scrollTo({
    //           top: 50,
    //           behavior: 'smooth',
    //         });
    //       }, 500);
    //     }
    //     // setTimeout(function () {
    //     if (currentScroll == 0) {
    //       timer = setTimeout(function () {
    //         $('#bookingWrapper').removeClass('package-box-fixed');
    //         $('#bookingWrapper').addClass('package-box-expand');
    //         $scope.package_box_fixed = false;
    //       }, 500);
    //     }
    //   }
    // });
    //========== end ===============

    $scope.showSignin = function () {
      $scope.signup_user = {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        active: true,
      };
      $scope.password_reset = {
        email: '',
      };
      $scope.show_signin = true;
      $scope.show_signup = false;
      $scope.show_password_reset = false;
    };
    $scope.showSignup = function () {
      $scope.signin_user = {
        email: '',
        password: '',
      };
      $scope.password_reset = {
        email: '',
      };
      $scope.show_signin = false;
      $scope.show_signup = true;
      $scope.show_password_reset = false;
    };
    $scope.showPasswordReset = function () {
      $scope.show_signin = false;
      $scope.show_signup = false;
      $scope.show_password_reset = true;
    };

    $scope.setPageTrack = function (pageUrl, pageTitle) {
      var page = pageUrl || getPageUrl();
      var title = pageTitle || getPageTitle();
      window.document.title = title;
      $analytics.pageTrack(page);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page',
        pageName: page,
      });
    };

    $scope.login = function () {
      $.post(
        '/users/sign_in',
        { is_json: true, user: $scope.signin_user },
        function (rs) {
          $timeout(function () {
            if (rs.success) {
              location.reload();
            } else {
              $scope.signin_error_msg = rs.message;
            }
          });
        }
      );
    };

    $scope.signUp = function () {
      $.post('/users', { user: $scope.signup_user }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            location.reload();
          } else {
            $scope.signup_error_msg = rs.message;
          }
        });
      });
    };

    $scope.sendPasswordReset = function () {
      const isValidEmail = EMAIL_PATTERN.test($scope.password_reset.email);
      if (isValidEmail) {
        $.post(
          '/users/password',
          {
            user: $scope.password_reset,
            json: true,
            event_slug: $scope.event.slug,
          },
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.setStorage($scope.event_store.PASSWORD_RESET, true);
                location.reload();
              } else {
                $scope.password_reset_msg = rs.message;
              }
            });
          }
        );
      } else {
        $scope.password_reset_error_msg.email = 'Email is invalid';
      }
    };

    $scope.increaseTravelers = function (customer_type = 'default') {
      if ($scope.number_of_travellers < $scope.max_traveller && !$scope.fixedBooking()) {
        if (customer_type) {
          $scope.selected_packages[customer_type] ||= {};
          $scope.selected_packages[customer_type].quantity += 1;
          $scope.setStorage($scope.event_store.SELECTED_PACKAGES, $scope.selected_packages);
        }
        if (!$scope.has_ticket_products) {
          $scope.number_of_travellers += 1;
        }else{
          if ($scope.active_ticket_select_method == $scope.ticket_select_method.ASSIGN_BEST_AVAILABLE_SEATS) {
            $scope.number_of_travellers += 1;
            $scope.assignBestAvailableSeats();
          }else{
            $('.loading').fadeIn();
            $scope.number_of_travellers += 1;
            $scope.updateAvailableSeats(false, false, true);
            $('.loading').fadeOut('slow');
          }
        }
        $scope.setStorage($scope.event_store.NUMBER_OF_TRAVELLERS, $scope.number_of_travellers);
      }
    };

    $scope.decreaseTravelers = function (customer_type = 'default') {
      if ($scope.number_of_travellers > 0 && !$scope.fixedBooking()) {
        if (customer_type && $scope.selected_packages[customer_type].quantity > 0 ) {
          $scope.selected_packages[customer_type].quantity -= 1;
          $scope.setStorage($scope.event_store.SELECTED_PACKAGES, $scope.selected_packages);
        }
        if (!$scope.has_ticket_products) {
          $scope.number_of_travellers -= 1;
        }else{
          if ($scope.active_ticket_select_method == $scope.ticket_select_method.ASSIGN_BEST_AVAILABLE_SEATS) {
            $scope.number_of_travellers -= 1;
            $scope.assignBestAvailableSeats();
          }else{
            $('.loading').fadeIn();    
            $scope.number_of_travellers -= 1;
            $scope.updateAvailableSeats(false, false, true);
            $('.loading').fadeOut('slow');
          }
        }
        $scope.setStorage($scope.event_store.NUMBER_OF_TRAVELLERS, $scope.number_of_travellers);
      }
    };

    $scope.fixedBooking = function(join = true) {
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid');
      var amend_order = params.get('amend_order');
      if (join) {
        return guid_param != null && amend_order != null
      }else{
        return guid_param != null || amend_order != null
      }
    }

    $scope.addTicketsToOrder = function(success, failure) {
      var opt = {
        tickets: JSON.stringify($scope.selectedTickets),
        number_of_travellers: $scope.number_of_travellers,
        tickets_without_seats: JSON.stringify($scope.selectedTicketsWithoutSeats)
      }
      $.post($scope.event_booking_path + '/add_tickets_to_order', 
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              success(rs)
            } else {
              failure(rs)
            }
          });
        }
      );
    }

    $scope.removeAllTicketsFromOrder = function(failure) {
      $.post($scope.event_booking_path + '/remove_all_ticket_from_order',
      function (rs) {
        $timeout(function () {
          if (!rs.succeed) {
            failure(rs)
          }
        });
      }
    );
    }

    /**
     * scroll to top
     * if trip_dates doesn't exist, get hotels to render
     */
    $scope.goToPackageStep = function () {

      HotelBeds.updateHotelBedsHotelPrices($scope, false);

      doGoToPackageStep = function() {
        window.scrollTo(0, 0);
        $scope.verifyGuidToken();
        $scope.setCurrentStep($scope.step_keys.package);
        $scope.setCurrentTrackedStep('Package');
        Amplitude.logEvent('Viewed Package Step Page', $scope.ampEventProps);

        if ($scope.trip_dates.length === 0) {
          $.post($scope.event_booking_path + '/customer_packages', function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.customer_packages = rs.customer_packages;
                if (!$scope.pre_booking_data) {
                  $scope.flight_ticket = {
                    flight_data: {},
                    selected_airport: null,
                    state_id: null,
                    departure_date: null,
                    return_date: null
                  };
                  $scope.flight_data = {}
                  $scope.removeStorage($scope.event_store.FLIGHT_DATA);
                  $scope.flights = [];
                }
                selectFirstCustomerTypes(true);
                HotelBeds.updateHotelBedsHotelPrices($scope, false);
              } else if (rs.message) {
                bootbox.alert(rs.message);
              }
            });
          });
        } else if (Object.keys($scope.customer_packages).length > 0) {
          // automaticaly select first availiable package
          // if pre booking has order will select the package in the order
          selectFirstCustomerTypes(true);
        }
        applyOwlCarousel({ auto_select: true });
        $('.loading').fadeOut('slow');
      }
      if ($scope.has_ticket_products && !$scope.haveTickets) {

        $scope.addTicketsToOrder(function() {

          doGoToPackageStep()

        }, function(rs) {
          bootbox.alert(rs.message);
        })
      } else {
        doGoToPackageStep()
      }
    };

    $scope.chooseTripDate = function (trip_date, reset_selected_packages = true) {
      $scope.my_trip_date = trip_date;
      if (trip_date == null) {
        $scope.customer_packages = {};
        return;
      }
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid')
      if(guid_param != null) {
        var temp_customer_select = $scope.customer_select;
        if (temp_customer_select == 'default') {
          temp_customer_select = $scope.event_customer_types[0].singular_name;
        }
        var params = {
          trip_date_id: $scope.my_trip_date.id,
          amend_order: $scope.amend_order,
          pre_booking_data: {
            guid: guid_param,
            customer_select: temp_customer_select,
          },
          number_of_travellers: $scope.number_of_travellers
        };
      } else {
        var params = {
          trip_date_id: $scope.my_trip_date.id,
          number_of_travellers: $scope.number_of_travellers
        };
      }
      $('.loading').fadeIn();
      $.post(
        $scope.event_booking_path + '/customer_packages',
        params,
        function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              document.scope = $scope;
              $scope.customer_packages = rs.customer_packages;
              if (!$scope.pre_booking_data) {
                $scope.flight_ticket = {
                  flight_data: {},
                  selected_airport: null,
                  state_id: null,
                  departure_date: null,
                  return_date: null
                };
                $scope.flight_data = {}
                $scope.removeStorage($scope.event_store.FLIGHT_DATA);
                $scope.flights = [];
                $scope.flight_state_id = null;
                $scope.selected_airport = null;
              }
              // Reset package select when fetching new tripdate
              if (reset_selected_packages) {
                $.each($scope.selected_packages, function (customer_type, select_package) {
                    delete $scope.selected_packages[customer_type].package;
                  }
                );
              }
              // check if guid_param is null then
              //  let customer choose package for the first customer type
              // else
              //  skip since use chooseTripDate to ajax render package
              if (!guid_param) {
                selectFirstCustomerTypes(true);
              }
              // applyOwlCarousel does not get triggered on reload
              applyOwlCarousel({ auto_select: true });


              HotelBeds.updateHotelBedsHotelPrices($scope, false);

              $('.loading').fadeOut('slow');
            } else if (rs.message) {
              bootbox.alert(rs.message);
            }
          });
        }
      );
    };

    $scope.selectPackage = function (
      customer_type,
      package_instance,
      trigger = false,
      same_package = true
    ) {
      // validation package stock: todo
      // if (package_instance.package_stock < $scope.number_of_travellers) return;

      $scope.selected_packages[customer_type] ||= {};
      const selected_package = $scope.selected_packages[customer_type];
      const is_selected =
        Object.keys(selected_package).length > 0 &&
        selected_package.hotel_id == package_instance.hotel_id &&
        selected_package.package_id == package_instance.package_id;
      if (!same_package && !$scope.pre_booking_data) {
        $scope.flight_ticket = {
          flight_data: {},
          selected_airport: null,
          state_id: null,
          departure_date: null,
          return_date: null
        };
        $scope.flight_data = {}
        $scope.removeStorage($scope.event_store.FLIGHT_DATA);
        $scope.flights = [];
      }
      // Toggle selected
      if (!trigger && is_selected) {
        delete $scope.selected_packages[customer_type].package;
      } else {
        $scope.selected_packages[customer_type].package = package_instance;
        $scope.selectPackageOption(package_instance);
      }
    };

    $scope.selectPackageOption = function (package_instance) {
      $scope.event_package_options = [];
      sortBy($scope.event_options, 'sequence').forEach((option) => {
        const package_option = option;
        package_option.selected = package_instance.package_options.some(
          (package_option) => package_option.event_option_id == option.id
        );
        $scope.event_package_options.push(package_option);
      });
      return $scope.event_package_options;
    };

    $scope.showTicketLevelMap = function () {
      $('#popupModal .modal-content').html(
        `<div class="modal-body">
          <div class="d-flex justify-content-between">
            ${
              ($scope.event.address_2 &&
                `<p class="font-weight-normal"><i class="fas fa-map-marker-alt icon text-active mr-2"></i>${$scope.event.address_2}</p>`) ||
              ''
            }
            <button type="button" class="close mb-3" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="la la-times"></i></span>
            </button>
          </div>
          <div class="card p-0 m-0">
            <div class="card-body p-0">
              <div class="row align-items-center">
                <img src="${$scope.event_ticket_level_map}"/>
              </div>
            </div>
          </div>
        </div>
        `
      );
      $('#popupModal').modal('show');
    };

    $scope.isPackageSelected = function (customer_type, package_instance) {
      const customer_package = $scope.selected_packages[customer_type]?.package;
      if (!customer_package) return false;

      return (
        package_instance.hotel_id == customer_package?.hotel_id &&
        package_instance.package_id == customer_package?.package_id
      );
    };

    $scope.canNextToCustomerTypeSelect = function () {
      if ($scope.current_step != $scope.step_keys.package) return false;

      const selected_packages = getSelectedPackages();
      return $scope.my_trip_date && selected_packages[$scope.customer_select]?.package;
    };

    $scope.nextToCustomerPackage = function () {
      $scope.verifyGuidToken();
      if ($scope.current_step != $scope.step_keys.package) return false;

      $('.loading').fadeIn();
      const selected_customer_types = Object.keys(getSelectedPackages());
        if ($scope.customer_select == selected_customer_types[selected_customer_types.length - 1]) {
          $('.loading').fadeOut('slow');
          return $scope.addCustomerPackages();
        }
        const nextIndex = selected_customer_types.indexOf($scope.customer_select) + 1;
        $scope.customer_select = selected_customer_types[nextIndex];
        var params = new URLSearchParams(window.location.search)
        var guid_param = params.get('guid')
        if(guid_param != null) {
          $scope.chooseTripDate($scope.my_trip_date, false)
        }
        selectFirstCustomerTypes();
        applyOwlCarousel({ auto_select: true });
        $('.loading').fadeOut('slow');
      }
    // };

    $scope.addCustomerPackages = function () {
      // Remove for package selection
      $('#bookingWrapper').removeClass('package-box-fixed');
      // Get room types
      const selected_packages = getSelectedPackages();
      // Reset
      $scope.customer_hotels = {};
      $.each(selected_packages, function (customer_type, selected_package) {
        const package_instance = selected_package.package;
        $scope.customer_room_types[customer_type] = package_instance.room_types;
        $scope.customer_hotels[customer_type] = {
          number_of_rooms: 0,
          total_room_pax: 0,
          package_id: package_instance.package_id,
          hotel_id: package_instance.hotel_id,
          room_type_data: {},
          hotel_has_hotelbeds: package_instance.hotel_has_hotelbeds
        };
        // auto select room when one room type and pax = 1
        var params = new URLSearchParams(window.location.search)
        var guid_param = params.get('guid')
        if (
          package_instance.room_types.length == 1 &&
          package_instance.room_types[0].pax == 1
        ) {
          for(var i = 1; i <= selected_package.quantity; i++) {
            $scope.increaseRoomType(
              customer_type,
              package_instance.room_types[0]
            );
          }
        } else if(guid_param != null) {
          data = {};
          temp_room_type = {};
          $.each($scope.pre_booking_data.customer_types, function(index, pre_booking_customer_type) {
            if (pre_booking_customer_type.customer_type.toLowerCase() == customer_type.toLowerCase()){
              data = pre_booking_customer_type;
            }
          });
          $.each(package_instance.room_types, function(index, room_type) {
            if (!Array.isArray(data.rooms[0].room_type_id)) {
              if (data.rooms[0].room_type_id == room_type.id){
                temp_room_type = room_type;
              }
            }else{
              if (data.rooms[0].room_type_id.includes(room_type.id)){
                temp_room_type = room_type;
              }
            }
          });
          for(var i = 1; i <= selected_package.quantity; i++) {
            $scope.increaseRoomType(
              customer_type,
              temp_room_type
            );
          }
        }
      });
      $scope.setStorage($scope.event_store.CUSTOMER_ROOM_TYPES, $scope.customer_room_types);
      $scope.show_packages = false;
      $scope.goToRoomTypeSelect();
    };

    $scope.increaseRoomType = function (customer_type = 'default', room_type) {
      if (
        $scope.selected_packages[customer_type].quantity >
        $scope.customer_hotels[customer_type].number_of_rooms
      ) {
        var temp = $scope.customer_hotels[customer_type].room_type_data[room_type.id];
        Object.keys($scope.hotel_order_items).length > 0 &&
        Object.keys($scope.hotel_order_items).forEach(amend_hotel => {
          if (room_type.id == $scope.hotel_order_items[amend_hotel].room_type_id && room_type.hotel_id == $scope.hotel_order_items[amend_hotel].product_id) {
            room_type.pricings.forEach((pricing, idx) => {
              if (pricing.number_of_occupants == $scope.amend_order_travelers && parseFloat($scope.hotel_order_items[amend_hotel].unit_price) > 0 && (!temp || (temp && temp.quantity < $scope.hotel_order_items[amend_hotel].quantity))) {
                room_type.original_price = $scope.hotel_order_items[amend_hotel].unit_price
                room_type.original_quantity = $scope.hotel_order_items[amend_hotel].quantity
              }
            });
          }
        });
        if (!temp) {
          temp = {
            id: room_type.id,
            package_name: room_type.package_name,
            hotel_id: room_type.hotel_id,
            hotel_name: room_type.hotel_name,
            quantity: 0,
            additional_fee: 0,
            pax: room_type.pax,
            name: room_type.name,
            pricings: room_type.pricings,
            image: room_type.image,
            package_id: room_type.package_id,
            event_customer_type_id: room_type.event_customer_type_id,
            rooms: {},
            is_room_hb: room_type.is_room_hb,
            hotelbeds_room_codes: room_type.hotelbeds_room_codes,
            hotelbeds_board_codes: room_type.hotelbeds_board_codes,
            original_price: room_type.hasOwnProperty('original_price') ? parseFloat(room_type.original_price) : 0,
            original_quantity: room_type.hasOwnProperty('original_quantity') ? parseInt(room_type.original_quantity) : 0
          };
        }

        if (room_type.stock > temp.quantity) {
          temp.quantity += 1;
          $scope.customer_hotels[customer_type].total_room_pax += room_type.pax;
          $scope.customer_hotels[customer_type].number_of_rooms += 1;
        }
        $scope.customer_hotels[customer_type].room_type_data[room_type.id] = temp;
      }
      if (customer_type === 'default') skipAllocateGuest();
    };

    $scope.decreaseRoomType = function (customer_type = 'default', room_type) {
      var temp = $scope.customer_hotels[customer_type].room_type_data[room_type.id];

      if (!temp || temp.quantity == 0) {
        return false;
      }

      temp.quantity -= 1;
      $scope.customer_hotels[customer_type].number_of_rooms -= 1;
      $scope.customer_hotels[customer_type].total_room_pax -= room_type.pax;

      if (temp.quantity == 0) {
        delete $scope.customer_hotels[customer_type].room_type_data[room_type.id];
      }
      if (customer_type === 'default') skipAllocateGuest();
    };

    /**
     * 1 people -> addons
     * 1 room -> build allocate -> addons
     * number_of_travellers == max of pax -> build allocate -> addons
     *
     * remain -> build allocate -> display allocate -> addons
     */
    $scope.allocateGuests = function () {
      window.scrollTo(0, 0);
      if (skipRoomSelection()) {
        $scope.addHotelToOrder(false);
        $.each($scope.customer_hotels, function (_, hotel) {
          $.each(hotel.room_type_data, function(_, room_type) {
            for(let room = 1; room <= room_type.quantity; room++) {
              room_type.rooms ||= {};
              room_type.rooms[room] = { number_of_occupants: 1 };
              $scope.occupants[room_type.id] = []
              $scope.occupants[room_type.id][room] = 1
            }
          });
        });
        if ($scope.has_flight_ticket_products) {
          return $scope.goToFlightTicketStep();
        }
        return $scope.goToAddonStep();
      }
      var { number_of_occupants, is_full_capacity, skip_allocate } = skipAllocateGuest();
      // Rebuild allocateGuests
      $.each($scope.customer_hotels.default.room_type_data, function (room_type_id, room_type) {
          var temp = $scope.customer_room_types.default.find((item) => item.id == room_type_id);
          for (var room = 1; room <= room_type.quantity; room++) {
            room_type.id = temp.id;
            room_type.image = temp.image;
            room_type.rooms ||= {};
            room_type.rooms[room] = {
              number_of_occupants:(skip_allocate && ((is_full_capacity && room_type.pax) || number_of_occupants)) || 0,
            };
            $scope.occupants[room_type_id] = []
            $scope.occupants[room_type_id][room] = (skip_allocate && ((is_full_capacity && room_type.pax) || number_of_occupants)) || 0
          }
        }
      );
      if (skip_allocate) {
        $scope.addHotelToOrder(false);
        if ($scope.has_flight_ticket_products) {
          return $scope.goToFlightTicketStep();
        }
        return $scope.goToAddonStep();
      }
      $scope.guest_unassigned = $scope.number_of_travellers;
      $scope.show_room_types = false;
      $scope.show_allocate_guests = true;
      $scope.customer_hotels.default.each_room_has_member = hasMemberEachRoom();
      $scope.ampEventProps.number_of_travellers = $scope.number_of_travellers;
      Amplitude.logEvent('Rooms selected', $scope.ampEventProps);
    };

    $scope.increaseRoomOccupant = function (room_type, room) {
      if ($scope.guest_unassigned > 0) {
        $scope.customer_hotels.default.room_type_data[room_type.id].rooms[room].number_of_occupants += 1;
        $scope.occupants[room_type.id][room] += 1;
        $scope.guest_unassigned -= 1;
      }
      $scope.customer_hotels.default.each_room_has_member = hasMemberEachRoom();
    };

    $scope.decreaseRoomOccupant = function (room_type, room) {
      if ($scope.customer_hotels.default.room_type_data[room_type.id].rooms[room].number_of_occupants > 0) {
        $scope.customer_hotels.default.room_type_data[room_type.id].rooms[room].number_of_occupants -= 1;
        $scope.occupants[room_type.id][room] -= 1;
        $scope.guest_unassigned += 1;
      }
      $scope.customer_hotels.default.each_room_has_member = hasMemberEachRoom();
    };

    $scope.goToRoomTypeSelect = function () {
      if (skipRoomSelection()) {
        return $scope.allocateGuests();
      }
      $scope.setCurrentTrackedStep('Package RoomType Select');
      Amplitude.logEvent('Viewed Room Select Page', $scope.ampEventProps)
      $scope.show_room_types = true;
      $scope.setPageTrack(
        `/booking/${$scope.event.slug}/room-types`,
        `${$scope.event.name} | Room Type`
      );
      window.scrollTo(0, 0);
    };

    $scope.goToFlightTicketStep = function () {
      if (!$scope.has_flight_ticket_products) {
        return $scope.goToAddonStep();
      }
      $scope.setStorage($scope.event_store.CUSTOMER_HOTELS, $scope.customer_hotels);
      $scope.setCurrentStep($scope.step_keys.flight_ticket);
      $scope.setCurrentTrackedStep('Flight Ticket');
      Amplitude.logEvent('Viewed Flight Ticket Page', $scope.ampEventProps)
      window.scrollTo(0, 0);
    };

    $scope.backToFlightTicketStep = function () {
      if (!$scope.has_flight_ticket_products) {
        return $scope.backToAllocateGuest();
      }
      $scope.setCurrentStep($scope.step_keys.flight_ticket);
      $scope.setCurrentTrackedStep('Flight Ticket');
      window.scrollTo(0, 0);
    };


    $scope.goToAddonStep = function (add_rooms = false, force_flight = false) {
      var none_addon = $scope.addons.length <= 0;
      $scope.setStorage($scope.event_store.CUSTOMER_HOTELS, $scope.customer_hotels);
      if ($scope.has_flight_ticket_products) {
        $scope.addFlightToOrder();
      }
      if (add_rooms) {
        $scope.addHotelToOrder(false, force_flight);
      }
      if ($scope.has_flight_ticket_products && $scope.current_step !== $scope.step_keys.flight_ticket) {
        return $scope.goToFlightTicketStep();
      } else if (none_addon && $scope.number_of_travellers == 1) {
        return $scope.goToConfirmCheckout();
      } else if (none_addon) {
        return $scope.goToConfirmStep();
      }

      if (Object.keys($scope.amend_addon_data).length > 0 && !none_addon && Object.keys($scope.addon_data).length == 0) {
        Object.keys($scope.amend_addon_data).forEach(amend_addon => {
          let addon_data = $scope.addons.find(
            (addon) => addon.id == amend_addon
          );
          addon_data && $scope.increaseAddon(addon_data, $scope.amend_addon_data[amend_addon]);
        })
      }
      $scope.setCurrentTrackedStep('Add-on');
      Amplitude.logEvent('Viewed Addon Page', $scope.ampEventProps)
      $scope.setCurrentStep($scope.step_keys.addon);
      window.scrollTo(0, 0);
    };

    $scope.addHotelToOrder = function (skip = false, force_flight = false) {
      const selected_packages = {};
      let package_id = null
      $.each(getSelectedPackages(), function(customer_type, item) {
        selected_packages[customer_type] = item.quantity;
        if ($scope.pre_booking_data && Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = item.package.package_id
        }
      });
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      var opt = {
        event_id: $scope.event.id,
        package_id: package_id,
        number_of_travellers: $scope.number_of_travellers,
        selected_packages,
        customer_hotels: buildCustomerHotelParams(),
        trip_date_id: $scope.my_trip_date != null ? $scope.my_trip_date.id : null,
        pre_booking: $scope.pre_booking_data ? true : false
      };
      $.post(
        $scope.event_booking_path + '/add_hotel_to_order',
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              $scope.addons = rs.addons;
              $scope.flight_product = rs.flights.flight_product;
              $scope.flight_product_specific = rs.flights.flight_product_specific;
              $scope.flight_states = rs.flights.flight_states;
              if ($scope.flight_product) {
                $scope.has_flight_ticket_products = Object.keys($scope.flight_product).length > 0
              }
              let storage_flight_data = $scope.getStorage($scope.event_store.FLIGHT_DATA);
              if (storage_flight_data) {
                $scope.loadFlightData(storage_flight_data)
              }
              /**
               * if none addon && traveler = 1 -> payment
               * none addon - to payment
               * Display addon
               */
              var none_addon = $scope.addons.length <= 0;
              if (!$scope.has_flight_ticket_products && $scope.has_flight_products) {
                $scope.addFlightToOrder();
              }
              if (force_flight && $scope.has_flight_ticket_products) {
                return $scope.goToFlightTicketStep();
              }

              if (!skip && !$scope.show_allocate_guests && $scope.has_flight_ticket_products) {
                $scope.selectPaymentMethod(
                  $scope.payment_methods.PAY_ALL_BY_MYSELF
                );
                return $scope.goToFlightTicketStep();
              }else if(!skip && !$scope.show_allocate_guests && !none_addon && !$scope.has_flight_ticket_products) {
                $scope.current_redirect_step_after_availability_return = $scope.step_keys.addon;
                return $scope.goToAddonStep();
              } else if (!none_addon && $scope.show_allocate_guests && !skip) {
                $scope.current_redirect_step_after_availability_return = $scope.step_keys.addon;
                return $scope.goToAddonStep();
              } else if (none_addon && $scope.number_of_travellers == 1 && !$scope.show_allocate_guests) {
                $scope.selectPaymentMethod(
                  $scope.payment_methods.PAY_ALL_BY_MYSELF
                );
                return $scope.goToConfirmCheckout();
              } else if (none_addon && !$scope.show_allocate_guests) {
                return $scope.goToConfirmStep();
              }
            } else {
              bootbox.alert({
                message: rs.message ? rs.message : ' ',
                callback: function () {
                  $scope.removeStorage();
                  $scope.setCurrentStep($scope.step_keys.your_party);
                  window.location.href = $scope.event_booking_path;
                }
              })
            }
          });
        },
        ''
      );
    }

    $scope.viewAdditionalFee = function (product_id, room_type_id) {
      $.get(
        $scope.event_booking_path + '/show_product_additional_fee',
        { product_id: product_id, room_type_id: room_type_id },
        function (rs) {}
      );
    };

    $scope.viewFlightAdditionalFee = function (flight_id) {
      $.get(
        $scope.event_booking_path + "/show_flight_additional_fee",
        { flight_id: flight_id },
        function (rs) {}
      );
    };    

    $scope.increaseAddon = function (addon, amend_addon = null) {
      var temp = $scope.addon_data[addon.id];

      if (!temp) {
        temp = {
          id: addon.id,
          name: addon.name,
          quantity: 0,
          unit_price: 0,
          is_ticket: addon.is_ticket,
          blocks: [],
          amend: false,
          original_price: amend_addon && amend_addon.hasOwnProperty('original_unit_price') ? parseFloat(amend_addon.original_unit_price) : 0,
          original_quantity: amend_addon && amend_addon.hasOwnProperty('original_quantity') ? parseInt(amend_addon.original_quantity) : 0
        };
      }

      if (addon.stock > temp.quantity) {
        temp.quantity += amend_addon ? amend_addon.original_quantity : 1;
        var quantity_for_block = temp.quantity;
        new_blocks = [];

        for (var i = 0, len = addon.blocks.length; i < len; i++) {
          block = addon.blocks[i];
          if (quantity_for_block > block.quantity) {
            new_blocks.push(block);
            quantity_for_block -= block.quantity;
            temp.unit_price += block.quantity * block.unit_price;
          } else if (
            quantity_for_block <= block.quantity &&
            quantity_for_block > 0
          ) {
            temp.unit_price += quantity_for_block * block.unit_price;
            block_data = {
              product_block_id: block.product_block_id,
              quantity: quantity_for_block,
              unit_price: block.unit_price,
            };
            new_blocks.push(block_data);
            quantity_for_block = 0;
          } else {
            quantity_for_block = 0;
          }

          if (quantity_for_block == 0) {
            break;
          }
        }
        temp.blocks = new_blocks;
      }

      var price = 0;
      $.each(temp.blocks, function (i, x) {
        price += x.unit_price * x.quantity;
      });
      temp.unit_price = price / temp.quantity;
      $scope.addon_data[addon.id] = temp;
    };

    $scope.decreaseAddon = function (addon) {
      var temp = $scope.addon_data[addon.id];
      let original_addon_amend = {}
      Object.keys($scope.amend_addon_data).length > 0 && 
      Object.keys($scope.amend_addon_data).forEach((amend_addon) => {
        if (parseInt(amend_addon) == addon.id) {
          original_addon_amend = $scope.amend_addon_data[amend_addon]
        }
      });
      if ((!temp || temp.quantity == 0) || temp.quantity == original_addon_amend.original_quantity) {
        return false;
      }
      temp.quantity -= 1;
      temp.blocks
        .slice()
        .reverse()
        .forEach((block) => {
          block.quantity -= 1;
          if (block.quantity == 0) {
            temp.blocks.pop();
          }
        });

      if (temp.quantity == 0) {
        delete $scope.addon_data[addon.id];
      }

      var price = 0;
      $.each(temp.blocks, function (i, x) {
        price += x.unit_price * x.quantity;
      });
      temp.unit_price = price / temp.quantity;
    };

    $scope.increaseFlight = function (flight) {
      var temp = $scope.flight_data[flight.id];
      if (!temp) {
        temp = {
          id: flight.id,
          quantity: 0,
          stock: flight.stock,
          unit_price: parseFloat(flight.price_per_seat),
          source_airport_id: flight.source_airport_id,
          departure_time_outbound: flight.departure_time_outbound,
          arrival_time_outbound: flight.arrival_time_outbound,
          duration_in_minutes_outbound: flight.duration_in_minutes_outbound,
          destination_airport_id: flight.destination_airport_id,
          departure_time_inbound: flight.departure_time_inbound,
          arrival_time_inbound: flight.arrival_time_inbound,
          duration_in_minutes_inbound: flight.duration_in_minutes_inbound,
          description: flight.description,
          source_airport_code: $scope.findAirportCode($scope.source_airports, flight, true),
          destination_airport_code: $scope.findAirportCode($scope.destination_airports, flight, false),
          flight_number: flight.flight_number,
          ticket_class: flight.ticket_class,
          inbound_flight_number: flight.inbound_flight_number,
          inbound_ticket_class: flight.inbound_ticket_class,
          trip_type: flight.trip_type,
          show_terms: flight.show_terms,
          outbound_airline: flight.outbound_airline,
          inbound_airline: flight.inbound_airline,
          flight_product: $scope.flight_product.id,
          amend: false,
          original_price: flight.hasOwnProperty('original_price') ? parseFloat(flight.original_price) : 0,
          original_quantity: flight.hasOwnProperty('original_quantity') ? parseInt(flight.original_quantity) : 0
        };
      }
      $scope.flightTicketsTotalQuantity();
      if (flight.stock > temp.quantity && $scope.total_flight_tickets < $scope.number_of_travellers) {
        temp.quantity += 1;
        $scope.flight_data[flight.id] = temp;
        $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA) || $scope.flight_ticket;
        $scope.flight_ticket.flight_data = $scope.flight_data;
        $scope.flightTicketsTotalQuantity();
        $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket);
      }
    };

    $scope.decreaseFlight = function (flight) {
      var temp = $scope.flight_data[flight.id];
      let original_flight_amend = {};
      Object.keys($scope.flight_ticket_data.flight_data).length > 0 && 
      Object.keys($scope.flight_ticket_data.flight_data).forEach(org_flight => {
        if (parseInt(org_flight) == temp.id) {
          original_flight_amend = $scope.flight_ticket_data.flight_data[org_flight]
        }
      });
      if (!temp || temp.quantity == 0 || temp.quantity == original_flight_amend.quantity) {
        return false;
      }
      temp.quantity -= 1;
      
      if (temp.quantity == 0) {
        delete $scope.flight_data[flight.id];
      }
      $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA) || $scope.flight_ticket;
      $scope.flight_ticket.flight_data = $scope.flight_data;
      $scope.flightTicketsTotalQuantity();
      $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket);
    };

    $scope.flightTicketsTotalQuantity = function () {
      $scope.total_flight_tickets = 0;
      Object.keys($scope.flight_data).forEach((id) => {
        $scope.total_flight_tickets += $scope.flight_data[id].quantity;
      });
    }

    $scope.addFlightToOrder = function () {
      $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA);
      if ($scope.flight_ticket && $scope.flight_ticket.hasOwnProperty('flight_data')) {
        $scope.flight_data = $scope.flight_ticket.flight_data
      }
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      var opt = {
        event_id: $scope.event.id,
        flight_data: $scope.flight_data,
        amend_order: $scope.amend_order,
        package_id: package_id,
      }

      $.post(
        $scope.event_booking_path + '/add_flight_to_order',
        opt,
        function (rs) {
          $timeout(function () {
            // if (rs.succeed) {
            //   window.scrollTo(0, 0);
            // }
          });
        }
      );
    }

    $scope.goToConfirmStep = function () {
      Amplitude.logEvent('Viewed Confirm Page', $scope.ampEventProps)
      if (!$scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds || Object.keys($scope.hotel_beds_availability_return).length !== 0) {
        $scope.setCurrentStep($scope.step_keys.confirm);
      } else {
        $scope.current_redirect_step_after_availability_return = $scope.step_keys.confirm;
      }
      const customer_default_package =
        $scope.customer_hotels[$scope.customer_select].package_id;
      // none addon setting || just support for single customer type
      if ($scope.has_addon_setting) {
        if (!$scope.pre_booking_data) {
          if (!customer_default_package) {
            console.log(
              'Multiple customer types are just support for none addon setting'
            );
            return rebuildConfirmStep();
          }
        }
      } else {
        return rebuildConfirmStep();
      }

      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (Object.keys($scope.addon_data).length > 0) {
        var opt = {
          event_id: $scope.event.id,
          package_id: package_id,
          addon_data: $scope.addon_data,
          trip_date_id: $scope.my_trip_date != null ? $scope.my_trip_date.id : null,
        }

        $.post(
          $scope.event_booking_path + '/add_addon_to_order',
          opt,
          function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                window.scrollTo(0, 0);
                return rebuildConfirmStep();
              }
            });
          }
        );
      }else{
        return rebuildConfirmStep();
      }
    };

    $scope.startOver = function () {
      bootbox.confirm(
        'Are you sure to start over your booking?',
        function (rs) {
          if (rs) {
            $.post(
              $scope.event_booking_path + '/start_over',
              {},
              function (rs) {
                $scope.removeStorage();
                $scope.setCurrentStep($scope.step_keys.your_party);
                window.location.href = $scope.event_booking_path;
                Amplitude.logEvent('Restart Booking', $scope.ampEventProps)
              }
            );      
          }
        }
      );
    };

    $scope.increaseOccupantAddon = function (addon) {
      if ($scope.addon_data[addon.addon_id].guest_stock == undefined) {
        var total_guest_quantity = 0;
        $scope.rooms.forEach((room) => {
          room.members.forEach((member) => {
            var member_addon = member.addons.find(
              (item) => item.addon_id == addon.addon_id
            );
            total_guest_quantity +=
              (member_addon && member_addon.quantity) || 0;
          });
        });
        $scope.addon_data[addon.addon_id].guest_stock = $scope.addon_data[addon.addon_id].quantity - total_guest_quantity;
      }
      var can_increase_addon =
        addon.total_quantity > 0 &&
        $scope.addon_data[addon.addon_id].guest_stock > 0;

      if (can_increase_addon) {
        addon.quantity += 1;
        $scope.addon_data[addon.addon_id].guest_stock -= 1;
        $scope.changeRoomOptions();
      }
    };

    $scope.decreaseOccupantAddon = function (addon) {
      if (addon.quantity > 0) {
        addon.quantity -= 1;
        $scope.addon_data[addon.addon_id].guest_stock += 1;
        $scope.changeRoomOptions();
      }
    };

    $scope.increaseOccupantFlight = function (flight) {
      if ($scope.flight_data[flight.flight_id].guest_stock == undefined || isNaN($scope.flight_data[flight.flight_id].guest_stock)) {
        var total_guest_quantity = 0;
        $scope.rooms.forEach((room) => {
          room.members.forEach((member) => {
            var member_flight = member.flights.find(
              (item) => item.flight_id == flight.flight_id
            );
            total_guest_quantity +=
              (member_flight && member_flight.quantity) || 0;
          });
        });
        $scope.flight_data[flight.flight_id].guest_stock = $scope.flight_data[flight.flight_id].quantity - total_guest_quantity;
      }
      var can_increase_flight =
        flight.total_quantity > 0 &&
        $scope.flight_data[flight.flight_id].guest_stock > 0;

      if (can_increase_flight) {
        flight.quantity += 1;
        $scope.flight_data[flight.flight_id].guest_stock -= 1;
        $scope.changeRoomOptions();
      }
    };

    $scope.decreaseOccupantFlight = function (flight) {
      if (flight.quantity > 0) {
        flight.quantity -= 1;
        $scope.flight_data[flight.flight_id].guest_stock += 1;
        $scope.changeRoomOptions();
      }
    };

    /**
     * selected pay all -> pay full -> true
     * if method = pay share && is already save store(reload | back to) -> re-build $scope.rooms
     */
    $scope.selectPaymentMethod = function (method) {
      $scope.payment_method = method;
      $scope.pay_full = $scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF;
      if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
        Amplitude.logEvent('Clicked Split Payment Button', $scope.ampEventProps)
        $scope.payment_data.amendment_credits.applied = parseFloat($scope.payment_data.amendment_credits.mine)
        buildSplitOrders(() => {
          $scope.allocating_room_number = 1;
          $scope.changeRoomOptions();
          $scope.setStorage($scope.event_store.PAYMENT_METHOD, method);
        });
      } else {
        Amplitude.logEvent('Clicked Pay All Button', $scope.ampEventProps)
        $scope.payment_data.amendment_credits.applied = parseFloat($scope.payment_data.amendment_credits.usable)
        if ($scope.hotel_beds_availability_return) {
          if (!$scope.confirm_pricings) {
            $scope.confirm_pricings = {
              payment_data: $scope.payment_data
            };
          }
          saved_payment_data = $scope.confirm_pricings.payment_data.payment_amount
        }
        buildRoomMembers(() => { 
            $scope.setStorage($scope.event_store.PAYMENT_METHOD, method)
            if ($scope.hotel_beds_availability_return) {
              $scope.confirm_pricings.payment_data.payment_amount = saved_payment_data
              $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
              $scope.$applyAsync();
            }
          }
        );
      }
      $scope.setStorage($scope.event_store.PAYMENT_METHOD, method);
    };

    $scope.selectPaymentOption = function (option) {
      let option_string;
      switch(option){
        case $scope.payment_options.PAY_EVERYTHING:
          option_string = 'Payment Method: Pay Everything';
          break;
        case $scope.payment_options.PAY_INSTALLMENT:
          option_string = 'Payment Method: Pay Installments';
          break;
        case $scope.payment_options.PAY_AFFIRM:
          option_string = 'Payment Method: Pay Affirm';
      }
      Amplitude.logEvent(`Clicked Button: ${option_string}`, $scope.ampEventProps)
      $scope.payment_option = option;
      $scope.setStorage(
        $scope.event_store.PAYMENT_OPTION,
        $scope.payment_option
      );
    };

    $scope.selectPaymentPlanOption = function (option, plan_option) {
      $scope.payment_plan_option = plan_option;
      $scope.payment_option = option;
      $scope.setStorage(
        $scope.event_store.PAYMENT_OPTION,
        $scope.payment_option
      );
      $scope.setStorage(
        $scope.event_store.PAYMENT_PLAN_OPTION,
        $scope.payment_plan_option
      );
    };

    $scope.goToConfirmCheckout = function () {
      $scope.verifyGuidToken();
      $scope.setCurrentStep($scope.step_keys.confirm);
      $scope.setCurrentTrackedStep('Confirm Checkout');
      window.scrollTo(0, 0);

      if ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF) {
        buildRoomMembers(() => {
          $scope.getInstallments();
          if ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF) {
            $scope.fetchQueryStrings();
          }
          $scope.show_payment = true;
          $scope.setStorage($scope.event_store.PAY_FULL_ORDER_DATA, $scope.rooms);
          $scope.setStorage($scope.event_store.REQUIRE_NAMES_PAY_FULL, $scope.require_guest_names_on_pay_full);
        });
      }
      //scope.number_of_travellers == 1 ? $scope.fetchQueryStrings(): '';
      $scope.getInstallments();
      if ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF) {
      $scope.fetchQueryStrings();
      }
      $scope.show_payment = true;
    };

    /**
     * one traveler -> pay all method
     * can checkout -> next to payment
     */
    $scope.goToPaymentStep = function () {
      // TODO test if this is not required
      // if ($scope.customer_hotels.default.hotel_has_hotelbeds && Object.keys($scope.hotel_beds_availability_return).length === 0) {
      //   return
      // }
      $scope.verifyGuidToken();
      window.scrollTo(0, 0);
      if ($scope.number_of_travellers === 1) {
        $scope.payment_method = $scope.payment_methods.PAY_ALL_BY_MYSELF;
      }
      $scope.setStorage($scope.event_store.PAYMENT_METHOD, $scope.payment_method);
      $scope.setCurrentStep($scope.step_keys.payment);
      $scope.setCurrentTrackedStep('Payment');
      Amplitude.logEvent('Viewed Add Payment Method Page', $scope.ampEventProps);
    };

    $scope.selectMyRoom = function (room) {
      room.mine = !room.mine;
      var fullname = room.members[0].name;
      var email = room.members[0].email;

      if ($scope.current_user) {
        fullname =
          $scope.current_user.first_name + ' ' + $scope.current_user.last_name;
        email = $scope.current_user.email;
      }

      var filled_my_info = $.grep(room.members, function (m, i) {
        return m.email == email;
      });

      if (room.mine) {
        $.each($scope.rooms, function (i, r) {
          if (r.room_number != room.room_number) {
            r.mine = false;
          }
        });

        if (filled_my_info.length == 0) {
          filled_my_info = room.members[0];
          filled_my_info.paid = true;
          filled_my_info.name = fullname;
          filled_my_info.email = email;
        } else {
          filled_my_info[0].paid = true;
          filled_my_info[0].name = fullname;
          filled_my_info[0].email = email;
        }
      } else {
        room.members[0].paid = false;
      }

      $scope.changeRoomOptions();
    };

    $scope.changeRoomOptions = function (is_confirm = false) {
      var order_price = 0;
      var original_price = 0;
      var invalid_members = [];
      var valid_members = [];
      var room_data = {};
      var has_mine, has_paid, duplicated_member, has_me_in_room;
      has_mine = has_paid = duplicated_member = has_me_in_room = false;
      var my_room_number = 0;
      var every_room_has_person = true;
      $.each($scope.rooms, function (i, room) {
        if (room.mine) {
          has_mine = true;
          my_room_number = room.room_number;
        }

        var valid_items = [];
        var invalid_items = [];
        $.each(room.members, function (i, member) {
          const valid_name = member.name != '';
          const valid_email =
            ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF && !$scope.require_guest_names_on_pay_full)
              ? member.email != ''
              : EMAIL_PATTERN.test(member.email);
          member.valid_email = valid_email;
          if ((valid_name && valid_email) || (is_confirm && !$scope.require_guest_names_on_pay_full)) {
            valid_items.push(member);
          } else {
            invalid_items.push(member);
          }
        });

        valid_members = valid_members.concat(valid_items);
        invalid_members = invalid_members.concat(invalid_items);

        if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
          var valid_member_count = valid_members.filter(
            (item) => item.room_number == $scope.allocating_room_number
          ).length;
          var member_count = $scope.rooms.filter(
            (room) => room.room_number == $scope.allocating_room_number
          )[0].members.length;
          $scope.enable_allocate_next_room = valid_member_count == member_count;
        } else {
          var valid_member_count = valid_members.filter(
            (item) => item.room_number == $scope.allocating_room_number
          ).length;
          var member_count = $scope.rooms.filter(
            (room) => room.room_number == $scope.allocating_room_number
          )[0].members.length;
          $scope.enable_allocate_next_room = valid_member_count == member_count;
        }

        room_data[room.room_number] = {
          number_of_occupants: valid_items.length,
          room_prices: room.room_prices,
          original_price: room.original_price,
          original_quantity: room.original_quantity
        };

        if (valid_items.length == 0) {
          every_room_has_person = false;
        }
      });

      var valid_member_count = valid_members.length
      $.each(invalid_members, function (i, member) {
        var room = room_data[member.room_number];
        room_price_aux = room.room_prices.find((price) => price.number_of_occupants == room.room_prices.length);
        if (member.paid != true) {
          $.each(member.addons, function (j, a) {
            $scope.addon_data[a.addon_id].guest_stock -= a.quantity;
            a.quantity = 0;
            member.paid = $scope.pay_full;
            member.unit_price = 0;
            member.down_payment = 0;
            member.amount = 0;
            member.original_price = 0;
            member.original_quantity = 0;
          });
          if ($scope.has_flight_ticket_products) {
            $.each(member.flights, function (j, a) {
              $scope.flight_data[a.flight_id].guest_stock -= a.quantity;
              a.quantity = 0;
              member.paid = $scope.pay_full;
              member.unit_price = 0;
              member.down_payment = 0;
              member.amount = 0;
            });
          }
        }
        member.amount_hotel_discountable = room_price_aux.unit_price;
      });

      var emails = [];
      var total_paid = 0;
      var addon_qty_data = {};
      var flight_qty_data = {};
      $.each(valid_members, function (i, member) {
        if (member.paid) {
          has_paid = true;
          total_paid += 1;
        }

        if (
          member.room_number == my_room_number &&
          member.email == $scope.current_user
            ? $scope.current_user.email
            : 'TBD #1'
        ) {
          has_me_in_room = true;
        }

        if (emails.indexOf(member.email) >= 0) {
          duplicated_member = true;
          $scope.split_bill_error_message =
            'Your members emails are duplicated';
        }

        emails.push(member.email);

        var room = room_data[member.room_number];
        var room_price = room.room_prices.find((price) => price.number_of_occupants == room.number_of_occupants);
        member.unit_price = parseFloat(room_price?.unit_price);
        member.down_payment = parseFloat(room_price?.down_payment);
        if (member.original_price == 0 || member.original_quantity == 0) {
          original_price += member.unit_price;
          var member_amount = parseFloat(member.unit_price);
        }else{
          original_price += member.original_price;
          var member_amount = parseFloat(member.original_price);
        }
        var member_hotel_fee = 0;
        // Additional fee for hotels
        if (room_price && room_price.additional_fees.length) {
          room_price.additional_fees.forEach((hotel_fee) => {
            if (hotel_fee.include_in_bill) {
              if (room.original_price == 0 && room.original_quantity == 0) {
                member_amount += parseFloat(hotel_fee.amount);
                member_hotel_fee += parseFloat(hotel_fee.amount);
              }else{
                let hote_fee_amount = 0.0;
                if (hotel_fee.fee_type) {
                  hote_fee_amount = parseFloat(room.original_price * 
                                    hotel_fee.fee_value /
                                    100)
                  member_amount += hote_fee_amount;
                  member_hotel_fee += hote_fee_amount;
                }else{
                  hote_fee_amount = hotel_fee.fee_value
                  member_amount += hote_fee_amount;
                  member_hotel_fee += hote_fee_amount;
                }
              }
            }
          });
        }
        member.total_hotel_fee = member_hotel_fee;

        $.each(member.addons, function (j, a) {
          let qty = parseInt(a.quantity);
          let org_qty = parseInt(a.original_quantity);
          if (!addon_qty_data[a.addon_id]) {
            addon_qty_data[a.addon_id] = 0;
          }
          addon_qty_data[a.addon_id] += qty;

          var addon_fee = 0;
          var original_addon_fee = 0;
          if (Object.keys($scope.addon_additional_fees).length) {
            $scope.addon_additional_fees.forEach(function (fee) {
              if (a.addon_id == fee.product_id && fee.include_in_bill) {
                if (fee.fee_type) {
                  if (a.original_price == 0 || a.original_quantity == 0) {
                    addon_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                  }else {
                    addon_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                    original_addon_fee +=
                      (parseFloat(a.original_price) * parseFloat(fee.fee_value)) /
                      100;
                  }
                } else {
                  addon_fee += parseFloat(fee.fee_value);
                }
              }
            });
          }
          member_amount += org_qty * (parseFloat(a.original_price) + original_addon_fee) + (qty - org_qty) * (parseFloat(a.unit_price) + addon_fee);
          original_price += org_qty * parseFloat(a.original_price) + (qty - org_qty) * parseFloat(a.unit_price);
        });
        if ($scope.has_flight_ticket_products) {
          $.each(member.flights, function (j, a) {
            let qty = parseInt(a.quantity);

            if (!flight_qty_data[a.flight_id]) {
              flight_qty_data[a.flight_id] = 0;
            }
            flight_qty_data[a.flight_id] += qty;

            var flight_fee = 0;
            if (Object.keys($scope.flight_additional_fees).length) {
              $scope.flight_additional_fees.forEach(function (fee) {
                if (a.source_airport_id == fee.airport_id && fee.include_in_bill) {
                  if (fee.fee_type == 'percent') {
                    flight_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                  } else {
                    flight_fee += parseFloat(fee.fee_value);
                  }
                }
              });
            }
            member_amount += qty * (parseFloat(a.unit_price) + flight_fee);
            original_price += qty * parseFloat(a.unit_price);
          });
        }
        member.amount = member_amount.toFixed(8);
        order_price += parseFloat(member.amount);
      });

      if ($scope.has_ticket_products) {
        $scope.payment_data.order_price = order_price + parseFloat(totalSelectedTicketsCost($scope.number_of_travellers));
        $scope.payment_data.original_price = original_price + parseFloat(totalSelectedTicketsCost($scope.number_of_travellers));
      }

      // validate addons qty
      var valid_addon_qty = true;
      if (!$scope.pay_full) {
        $.each($scope.addon_data, function (addon_id, data) {
          if (addon_qty_data[addon_id] != data.quantity) {
            valid_addon_qty = false;
            return false;
          }
        });
      }

      if ($scope.has_flight_ticket_products) {
        // validate flights qty
        var valid_flight_qty = true;
        if (!$scope.pay_full) {
          $.each($scope.flight_data, function (flight_id, data) {
            if (flight_qty_data[flight_id] != data.quantity) {
              valid_flight_qty = false;
              return false;
            }
          });
        }
      }

      if ($scope.has_flight_ticket_products) {
        var can_checkout =
          parseInt($scope.number_of_travellers) == valid_member_count &&
          valid_addon_qty &&
          valid_flight_qty &&
          every_room_has_person;
      }else{
        var can_checkout =
          parseInt($scope.number_of_travellers) == valid_member_count &&
          valid_addon_qty &&
          every_room_has_person;
      }

      $scope.calculateGrandAmount(is_confirm);

      // TODO check if any room is hotelbeds to not require grand amount
      is_hotelbeds = $scope.rooms.find((room) => room.is_room_hb == true)

      $scope.can_checkout =
        can_checkout &&
        has_mine &&
        has_paid &&
        has_me_in_room &&
        !duplicated_member &&
        (total_paid < valid_member_count || 
         ($scope.require_guest_names_on_pay_full && total_paid == valid_member_count) || 
         (!$scope.require_guest_names_on_pay_full && $scope.pay_full)) &&
        (parseFloat($scope.payment_data.grand_amount) > 0 || typeof is_hotelbeds !== 'undefined');

      if (!$scope.can_checkout) {
        if (!has_mine) {
          $scope.split_bill_error_message = 'Please select your room';
        } else if (!has_paid) {
          $scope.split_bill_error_message =
            'Please select at least 1 and no more than ' +
            $scope.number_of_travellers +
            ' persons to pay for';
        } else if (
          parseInt($scope.number_of_travellers) != valid_member_count
        ) {
          let number_of_member =
            parseInt($scope.number_of_travellers) - valid_member_count;
          if (number_of_member > 0) {
            $scope.split_bill_error_message =
              'Please input name and email of ' +
              number_of_member +
              ' more person(s)!';
          } else {
            $scope.split_bill_error_message =
              'Please reallocate ' +
              Math.abs(number_of_member) +
              ' person(s) and make sure each room has at least 1 member';
          }
        } else if (!valid_addon_qty) {
          $scope.split_bill_error_message =
            'Please allocate all add-ons to persons';
        } else if ($scope.has_flight_ticket_products && !valid_flight_qty) {
          $scope.split_bill_error_message =
            'Please allocate all flight tickets to persons';
        } else if (!every_room_has_person) {
          $scope.split_bill_error_message =
            'Please allocate at least 1 person in each room';
        }
      } else {
        $scope.split_bill_error_message = '';
      }
    };

    $scope.calculateGrandAmount = function (is_confirm = false) {
      $scope.confirm_pricings = $scope.hydrateScope();
      $scope.confirm_pricings_transition = $scope.hydrateScope();
      var grand_amount = 0.0;
      var total_paid = 0;
      var discountable_order_price = 0.0;
      var discountable_grand_amount = 0.0;
      $.each($scope.rooms, function (i, room) {
        $.each(room.members, function (i, member) {
          $.each(member.addons, function (i, addon) {
            var addon_fee = 0;
            var original_addon_fee = 0;
            if (Object.keys($scope.addon_additional_fees).length) {
              $scope.addon_additional_fees.forEach(function (fee) {
                if (addon.addon_id == fee.product_id && fee.include_in_bill) {
                  if (fee.fee_type) {
                    if (addon.original_price == 0 || addon.original_quantity == 0) {
                      addon_fee +=
                        (parseFloat(addon.unit_price) * parseFloat(fee.fee_value)) /
                        100;
                    }else {
                      addon_fee +=
                        (parseFloat(addon.unit_price) * parseFloat(fee.fee_value)) /
                        100;
                      original_addon_fee +=
                        (parseFloat(addon.original_price) * parseFloat(fee.fee_value)) /
                        100;
                    }
                  } else {
                    if (addon.original_price == 0 || addon.original_quantity == 0) {
                      addon_fee += parseFloat(fee.fee_value);
                    }else{
                      original_addon_fee += parseFloat(fee.fee_value);
                    }
                  }
                }
              });
            }
            if (addon.original_price == 0 || addon.original_quantity == 0) {
              discountable_order_price += (parseFloat(addon.unit_price) + addon_fee) * parseInt(addon.quantity);
            }else{
              discountable_order_price += ((parseFloat(addon.original_price) + original_addon_fee) * addon.original_quantity) + ((addon.quantity - parseFloat(addon.original_quantity)) * (parseFloat(addon.unit_price) + addon_fee));
            }
          });
          let original_room_fee = 0;
          let room_fee = 0;
          if (Object.keys($scope.hotel_additional_fees).length) {
            $scope.hotel_additional_fees.forEach(function (fee) {
              if (member.room_type_id == fee.room_type_id && fee.include_in_bill) {
                if (fee.fee_type) {
                  if (member.original_price == 0 || member.original_quantity == 0) {
                    room_fee +=
                      (parseFloat(member.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                  }else {
                    room_fee +=
                      (parseFloat(member.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                    original_room_fee +=
                      (parseFloat(member.original_price) * parseFloat(fee.fee_value)) /
                      100;
                  }
                } else {
                  room_fee += parseFloat(fee.fee_value);
                  original_room_fee =+ parseFloat(fee.fee_value)
                }
              }
            });
          }
          if (member.original_price == 0 || member.original_quantity == 0) {
            if (member.unit_price == 0) {
              discountable_order_price += parseFloat(member.amount_hotel_discountable);
            } else {
              discountable_order_price += parseFloat(member.unit_price);
            }
          }else{
            discountable_order_price += parseFloat(member.original_price) + original_room_fee;
          }
        });
        var paid_items = $.grep(room.members, function (x, o) {
          return x.paid;
        });

        total_paid += paid_items.length;
        $.each(paid_items, function (j, member) {
          grand_amount += parseFloat(member.amount);
          $.each(member.addons, function (h, addon) {
            var addon_fee = 0;
            var original_addon_fee = 0;
            if (Object.keys($scope.addon_additional_fees).length) {
              $scope.addon_additional_fees.forEach(function (fee) {
                if (addon.addon_id == fee.product_id && fee.include_in_bill) {
                  if (fee.fee_type) {
                    if (addon.original_price == 0 || addon.original_quantity == 0) {
                      addon_fee +=
                        (parseFloat(addon.unit_price) * parseFloat(fee.fee_value)) /
                        100;
                    }else {
                      addon_fee +=
                        (parseFloat(addon.unit_price) * parseFloat(fee.fee_value)) /
                        100;
                      original_addon_fee +=
                        (parseFloat(addon.original_price) * parseFloat(fee.fee_value)) /
                        100;
                    }
                  } else {
                    addon_fee += parseFloat(fee.fee_value);
                  }
                }
              });
            }
            if (addon.original_price == 0 || addon.original_quantity == 0) {
              discountable_grand_amount += (parseFloat(addon.unit_price) + addon_fee) * parseInt(addon.quantity);
            }else{
              discountable_grand_amount += parseInt(addon.original_quantity) * (parseFloat(addon.original_price) + original_addon_fee) + ((parseInt(addon.quantity) - parseInt(addon.original_quantity)) * (parseFloat(addon.unit_price) + addon_fee))
            }
          });
          if (member.original_price == 0 || member.original_quantity == 0) {
            discountable_grand_amount += parseFloat(member.unit_price);
          }else{
            discountable_grand_amount += parseFloat(member.original_price);
          }
        });
      });

      if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
        grand_amount = grand_amount + parseFloat(totalSelectedTicketsCost(total_paid));
      }
      $scope.total_paid = total_paid;
      if ($scope.pay_full || is_confirm) {
        $.each($scope.addon_data, function (addon_id, a) {
          let qty = parseInt(a.quantity);
          let org_qty = parseInt(a.original_quantity);
          var addon_fee = 0;
          var original_addon_fee = 0;
          if (Object.keys($scope.addon_additional_fees).length) {
            $scope.addon_additional_fees.forEach(function (fee) {
              if (addon_id == fee.product_id && fee.include_in_bill) {
                if (fee.fee_type) {
                  if (a.original_price == 0 || a.original_quantity == 0) {
                    addon_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                  }else {
                    addon_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                    original_addon_fee +=
                      (parseFloat(a.original_price) * parseFloat(fee.fee_value)) /
                      100;
                  }
                } else {
                  addon_fee += parseFloat(fee.fee_value);
                }
              }
            });
          }
          grand_amount += org_qty * (parseFloat(a.original_price) + original_addon_fee) + (qty - org_qty) * (parseFloat(a.unit_price) + addon_fee);
          discountable_grand_amount += org_qty * (parseFloat(a.original_price) + original_addon_fee) + (qty - org_qty) * (parseFloat(a.unit_price) + addon_fee);
          discountable_order_price += org_qty * (parseFloat(a.original_price) + original_addon_fee) + (qty - org_qty) * (parseFloat(a.unit_price) + addon_fee);
          $scope.payment_data.order_price += org_qty * (parseFloat(a.original_price) + original_addon_fee) + (qty - org_qty) * (parseFloat(a.unit_price) + addon_fee);
          $scope.payment_data.original_price += org_qty * parseFloat(a.original_price) + (qty - org_qty) * parseFloat(a.unit_price);
        });

        if ($scope.has_flight_ticket_products) {
          $.each($scope.flight_data, function (flight_id, a) {
            let qty = parseInt(a.quantity);

            var flight_fee = 0;
            if (Object.keys($scope.flight_additional_fees).length) {
              $scope.flight_additional_fees.forEach(function (fee) {
                if (a.source_airport_id == fee.airport_id && fee.include_in_bill) {
                  if (fee.fee_type == 'percent') {
                    flight_fee +=
                      (parseFloat(a.unit_price) * parseFloat(fee.fee_value)) /
                      100;
                  } else {
                    flight_fee += parseFloat(fee.fee_value);
                  }
                }
              });
            }
            grand_amount += qty * (parseFloat(a.unit_price) + flight_fee);
            let flight_included = false;
            $scope.discount_code.forEach(discount => {
              if (discount.include_flights) {
                flight_included = true
              }
            });
            if (flight_included) {
              discountable_grand_amount += qty * (parseFloat(a.unit_price) + flight_fee);
              discountable_order_price += qty * (parseFloat(a.unit_price) + flight_fee);
            }
            $scope.payment_data.order_price += qty * (parseFloat(a.unit_price) + flight_fee);
            $scope.payment_data.original_price += qty * parseFloat(a.unit_price);
          });
        }
        if ($scope.has_ticket_products) {
          discountable_order_price += parseFloat(totalSelectedTicketsCost($scope.number_of_travellers))
          discountable_grand_amount += parseFloat(totalSelectedTicketsCost($scope.number_of_travellers))
          grand_amount = grand_amount + parseFloat(totalSelectedTicketsCost($scope.number_of_travellers));
        }
        $scope.payment_data.order_price = grand_amount;
        $scope.payment_data.original_price = grand_amount;
      }

      if ($scope.include_insurance) {
        $scope.payment_data.insurance_amount = parseFloat($scope.event.insurance_amount) * total_paid;
      }
      $scope.payment_data.grand_amount = grand_amount;
      $scope.payment_data.discountable_order_price = discountable_order_price;
      $scope.payment_data.discountable_grand_amount = discountable_grand_amount;
      recalculatePaymentAmount(total_paid);
    };

    $scope.saveSplitOrders = function () {
      if ($scope.can_checkout) {
        $scope.split_orders = [];
        $scope.my_products = { rooms: {}, addons: {}, flights: {} };
        $.each($scope.rooms, function (i, room) {
          if (room.mine) {
            var my_account = room.members[0];
            var fullname = my_account.name.split(' ');
            if (fullname.length > 0) {
              $scope.signup_user.first_name = fullname[0];
            }
            if (fullname.length > 1) {
              $scope.signup_user.last_name = fullname[fullname.length - 1];
            }
            $scope.signup_user.email = my_account.email;
          }

          var valid_members = $.grep(room.members, function (x, i) {
            return x.name != '' && x.email != '';
          });

          $.each(valid_members, function (j, member) {
            var mine = $scope.current_user
              ? room.mine && member.email == $scope.current_user.email
              : room.mine && room.members[0] === member;

            var addons = $.grep(member.addons, function (a, i) {
              return a.quantity > 0;
            });

            var flights = $.grep(member.flights, function (a, i) {
              return a.quantity > 0;
            });

            $scope.split_orders.push({
              hotel_id: room.hotel_id,
              room_number: room.room_number,
              room_type_id: room.room_type_id,
              unit_price: member.unit_price,
              down_payment: member.down_payment,
              name: member.name,
              email: member.email,
              mine: mine,
              paid: member.paid,
              addons: addons,
              flights: flights,
              amount: member.amount,
            });
            // build my cart items
            if (member.paid) {
              var room_temp = $scope.my_products.rooms[room.room_type_id];
              var room_price = room.room_prices.find(
                (price) => price.number_of_occupants == valid_members.length
              );

              if (!room_temp) {
                room_temp = {
                  hotel_name: room.hotel_name,
                  room_name: room.room_name,
                  unit_price: parseFloat(member.unit_price),
                  down_payment: parseFloat(member.down_payment),
                  original_price: parseFloat(member.original_price),
                  original_quantity: parseInt(member.original_quantity),
                  quantity: 1,
                  members: [member],
                  additional_fees: [],
                };

                // deep copy
                room_price.additional_fees.forEach((room_fee) => {
                  room_temp.additional_fees.push({
                    amount: room_fee.amount,
                    explanation: room_fee.explanation,
                    fee_type: room_fee.fee_type,
                    fee_value: room_fee.fee_value,
                    id: room_fee.id,
                    include_in_bill: room_fee.include_in_bill,
                    name: room_fee.name,
                  });
                });
              } else {
                room_temp.unit_price += parseFloat(member.unit_price);
                room_temp.down_payment += parseFloat(member.down_payment);
                room_temp.members.push(member);

                room_temp.additional_fees.forEach((room_fee) => {
                  var same_fee = room_price.additional_fees.find(
                    (fee) => fee.id == room_fee.id
                  );
                  if (same_fee) {
                    room_fee.amount = (
                      parseFloat(room_fee.amount) + parseFloat(same_fee.amount)
                    ).toFixed(8);
                  }
                });
              }

              $scope.my_products.rooms[room.room_type_id] = room_temp;
              $.each(member.addons, function (i, a) {
                var addon_temp = $scope.my_products.addons[a.addon_id];
                if (!addon_temp) {
                  addon_temp = {
                    addon_name: a.addon_name,
                    unit_price: parseFloat(a.unit_price),
                    quantity: parseInt(a.quantity),
                    original_price: parseFloat(a.original_price),
                    original_quantity: parseInt(a.original_quantity),
                  };
                } else {
                  addon_temp.quantity += parseInt(a.quantity);
                }

                if (addon_temp.quantity > 0) {
                  $scope.my_products.addons[a.addon_id] = addon_temp;
                }
              });
              $.each(member.flights, function (i, a) {
                var flight_temp = $scope.my_products.flights[a.flight_id];
                if (!flight_temp) {
                  flight_temp = {
                    destination_airport_id: a.destination_airport_id,
                    source_airport_id: a.source_airport_id,
                    source_airport_code: a.source_airport_code,
                    destination_airport_code: a.destination_airport_code,
                    departure_time_outbound: a.departure_time_outbound,
                    departure_time_inbound: a.departure_time_inbound,          
                    ticket_class: a.ticket_class,
                    flight_number: a.flight_number,
                    inbound_ticket_class: a.inbound_ticket_class,
                    inbound_flight_number: a.inbound_flight_number,
                    unit_price: parseFloat(a.unit_price),
                    quantity: parseInt(a.quantity),
                    outbound_airline: a.outbound_airline,
                    inbound_airline: a.inbound_airline,
                    show_terms: a.show_terms
                  };
                } else {
                  flight_temp.quantity += parseInt(a.quantity);
                }

                if (flight_temp.quantity > 0) {
                  $scope.my_products.flights[a.flight_id] = flight_temp;
                }
              });
            }
          });
        });
        $scope.show_payment = true;
        buildMyProductAdditionalFees();
        $scope.changeRoomOptions();
        recalculatePaymentAmount($scope.total_paid);
        $scope.getInstallments();
        $scope.setPageTrack();
        $scope.setCurrentTrackedStep('Confirm Checkout');
        $scope.setStorage($scope.event_store.SPLIT_ORDER_DATA, $scope.rooms);
        window.scrollTo(0, 0);
      }
    };

    $scope.saveRoomMembers = function (called_from_hotelbeds_availability_return = false) {
      $.each($scope.rooms, function (i, room) {
        // Create flags for situation where it is called from availability hotelbeds, but we are going to a room not using hotelbeds, this case prices/addon shouldn't be changed 
        let is_cms_inventory_room_in_mixed_inventory_with_hotelbeds = called_from_hotelbeds_availability_return && !room.is_room_hb
        var valid_members = $.grep(room.members, function (x, i) {
          return x.name != '' && x.email != '';
        });

        if (!is_cms_inventory_room_in_mixed_inventory_with_hotelbeds) {
          $.each(valid_members, function (j, member) {
            var room_price = room.room_prices.find(
              (price) => price.number_of_occupants == valid_members.length
            );
            room.unit_price ||= 0;
            room.unit_price += parseFloat(member.unit_price);

            room.down_payment ||= 0;
            room.down_payment += parseFloat(member.down_payment);

            if (!room.is_room_hb) {
              if (!room.additional_fees || !room.additional_fees.length) {
                room.additional_fees = [];
                room_price.additional_fees.forEach((room_fee) => {
                  let room_fee_amount = 0;
                  if (room.original_price == 0 || room.original_quantity == 0) {
                    room_fee_amount = room_fee.amount
                  }else{
                    if (room_fee.fee_type) {
                      room_fee_amount = room.original_price *
                                        room_fee.fee_value /
                                        100;
                    }else{
                      room_fee_amount = room_fee.amount
                    }
                  }
                  room.additional_fees.push({
                    amount: room_fee_amount,
                    explanation: room_fee.explanation,
                    fee_type: room_fee.fee_type,
                    fee_value: room_fee.fee_value,
                    id: room_fee.id,
                    include_in_bill: room_fee.include_in_bill,
                    name: room_fee.name,
                  });
                });
              } else {
                room.additional_fees.forEach((room_fee) => {
                  var same_fee = room_price.additional_fees.find(
                    (fee) => fee.id == room_fee.id
                  );
                  if (same_fee) {
                    room_fee.amount = (
                      parseFloat(room_fee.amount) + parseFloat(same_fee.amount)
                    ).toFixed(8);
                  }
                });
              }
            }
          });
        }
      });
      buildProductAdditionalFees();
    };

    $scope.getInstallments = function () {
      let down_payment = 0.0;
      let people_group_leader_paying = 0;
      let people_have_down_payment = 0;
      let package_id = null

      $scope.rooms.forEach((room) => {
        room.members.forEach((member) => {
          if (member.paid) {
            people_group_leader_paying += 1;
            if (member.down_payment != 0) {
              people_have_down_payment += 1;
            }
            down_payment += member.down_payment;
          }
        });
      });
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      var opt = {
        event_id: $scope.event.id,
        payment_amount: $scope.payment_data.payment_amount,
        down_payment: down_payment,
        amendment: $scope.amend_order,
        split_orders: angular.toJson($scope.split_orders),
        discount_code: angular.toJson($scope.discount_code),
        payment_data: $scope.payment_data,
        include_insurance: $scope.include_insurance,
        payment_method: $scope.payment_method,
        amend_order: $scope.amend_order,
        people_group_leader_paying: people_group_leader_paying,
        people_have_down_payment: people_have_down_payment,
        package_id: package_id
      };
      $.post(
        $scope.event_booking_path + '/get_installments',
        opt,
        function (rs) {
          $timeout(function () {
            $scope.payment_plans = rs[0];
            $scope.payment_plan_names = rs[1];
            $scope.affirm_position = 0;
            any_payment_plan_available = false;
            if (rs.length === 3) {
              $scope.affirm_position = rs[2];
              $.each($scope.payment_plans, function (i, payment_plan_option) {
                if (payment_plan_option.length > 1) {
                  any_payment_plan_available = true
                }
              });

            }
            if (!any_payment_plan_available) {
              $scope.selectPaymentOption(
                $scope.payment_options.PAY_EVERYTHING
              );
            }
          });
        }
      );
    };

    $scope.doPayment = function () {
      var isPrecznAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.PRECZN && $scope.credit_card.is_new_one;
      var isStripeAndNewCard =  $scope.event.payment_gateway == $scope.payment_gateways.STRIPE && $scope.credit_card.is_new_one;
      var isBraintreeAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.BRAINTREE && $scope.credit_card.is_new_one;
      var isAuthorize_netAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.AUTHORIZE_NET && $scope.credit_card.is_new_one;
      
      // Revenue tag instead?
      Amplitude.logEvent('Payment attempt made', $scope.ampEventProps)
      $scope.verifyGuidToken();
      $scope.error_message = '';
      if (!$scope.stripe_stress_test && isPrecznAndNewCard) {
        $scope.credit_card = $scope.validatePrecznCreditCard($scope.credit_card);
        if ($scope.credit_card.error_message) {
          $scope.error_message = $scope.credit_card.error_message;
          bootbox.alert($scope.error_message);
          return false;
        }
        $('.loading').fadeIn();

        var callbackFn = function (credit_card) {
          if ($.trim(credit_card.preczn_single_use_token) == '' || credit_card.error_message) {
            $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
            $('.loading').fadeOut();
            bootbox.alert($scope.error_message);
            return false;
          }

          checkoutOrder();
        }
        $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
      } else if (!$scope.stripe_stress_test && isStripeAndNewCard) {
        $scope.credit_card = $scope.validateCreditCard($scope.credit_card);
        if ($scope.credit_card.error_message) {
          $scope.error_message = $scope.credit_card.error_message;
          bootbox.alert($scope.error_message);
          return false;
        }
        $('.loading').fadeIn();
        var callbackFn = function (credit_card) {
          if ($.trim(credit_card.token) == '' || credit_card.error_message) {
            $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
            $('.loading').fadeOut();
            bootbox.alert($scope.error_message);
            return false;
          }

          checkoutOrder();
        }
        $scope.credit_card = $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
      } else if (!$scope.stripe_stress_test && isBraintreeAndNewCard) {
        braintree.instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
          $scope.payment_method_nonce = payload.nonce;
          checkoutOrder();
        });
      } else if (!$scope.stripe_stress_test && isAuthorize_netAndNewCard) {
        
          fuseAuthorizeNetCheck(
            ({ valid, token }) => {
              if (valid) {
                $scope.payment_method_nonce = token.dataValue;
                checkoutOrder();
              }
            }
          );
          
      } else {
        checkoutOrder();
      }
    };

    /**
     * Get current step from store
     */
    $scope.redirectToCurrentStep = function () {
      HotelBeds.retrieveHotelBedsCacheAvailability($scope)
      // payment step: selected payment method and in checkout page
      if ($scope.has_flight_products) {
        $scope.steps = ['Your Party', 'Package', 'Flight Selection', 'Add-on', 'Confirm', 'Payment'];
        $scope.step_keys = {
          your_party: 0,
          package: 1,
          flight_ticket: 2,
          addon: 3,
          confirm: 4,
          payment: 5,
        };
        $scope.tracking_steps = [
          'Your Party', 
          'Package', 
          'Package RoomType Select', 
          'Flight ticket', 
          'Add-on', 
          'Confirm', 
          'Confirm Checkout', 
          'Payment', 
          'Payment Success'
        ];
      }
      if ($scope.getStorage($scope.event_store.CONFIRM_PRICING)) {
        $scope.payment_data = $scope.getStorage($scope.event_store.CONFIRM_PRICING).payment_data
      }
      totalEventAdditionalFees($scope.number_of_travellers)
      var current_step = parseInt($scope.getStorage($scope.event_store.CURRENT_STEP)) || $scope.current_step;
      if ($scope.amend_order != null) {
        return $scope.backYourParty();
      }
      var store_customer_hotels = $scope.getStorage($scope.event_store.CUSTOMER_HOTELS);
      if (store_customer_hotels) $scope.customer_hotels = store_customer_hotels;
      var store_selected_packages = $scope.getStorage($scope.event_store.SELECTED_PACKAGES);
      if (store_selected_packages) $scope.selected_packages = store_selected_packages;

      var store_customer_room_types = $scope.getStorage($scope.event_store.CUSTOMER_ROOM_TYPES);
      if (store_customer_room_types) $scope.customer_room_types = store_customer_room_types;

      if ($scope.has_ticket_products && $scope.current_step > $scope.step_keys.your_party) {
        let storage_selected_tickets = $scope.getStorage($scope.event_store.SELECTED_TICKETS);
        if (storage_selected_tickets) {
          $scope.selectedTickets = angular.copy(storage_selected_tickets);
        }
      }
      if (current_step == $scope.step_keys.package) {
        // TODO: this code might not work as expected with customer types
        // 
        // Update selected packages for page reloads. This is normally
        // set in the first step of the booking flow but with as reload
        // increaseTravelers() is omitted and selected_packages needs to
        // be updated manually.
        $scope.selected_packages[$scope.customer_select] ||= {};
        $scope.selected_packages[$scope.customer_select].quantity = $scope.number_of_travellers;
        
        return $scope.goToPackageStep();
      }

      if (current_step == $scope.step_keys.flight_ticket) {
        return $scope.goToPackageStep();
      }
 
      // re-add room_type_data
      if (current_step >= $scope.step_keys.addon) {
        $scope.addHotelToOrder(true);
        $scope.addFlightToOrder();
        if (current_step == $scope.step_keys.confirm) {
          $scope.confirm_pricings = $scope.getStorage($scope.event_store.CONFIRM_PRICING);
          if ($scope.number_of_travellers == 1) {
            $scope.selectPaymentMethod($scope.payment_methods.PAY_ALL_BY_MYSELF);
            return $scope.goToConfirmCheckout();
          }
        }
        $scope.payment_method =
          $scope.getStorage($scope.event_store.PAYMENT_METHOD) ==
          $scope.payment_methods.PAY_MY_SHARE
            ? $scope.payment_methods.PAY_MY_SHARE
            : $scope.payment_methods.PAY_ALL_BY_MYSELF;

        if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
          $scope.rooms = $scope.getStorage($scope.event_store.SPLIT_ORDER_DATA);
          if (!$scope.rooms) {
            buildSplitOrders(() => {
              $scope.changeRoomOptions();
              $scope.saveSplitOrders();
              $scope.show_payment = false;

              if (current_step == $scope.step_keys.payment) {
                $scope.getInstallments();
                $scope.payment_option =
                  $scope.getStorage($scope.event_store.PAYMENT_OPTION) ==
                  $scope.payment_options.PAY_EVERYTHING
                    ? $scope.payment_options.PAY_EVERYTHING
                    : $scope.payment_options.PAY_INSTALLMENT;
                // Payment Plan Option
                $scope.payment_plan_option = $scope.getStorage($scope.event_store.PAYMENT_PLAN_OPTION)
                if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
                  $scope.goToConfirmStep();
                } else {
                  $scope.goToConfirmCheckout();
                }
              }
            });
          } else {
            $scope.changeRoomOptions();
            $scope.saveSplitOrders();
            // Reset to confirm
            $scope.show_payment = false;
          }
        } else {
          $scope.rooms = $scope.getStorage($scope.event_store.PAY_FULL_ORDER_DATA);
          $scope.require_guest_names_on_pay_full = $scope.getStorage($scope.event_store.REQUIRE_NAMES_PAY_FULL);
          buildRoomMembers(() => {
            if (current_step == $scope.step_keys.payment) {
              $scope.getInstallments();
              $scope.payment_option =
                $scope.getStorage($scope.event_store.PAYMENT_OPTION) ==
                $scope.payment_options.PAY_EVERYTHING
                  ? $scope.payment_options.PAY_EVERYTHING
                  : $scope.payment_options.PAY_INSTALLMENT;
              // Payment Plan Option
              $scope.payment_plan_option = $scope.getStorage($scope.event_store.PAYMENT_PLAN_OPTION)
              if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
                $scope.goToConfirmStep();
              } else {
                $scope.goToConfirmCheckout();
              }
            }           
          });
        }

        // Payment
        if (current_step == $scope.step_keys.payment) {
          $scope.getInstallments();
          $scope.payment_option =
            $scope.getStorage($scope.event_store.PAYMENT_OPTION) ==
            $scope.payment_options.PAY_EVERYTHING
              ? $scope.payment_options.PAY_EVERYTHING
              : $scope.payment_options.PAY_INSTALLMENT;
          // Payment Plan Option
          $scope.payment_plan_option = $scope.getStorage($scope.event_store.PAYMENT_PLAN_OPTION)
          if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
            $scope.goToConfirmStep();
          } else {
            $scope.goToConfirmCheckout();
          }
        }
      }
    };

    /**
     * Split room
     */
    $scope.allocateNextRoom = function () {
      Amplitude.logEvent('Click Allocate Next Room Button', $scope.ampEventProps)
      $scope.allocating_room_number += 1;
      $scope.changeRoomOptions();
    };

    $scope.backToPreviousAllocate = function () {
      window.scrollTo(0, 0);
      $scope.allocating_room_number -= 1;
      $scope.changeRoomOptions();
    };

    $scope.initialSelectedPackage = function () {
      if ($scope.event_customer_types.length > 0) {
        $scope.event_customer_types.forEach(function (item) {
          if (!$scope.selected_packages[item.singular_name]) {
            $scope.selected_packages[item.singular_name] = { quantity: 0 };
          }
        })
      }
    }
    /** End */

    function recalculatePaymentAmount(total_paid = null, including_discount_first_time = false) {
      if (including_discount_first_time) {
        $scope.payment_data.grand_amount = $scope.payment_data.discountable_order_price;
      }
      var payment_amount = parseFloat($scope.payment_data.grand_amount);
      payment_amount -= parseFloat(EventDiscount(total_paid));
      payment_amount = payment_amount < 0.0 ? 0.0 : payment_amount;
      payment_amount += parseFloat(totalEventAdditionalFees(total_paid));
      // payment_amount += parseFloat(totalSelectedTicketsCost(total_paid));

      payment_amount -= $scope.payment_data.amendment_credits.applied;
      payment_amount = payment_amount < 0.0 ? 0.0 : payment_amount;
      var subtotal = payment_amount <= 0.0 ? 0.0 : payment_amount + parseFloat(EventDiscount(total_paid));
      $scope.payment_data.payment_amount = payment_amount;
      $scope.payment_data.subtotal = subtotal;
    }

    function buildSplitOrders(callback) {
      $scope.addon_count = Object.keys($scope.addon_data).length;
      $scope.flight_count = Object.keys($scope.flight_data).length;
      var addon_data = $scope.addon_data;
      var flight_data = $scope.flight_data;
      var room_number = 0;
      var member_number = 0;
      $scope.rooms = [];
      $scope.hydrateScope();

      $.each($scope.customer_hotels, function(customer_type, customer_data) {
        $.each(customer_data.room_type_data, function (room_type_id, room_data) {
          for (var q = 1; q <= room_data.quantity; q++) {
            room_number += 1;
            var members = [];
            let room_original_price = 0;
            let room_original_quantity = 0;
            Object.keys($scope.hotel_order_items).length > 0 &&
            Object.keys($scope.hotel_order_items).forEach(amend_hotel => {
              if (room_type_id == $scope.hotel_order_items[amend_hotel].room_type_id && room_data.hotel_id == $scope.hotel_order_items[amend_hotel].product_id && parseFloat($scope.hotel_order_items[amend_hotel].unit_price) > 0 ) {
                room_original_price = $scope.hotel_order_items[amend_hotel].unit_price
                room_original_quantity = $scope.hotel_order_items[amend_hotel].quantity
              }
            });
            // build split bill of roommate by room pax
            for (var i = 1; i <= room_data.rooms[q].number_of_occupants; i++) {
              var addons = [];
              var flights = [];
              member_number += 1;

              if ($scope.addon_count > 0) {
                $.each(addon_data, function (addon_id, data) {
                  if (!data.guest_stock) data.guest_stock = data.quantity;
                  addons.push({
                    addon_id: addon_id,
                    addon_name: data.name,
                    total_quantity: data.quantity,
                    quantity: 0,
                    unit_price: data.unit_price,
                    is_ticket: data.is_ticket,
                    original_price: data.hasOwnProperty('original_price') ? parseFloat(data.original_price) : 0,
                    original_quantity: data.hasOwnProperty('original_quantity') ? parseInt(data.original_quantity) : 0
                  });
                });
              }

              if ($scope.flight_count > 0 && $scope.flight_product !== undefined) {
                $.each(flight_data, function (flight_id, data) {
                  if (!data.guest_stock) data.guest_stock = data.quantity;
                  flights.push({
                    flight_id: flight_id,
                    total_quantity: data.quantity,
                    quantity: 0,
                    unit_price: data.unit_price,
                    source_airport_id: data.source_airport_id,
                    destination_airport_id: data.destination_airport_id,
                    departure_time_outbound: data.departure_time_outbound,
                    departure_time_inbound: data.departure_time_inbound,          
                    flight_number: data.flight_number,
                    ticket_class: data.ticket_class,
                    inbound_flight_number: data.inbound_flight_number,
                    inbound_ticket_class: data.inbound_ticket_class,          
                    source_airport_code: data.source_airport_code,
                    destination_airport_code: data.destination_airport_code,
                    trip_type: data.trip_type,
                    outbound_airline: data.outbound_airline,
                    inbound_airline: data.inbound_airline,
                    flight_product: $scope.flight_product.id,
                    show_terms: data.show_terms
                  });
                });
              }

              members.push({
                member_number,
                room_number: room_number,
                name: '',
                email: '',
                paid: false,
                unit_price: 0,
                down_payment: 0,
                addons: addons,
                flights: flights,
                amount: 0,
                original_price: parseFloat(room_original_price),
                original_quantity: parseInt(room_original_quantity)
              });
            }

            $scope.rooms.push({
              hotel_id: room_data.hotel_id,
              hotel_name: room_data.hotel_name,
              room_number: room_number,
              room_type_id: room_type_id,
              room_name: room_data.name,
              room_prices: room_data.pricings,
              package_id: room_data.package_id,
              event_customer_type_id: room_data.event_customer_type_id,
              room_pax: room_data.pax,
              mine: false,
              members: members,
              original_price: parseFloat(room_original_price),
              original_quantity: parseInt(room_original_quantity)
            });
          }
        });
      })

      $scope.payment_data.grand_amount = 0;
      if ($scope.pre_booking_data) {
        if (callback) callback();
      } else {
        if ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds == true) {
          if (HotelBeds.existsRoomHbOnRoomTypeData($scope.customer_hotels[$scope.customer_select].room_type_data)) {
            HotelBeds.updateHotelBedsAvailability($scope, $scope.customer_hotels, true, callback, false);
          }
        } else {
          if (callback) callback();
        }
      }
    }

    function buildRoomMembers(callback) {
      var params = new URLSearchParams(window.location.search)
      $scope.addon_count = Object.keys($scope.addon_data).length;
      $scope.flight_count = Object.keys($scope.flight_data).length;
      var guid_param = params.get('guid')
      var leader_room_type_id;
      var leader_customer_type;
      var leader_assigned = false;
      if (guid_param != null) {
        $.each($scope.pre_booking_data.customer_types, function(_, customer_type) {
          $.each(customer_type.guests, function (_, guest_data) {
            if (guest_data.is_leader == true) {
              leader_room_type_id = customer_type.rooms[0].room_type_id;
              leader_customer_type = customer_type.customer_type;
            }
          });
        });
      }

      var room_number = 0;
      var member_number = 0;
      var current_user = {
        name: 'You',
        email: 'your email',
      };

      if ($scope.current_user) {
        current_user.name  = $scope.current_user.first_name + ' ' + $scope.current_user.last_name;
        current_user.email = $scope.current_user.email;
      } else {
        $scope.signup_user.first_name = '';
        $scope.signup_user.last_name = '';
        $scope.signup_user.email = '';
      }
      if ($scope.require_guest_names_on_pay_full) {
        var saved_member_name_mail = []
        var index_member_info = 0
        $scope.rooms.forEach(room_each => {
          room_each.members.forEach(member_each => {
            saved_member_name_mail.push({ member_name: member_each.name, member_email: member_each.email })
          })
        })
      }
      $scope.rooms = [];
      $.each($scope.customer_hotels, function(customer_type, customer_data) {
        $.each(customer_data.room_type_data, function (room_type_id, room_data) {

          // TODO confirm create addon/flights on pay in full
          for (var room = 1; room <= room_data.quantity; room++) {
            room_number += 1;
            var members = [];
            let room_original_price = 0
            let room_original_quantity = 0
            Object.keys($scope.hotel_order_items).length > 0 &&
            Object.keys($scope.hotel_order_items).forEach(amend_hotel => {
              if (room_type_id == $scope.hotel_order_items[amend_hotel].room_type_id && 
              room_data.hotel_id == $scope.hotel_order_items[amend_hotel].product_id && 
              parseFloat($scope.hotel_order_items[amend_hotel].unit_price) > 0 ) {
                room_original_price = $scope.hotel_order_items[amend_hotel].unit_price
                room_original_quantity = $scope.hotel_order_items[amend_hotel].quantity
              }
            });
            for (var i = 1; i <= room_data.rooms[room].number_of_occupants; i++) {
              member_number += 1;
              var name_saved = '';
              var email_saved = '';
              if ($scope.require_guest_names_on_pay_full) {
                if (saved_member_name_mail[index_member_info]) {
                  name_saved = saved_member_name_mail[index_member_info].member_name;
                  email_saved = saved_member_name_mail[index_member_info].member_email;
                } else {
                  name_saved = '';
                  email_saved = '';
                }
                index_member_info ++;
              }
              var addons = [];
              var flights = [];

              if ($scope.addon_count > 0) {
                $.each($scope.addon_data, function (addon_id, data) {
                  if (!data.guest_stock) data.guest_stock = data.quantity;
                  addons.push({
                    addon_id: addon_id,
                    addon_name: data.name,
                    total_quantity: data.quantity,
                    quantity: 0,
                    unit_price: data.unit_price,
                    is_ticket: data.is_ticket,
                    original_price: data.hasOwnProperty('original_price') ? parseFloat(data.original_price) : 0,
                    original_quantity: data.hasOwnProperty('original_quantity') ? parseInt(data.original_quantity) : 0
                  });
                });
              }

              if ($scope.flight_count > 0 && $scope.flight_product !== undefined) {
                $.each($scope.flight_data, function (flight_id, data) {
                  if (!data.guest_stock) data.guest_stock = data.quantity;
                  flights.push({
                    flight_id: flight_id,
                    total_quantity: data.quantity,
                    quantity: 0,
                    unit_price: data.unit_price,
                    source_airport_id: data.source_airport_id,
                    destination_airport_id: data.destination_airport_id,
                    departure_time_outbound: data.departure_time_outbound,
                    departure_time_inbound: data.departure_time_inbound,          
                    flight_number: data.flight_number,
                    ticket_class: data.ticket_class,
                    inbound_flight_number: data.inbound_flight_number,
                    inbound_ticket_class: data.inbound_ticket_class,          
                    source_airport_code: data.source_airport_code,
                    destination_airport_code: data.destination_airport_code,
                    trip_type: data.trip_type,
                    outbound_airline: data.outbound_airline,
                    inbound_airline: data.inbound_airline,
                    flight_product: $scope.flight_product.id,
                    show_terms: data.show_terms
                  });
                });
              }
              if (guid_param != null) {
                members.push({
                  member_number,
                  room_type_id: room_type_id,
                  room_number: room_number,
                  name:
                    (leader_room_type_id == room_type_id &&
                      leader_assigned == false &&
                      leader_customer_type.toLowerCase() == customer_type.toLowerCase() &&
                      current_user.name) || 
                    ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds ? name_saved : `Guest #${member_number}`),
                  email:
                    (leader_room_type_id == room_type_id &&
                      leader_assigned == false &&
                      leader_customer_type.toLowerCase() == customer_type.toLowerCase() &&
                    current_user.email) ||
                    ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds ? email_saved : 'TBD'),
                  paid: true,
                  mine: true,
                  unit_price: 0,
                  down_payment: 0,
                  addons: addons,
                  flights: flights,
                  amount: 0,
                  original_price: parseFloat(room_original_price),
                  original_quantity: parseInt(room_original_quantity)
                });
                if (leader_room_type_id == room_type_id &&
                    leader_customer_type.toLowerCase() == customer_type.toLowerCase()) {
                  leader_assigned = true;
                }
              } else {
                members.push({
                  member_number,
                  room_type_id: room_type_id,
                  room_number: room_number,
                  name:
                    (!$scope.require_guest_names_on_pay_full && member_number == 1 && current_user.name) ||
                    ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds ? name_saved : `Guest #${member_number}`),
                  email: (!$scope.require_guest_names_on_pay_full && member_number == 1 && current_user.email) || ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds ? email_saved : 'TBD'),
                  paid: true,
                  mine: true,
                  unit_price: 0,
                  down_payment: 0,
                  addons: addons,
                  flights: flights,
                  amount: 0,
                  original_price: 0,
                  original_quantity: 0
                });
              }
            }
            $scope.rooms.push({
              package_id: room_data.package_id,
              package_name: room_data.package_name,
              event_customer_type_id: room_data.event_customer_type_id,
              hotel_id: room_data.hotel_id,
              hotel_name: room_data.hotel_name,
              room_number: room_number,
              room_type_id: room_type_id,
              room_name: room_data.name,
              room_pax: room_data.pax,
              mine: room_number == 1,
              members: members,
              is_room_hb: room_data.is_room_hb,
              room_prices: room_data.pricings,
              original_price: parseFloat(room_original_price),
              original_quantity: parseInt(room_original_quantity)
            });
          }
        });
      });
      $scope.payment_data.grand_amount = 0;
      // this routine update $scope.payment_data
      $scope.changeRoomOptions(is_confirm = true);
      $scope.saveRoomMembers();
      // save for confirmation
      $scope.confirm_pricings = $scope.hydrateScope();
      
      var event_additional_fees = angular.copy($scope.event_additional_fees);
      var payment_data = angular.copy($scope.payment_data);
      var rooms = angular.copy($scope.rooms);
      var addon_data = angular.copy($scope.addon_data);
      var flight_data = angular.copy($scope.flight_data);
      var event_discount_codes = angular.copy($scope.discount_code);
      $scope.confirm_pricings = {
        payment_data,
        rooms,
        addon_data,
        flight_data,
        event_additional_fees,
        event_discount_codes
      };

      if ($scope.pre_booking_data) {
        if (callback) callback();
      } else {
        if ($scope.customer_hotels[$scope.customer_select].hotel_has_hotelbeds == true) {
          if (HotelBeds.existsRoomHbOnRoomTypeData($scope.customer_hotels[$scope.customer_select].room_type_data)) {
            HotelBeds.updateHotelBedsAvailability($scope, $scope.customer_hotels, false, callback, false);
          }
        } else {
          $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
          if (callback) callback();
        }
      }
    }

    function checkoutOrder() {
      var params = new URLSearchParams(window.location.search)
      if (!$scope.gaClientId) {
        $scope.setGAClientId()
      }
      var opt = {
        event_id: $scope.event.id,
        token: $scope.braintree.active ? $scope.credit_card.fingerprint : $scope.credit_card.token,
        credit_card_id: $scope.credit_card.id,
        stripe_customer_id: $scope.credit_card.stripe_customer_id,
        preczn_single_use_token: (!$scope.credit_card.is_new_one && $scope.credit_card.preczn_multi_use_token) 
          ? null 
          : $scope.credit_card.preczn_single_use_token,
        card_name: $scope.credit_card.name,
        payment_method: $scope.payment_method,
        payment_option: $scope.payment_option,
        payment_plan_option: $scope.payment_plan_option,
        payment_data: $scope.payment_data,
        discount_code: angular.toJson($scope.discount_code),
        include_insurance: $scope.include_insurance,
        split_orders: angular.toJson($scope.split_orders),
        billing_address: $scope.billing_address,
        shipping_address: $scope.shipping_address,
        room_members: angular.toJson(roomMemberForCheckout()),
        user: ($scope.show_signin && $scope.signin_user) || $scope.signup_user,
        amend_order: $scope.amend_order,
        payment_gateway_test_token: $scope.payment_gateway_test_token,
        rooms_data: angular.toJson($scope.rooms),
        addon_data: angular.toJson($scope.addon_data),
        flight_data: angular.toJson($scope.flight_data),
        pre_booking: $scope.pre_booking_data ? true : false,
        pre_booking_guid: params.get('guid'),
        referrer_url: $scope.referrerURL,
        user_locale: window.getLocalizeLang(),
        amp_device_id: $scope.amplitude_device_id,
        client_id: $scope.gaClientId
      };
      
      if (typeof diagnostics_service != 'undefined') {
        opt = Object.assign(opt, diagnostics_service)
      }

      if ($scope.event.payment_gateway == $scope.payment_gateways.BRAINTREE) {
        opt.payment_method_nonce = $scope.payment_method_nonce
      }

      if ($scope.event.payment_gateway == $scope.payment_gateways.AUTHORIZE_NET) {
        opt.payment_method_nonce = $scope.payment_method_nonce
      }

      $.post($scope.event_booking_path + '/checkout', opt, function (rs) {
        $timeout(function () {
          if (rs.succeed) {
            $scope.setCurrentTrackedStep('Payment Success');
            // Revenue marker?
            $scope.removeStorage();
            if (rs.post_booking_redirect_url) {
              window.location.href = rs.post_booking_redirect_url;
            } else {
              location.href = '/' + $scope.locale + '/dashboard' + '?language=' + window.getLocalizeLang();
            }
          } else {
            if (!$scope.current_user) {
              if (rs.type === 'signin') {
                $scope.signin_error_msg = rs.message;
              } else if (rs.type === 'signup') {
                $scope.signup_error_msg = rs.message;
              }
            }
            if (typeof rs.message === 'string') {
              var err_message = rs.message;
            } else {
              var err_message = `${
                Object.keys(rs.message)[0][0].toUpperCase() +
                Object.keys(rs.message)[0].slice(1)
              } ${Object.values(rs.message)[0][0]}`;
            }

            Amplitude.logEvent('Payment Error', {type: rs.type, error: rs.message, eventName: $scope.event.name, eventId: $scope.event.name})

            if ($scope.event.payment_gateway == $scope.payment_gateways.BRAINTREE) {
              braintree.instance.clearSelectedPaymentMethod();
            }
            if (rs.hasOwnProperty('action') && rs.action.includes('reset_booking')) {
              $.post(
                $scope.event_booking_path + '/start_over',
                {skip_message: true},
                function (rs) {
                  $scope.removeStorage();
                  $scope.setCurrentStep($scope.step_keys.your_party);
                  window.location.href = $scope.event_booking_path;
                  Amplitude.logEvent('Restart Booking')
                }
              );
            }

            bootbox.alert(err_message);
          }
          $('.loading').fadeOut();
        });
      });
    }

    $scope.hasAdditionalFees = function (product_id, room_type_id = null) {
      if (room_type_id) {
        return (
          Object.keys($scope.hotel_additional_fees).length &&
          $scope.hotel_additional_fees.some(
            (fee) =>
              fee.product_id == product_id && fee.room_type_id == room_type_id
          )
        );
      }
      return (
        Object.keys($scope.addon_additional_fees).length &&
        $scope.addon_additional_fees.some((fee) => fee.product_id == product_id)
      );
    };

    $scope.flightHasAdditionalFees = function (flight) {
      return (
        Object.keys($scope.flight_additional_fees).length &&
        $scope.flight_additional_fees.some((fee) => fee.airport_id === flight.source_airport_id)
      );
    };    

    $scope.setCurrentStep = function (step) {
      $scope.current_step = step;
      $scope.setStorage($scope.event_store.CURRENT_STEP, step);
      if ($scope.step_pages[$scope.current_step]) $scope.setPageTrack();
    };
    
    $scope.setCurrentTrackedStep = function (step) {
      var storageTrackedStep = $scope.getStorage($scope.event_store.CURRENT_TRACKED_STEP);
      if (!storageTrackedStep) {
        $scope.fireStepTrigger('Your Party');
        $scope.setStorage($scope.event_store.CURRENT_TRACKED_STEP, 'Your Party');
      }else if ($scope.tracking_steps.indexOf(storageTrackedStep) < $scope.tracking_steps.indexOf(step)) {
        $scope.current_tracked_step = step;
        $scope.fireStepTrigger(step);
        $scope.setStorage($scope.event_store.CURRENT_TRACKED_STEP, step);
      }
    };
    // Save to storage with event slug, key, value
    $scope.setStorage = function (item_key, value) {
      var new_value = $scope.getStorage() || {};
      item_key ? new_value[item_key] = value: new_value = value;
      window.localStorage.setItem($scope.event.slug, JSON.stringify(new_value));
    };

    $scope.getStorage = function (item_key = null) {
      var result = JSON.parse(window.localStorage.getItem($scope.event.slug)) || {};
      if (!item_key) {
        return result;
      }
      return result[item_key];
    };

    $scope.removeStorage = function (item_key = null) {
      if (!item_key) {
        return window.localStorage.removeItem($scope.event.slug);
      }
      var new_values = $scope.getStorage();
      delete new_values[item_key];
      $scope.setStorage(null, new_values);
    };

    // ============= Back to ================= //
    $scope.backToFromPaymentStep = function () {
      window.scrollTo(0, 0);
      $scope.show_payment = true;
      $scope.setCurrentStep($scope.step_keys.confirm);
    };

    $scope.backToPaymentMethod = function () {
      window.scrollTo(0, 0);
      if ($scope.number_of_travellers == 1 || $scope.pre_booking_data) {
        return $scope.backToAddonStep();
      }
      $scope.setCurrentStep($scope.step_keys.confirm);
      $scope.show_payment = false;
      // $scope.can_checkout = false;
      $scope.allocating_room_number = 1;
    };

    $scope.backToPackageStep = function () {
      window.scrollTo(0, 0);
      $scope.show_room_types = false;
      $scope.show_allocate_guests = false;
      $scope.show_packages = true;
      $scope.setPageTrack();
      applyOwlCarousel({ trigger: true });
      Amplitude.logEvent('Viewed Package Step Page', $scope.ampEventProps);
    };

    // TODO: Move to hotelbeds namespace
    $scope.openModalWithHotelFacilities = function (params) {
      $.get(
        `/hotels/${params.hotel_id}/popup`,
        (response) => {
          $('#popupModalTop #response-body').html(response);
          $('#popupModalTop').modal({backdrop: 'static'});

          // To remove backdrop modal:
          // $("#popupModalTop").on('show.bs.modal', function(){
          //   setTimeout(() => {
          //     $('.modal-backdrop').remove();
          //   },500)
          // });
        }
      );

    }

    $scope.backYourParty = function () {
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid')
      if ($scope.current_step != $scope.step_keys.package && guid_param == null) return false;
      // back to previous customer type
      const selected_customer_types = Object.keys(getSelectedPackages());
      if (
        selected_customer_types.length > 0 &&
        $scope.customer_select != selected_customer_types[0]
      ) {
        const previousIndex = selected_customer_types.indexOf($scope.customer_select) - 1;
        $scope.customer_select = selected_customer_types[previousIndex];

        if(guid_param != null) {
          $scope.chooseTripDate($scope.my_trip_date, false)
        }
        applyOwlCarousel({ trigger: true });
         //save data packages include when  step back
        if($scope.customer_select) {
          const package_instance = $scope.selected_packages[$scope.customer_select].package;
          $scope.selectPackageOption(package_instance);
          return;
        }
      }
      window.scrollTo(0, 0);
      $scope.setCurrentStep($scope.step_keys.your_party);
      $scope.updateSeatingMapLoop();
      Amplitude.logEvent('Viewed Your Party Page', $scope.ampEventProps)
    };

    $scope.backToAddonStep = function () {
      window.scrollTo(0, 0);
      $scope.discount_code.forEach(discount => {
        $scope.removeDiscountCode(discount.discount_code);
      });
      var none_addon = $scope.addons.length <= 0;
      if (none_addon) {
        Amplitude.logEvent('Viewed Room Allocation Page', $scope.ampEventProps)
        return $scope.backToAllocateGuest();
      }
      Amplitude.logEvent('Viewed Addon Step Page', $scope.ampEventProps)
      $scope.setCurrentStep($scope.step_keys.addon);
    };

    $scope.backToRoomType = function () {
      window.scrollTo(0, 0);
      $scope.show_room_types = true;
      $scope.show_allocate_guests = false;
      $scope.show_packages = false;
      $.each($scope.customer_hotels, function (_, customer_hotel) {
        $.each(customer_hotel.room_type_data, function (_, room_type) {
          room_type.rooms = {};
        });
      });
    };

    $scope.backToAllocateGuest = function () {
      Amplitude.logEvent('Viewed Room Allocation Page', $scope.ampEventProps);
      if ($scope.has_flight_ticket_products && $scope.current_step != $scope.step_keys.flight_ticket) {
        return $scope.backToFlightTicketStep();
      }
      window.scrollTo(0, 0);
      $scope.setCurrentStep($scope.step_keys.package);
      if (skipRoomSelection()) {
        return $scope.backToPackageStep();
      }
      var { skip_allocate } = skipAllocateGuest();
      if (($scope.number_of_travellers === 1 || skip_allocate) && $scope.customer_room_types?.default.length === 1) {
        return $scope.backToPackageStep();
      } else if ($scope.number_of_travellers === 1 || skip_allocate) {
        $scope.show_room_types = true;
        $scope.show_allocate_guests = false;
      } else {
        return $scope.backToPackageStep();
      }
    };

    $scope.toggleCopyBillingAddress = function () {
      $scope.copy_billing_address = !$scope.copy_billing_address;
      $scope.shippingStates = $scope.billingStates;
      document.querySelector('#billing_address_phone_number').addEventListener('countrychange', function() {
        if ($scope.copy_billing_address) {
          var selectedCountry = $scope.getBillingSelectedCountry();
          $scope.setShippingCountryFlag(selectedCountry.iso2);
        }
      });
      if ($scope.copy_billing_address) {
        var selectedFlag = $scope.getBillingSelectedCountry();
        $scope.setShippingCountryFlag(selectedFlag.iso2);
      }
      // reset these fields
      if (!$scope.copy_billing_address) {
        $scope.shipping_address = {};
      }
    };

    $scope.getBillingStates = function (country_id) {
      $.post($scope.event_booking_path + '/get_states', { country_id: country_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            if ($scope.copy_billing_address) {
              $scope.shippingStates = rs.states;
            }
            $scope.billingStates = rs.states;
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getShippingStates = function (country_id) {
      $.post($scope.event_booking_path + '/get_states', { country_id: country_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.shippingStates = rs.states;
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getStateAirports = function (state_id, selected_airport = null, reload = false) {
      $scope.airports = {}
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      $.post($scope.event_booking_path + '/get_airports', { state_id: state_id, package_id: package_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA) || $scope.flight_ticket;
            $scope.airports = rs.airports;
            $scope.flight_ticket.state_id = state_id;
            selected_airport ? $scope.selected_airport = selected_airport: '';
            if (!reload) {
              $scope.flight_ticket.selected_airport = '';
              $scope.flights = [];
              $scope.flight_ticket.departure_date = '';
              $scope.flight_ticket.return_date = '';
              $scope.available_return_date = [];
              $scope.available_departure_date = [];
              $scope.flight_datepicker.clearSelection();
              $scope.flight_datepicker.setLockDays([]);
            }
            $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket);
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getAvailableFlightsDepartureDates = function (reload = false) {
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      $.post($scope.event_booking_path + '/get_airport_available_flights_departure_dates', { airport_id: $scope.selected_airport, package_id: package_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA) || $scope.flight_ticket;
            $scope.available_departure_date = rs.departure_dates;
            $scope.flight_additional_fees = rs.flight_additional_fees;
            $scope.flight_ticket.selected_airport = $scope.selected_airport;
            if (!reload) {
              $scope.flights = [];
              $scope.flight_ticket.departure_date = '';
              $scope.flight_ticket.return_date = '';
              $scope.flight_datepicker.clearSelection();
              $scope.flight_datepicker.setLockDays([]);
            }
            $scope.available_departure_date.sort(function(a,b){
              let ra = a.split("-")
              let fa = new Date(ra[2], ra[1] - 1, ra[0])
              let rb = b.split("-")
              let fb = new Date(rb[2], rb[1] - 1, rb[0])
              let da = fa.getTime();
              let db = fb.getTime();
              
              return da < db ? -1 : da > db ? 1 : 0
            });
            let rawFirstDepartureDate = $scope.available_departure_date[0].split("-");
            $scope.firstDepartureDate = new Date(rawFirstDepartureDate[2], rawFirstDepartureDate[1] - 1, rawFirstDepartureDate[0]);
            let rawlasttDepartureDate = $scope.available_departure_date[$scope.available_departure_date.length - 1].split("-");
            $scope.lastDepartureDate = new Date(rawlasttDepartureDate[2], rawlasttDepartureDate[1] - 1, rawlasttDepartureDate[0]);
            let flight_check = $scope.getStorage($scope.event_store.FLIGHT_DATA)
            // if ((reload && !flight_check.departure_date && !flight_check.return_date) || !reload) {
              $scope.flight_datepicker.setOptions({
                minDate: $scope.firstDepartureDate,
                maxDate: $scope.lastDepartureDate,
                startDate: $scope.firstDepartureDate,
                endDate: $scope.lastDepartureDate
              });
              $scope.flight_datepicker.clearSelection();
            // }
            $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket);
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getAvailableFlightsReturnDates = function (departure_date) {
      $scope.available_return_date = [];
      $scope.flight_datepicker.setLockDays([]);
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      $.post($scope.event_booking_path + '/get_airport_available_flights_return_dates', { airport_id: $scope.selected_airport, departure_date: departure_date, package_id: package_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.available_return_date = rs.return_dates;
            $scope.flight_datepicker.setLockDays([]);
            $scope.available_return_date.sort(function(a,b){
              let ra = a.split("-")
              let fa = new Date(ra[2], ra[1] - 1, ra[0])
              let rb = b.split("-")
              let fb = new Date(rb[2], rb[1] - 1, rb[0])
              let da = fa.getTime();
              let db = fb.getTime();
              
              return da < db ? -1 : da > db ? 1 : 0
            });
            let rawFirstReturnDate = $scope.available_return_date[0].split("-");
            $scope.firstReturnDate = new Date(rawFirstReturnDate[2], rawFirstReturnDate[1] - 1, rawFirstReturnDate[0]);
            let rawlasttReturnDate = $scope.available_return_date[$scope.available_return_date.length - 1].split("-");
            $scope.lastReturnDate = new Date(rawlasttReturnDate[2], rawlasttReturnDate[1] - 1, rawlasttReturnDate[0]);
            $scope.flight_datepicker.setOptions({
              minDate: $scope.firstReturnDate,
              maxDate: $scope.lastReturnDate,
            });
            $scope.flight_datepicker.gotoDate($scope.firstReturnDate);
            $('#flight_date_departure').val(departure_date);
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getAvailableFlights = function (departure_date, return_date, change_calendar = false) {
      if ($scope.pre_booking_data) {
        // get package_id of first customer type
        if (!Array.isArray(Object.values($scope.pre_booking_customer_hotel)[0].package_id)) {
          package_id = Object.values($scope.pre_booking_customer_hotel)[0].package_id;
        }else{
          $.each(getSelectedPackages(), function(customer_type, item) {
            package_id = item.package.package_id
          });
        }
      } else {
        package_id = $scope.customer_hotels.default && $scope.customer_hotels.default.package_id
      }
      if (!package_id) return
      $.post($scope.event_booking_path + '/get_airport_available_flights', { flight_product: $scope.flight_product_specific.id, airport_id: $scope.selected_airport, departure_date: departure_date, return_date: return_date, package_id: package_id }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.flight_ticket = $scope.getStorage($scope.event_store.FLIGHT_DATA) || $scope.flight_ticket;
            $scope.flight_data = $scope.flight_ticket.flight_data;
            $scope.flights = rs.flights;
            $scope.source_airports = rs.source_airports;
            $scope.destination_airports = rs.destination_airports;
            $scope.flight_ticket.departure_date = departure_date;
            $scope.flight_ticket.return_date = return_date;
            if (!change_calendar) {
              $scope.flight_datepicker.setOptions({
                minDate: $scope.firstDepartureDate,
                maxDate: $scope.lastDepartureDate,
              });
            } else {
              $scope.flight_datepicker.setOptions({
                minDate: $scope.firstDepartureDate,
                maxDate: $scope.lastDepartureDate,
                startDate: $scope.firstDepartureDate,
                endDate: $scope.lastDepartureDate,
              });
              $scope.flight_datepicker.clearSelection();
              $scope.flight_datepicker.setDateRange(departure_date, return_date, true);
              if ($scope.amend_order == null) {
                rebuildConfirmStep();
              }
            }
            $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket);
            $scope.flightTicketsTotalQuantity();
            buildProductAdditionalFees();
            $scope.calculateGrandAmount(true);
          } else {
            console.error(rs.message);
          }
        });
      });
    }

    $scope.getHoursAndMinutes = function (datetime) {
      var date = new Date(datetime);
      UTCHours = String(date.getUTCHours()).padStart(2, '0');
      UTCMinutes = String(date.getUTCMinutes()).padStart(2, '0');
      return UTCHours + ':' + UTCMinutes;
    }

    $scope.getDate = function (datetime, long = true) {
      var date = new Date(datetime);
      UTCDay = date.getUTCDate();
      UTCMonth = date.getUTCMonth() + 1;
      UTCYear = date.getUTCFullYear();
      // UTCDay = date.getDate();
      // UTCMonth = date.getMonth() + 1;
      // UTCYear = date.getFullYear();

      if (long) {
        return (new Date(UTCYear+'-'+UTCMonth+'-'+UTCDay)).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", timeZone: 'UTC'}) 
      }else{
        return (new Date(UTCYear+'-'+UTCMonth+'-'+UTCDay)).toLocaleDateString('fr-CA', { year:"numeric", month:"numeric", day:"numeric"}) 
      }
    }

    $scope.toHoursAndMinutes = function (totalMinutes) {
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);
      return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}`;
    }

    $scope.findAirportCode = function (airports, flight, src_dest) {
      if (airports.length > 0) {
        if (src_dest) {
          return airports.find(airport => airport.id == flight.source_airport_id).code
        }else{
          return airports.find(airport => airport.id == flight.destination_airport_id).code
        }
      }
    }

    // reload page if url contain guid difference storage guid
    $scope.verifyGuidToken = function () {
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid')
      if (!guid_param) return;

      var guid_stored = $scope.getStorage($scope.event_store.GUID);
      if (guid_param != guid_stored) {
        bootbox.confirm(
          'Your session is timeout, please try again!!!',
          function (rs) {
            if (rs) {
              return location.reload();
            }
          }
        );
      }
    }

    // ============= UI functionality =================
    $scope.showingPackageInfo = function () {
      return (
        $scope.current_step == $scope.step_keys.package &&
        !$scope.show_room_types &&
        !$scope.show_allocate_guests
      );
    };

    $scope.setEventOrderCustomerType = function () {
      $scope.event_customer_types.forEach((event_customer_type) => {
        event_customer_type.quantity = 0;
        $scope.order_customer_types.forEach((order_customer_type) => {
          if (
            event_customer_type.id == order_customer_type.event_customer_type_id
          ) {
            event_customer_type.quantity = order_customer_type.quantity;
          }
        });
      });
    };

    // ============= functionality =================
    // Additional fees for event
    function totalEventAdditionalFees(total_paid = null) {
      var event_total_additional_fee = 0.0;
      var event_subtotal_additional_fee = 0.0;
      const { PER_SUBTOTAL } = $scope.event_additional_fee_apply_type;
      $scope.event_additional_fees.forEach((fee) => {
        if (fee.apply_type != PER_SUBTOTAL) {
          if ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF) {
            total_paid = $scope.number_of_travellers;
          }
          fee.amount = totalEventFee(fee, total_paid);
          if (fee.include_in_bill) {
            event_total_additional_fee += fee.amount;
          }
        }
      });
      $scope.event_additional_fees.forEach((fee) => {
        if (fee.apply_type == PER_SUBTOTAL) {
          fee.amount = calculateFeePerSubtotal(fee.fee_value, total_paid, event_total_additional_fee);
          if (fee.include_in_bill) {
            event_subtotal_additional_fee += fee.amount;
          }
        }
      });
      event_total_additional_fee = event_total_additional_fee + event_subtotal_additional_fee;
      $scope.payment_data.total_event_fee = event_total_additional_fee;
      return event_total_additional_fee;
    }

    function totalEventFee(fee, total_paid) {
      const { PER_ORDER, PER_PERSON, PER_CHECKOUT } =
        $scope.event_additional_fee_apply_type;
      var total_fee_amount =
        (fee.apply_type == PER_ORDER &&
          calculateFeePerOrder(fee, total_paid)) ||
        (fee.apply_type == PER_PERSON &&
          calculateFeePerPerson(fee, total_paid)) ||
        (fee.apply_type == PER_CHECKOUT && calculateFeePerCheckout(fee)) ||
        0.0;
      return total_fee_amount;
    }

    function calculateFeePerCheckout(fee) {
      var checkoutPercent =
        parseFloat($scope.payment_data.grand_amount) /
        parseFloat($scope.payment_data.order_price);
      var amount =
        (!fee.fee_type && parseFloat(fee.fee_value)) ||
        feeBaseOnPercent(parseFloat(fee.fee_value));
      return amount * checkoutPercent;
    }

    function calculateFeePerPerson(fee, total_paid) {
      var amount =
        (!fee.fee_type && parseFloat(fee.fee_value)) ||
        feeBaseOnPercent(parseFloat(fee.fee_value));

      return amount * total_paid;
    }

    function calculateFeePerOrder(fee, total_paid) {
      var amount =
        (!fee.fee_type && parseFloat(fee.fee_value)) ||
        feeBaseOnPercent(parseFloat(fee.fee_value));
      if ($scope.number_of_travellers == total_paid) {
        return amount;
      } else {
        var amount_per_person =
          ((amount / $scope.number_of_travellers) * 100) / 100;
        return amount_per_person * total_paid;
      }
    }

    function calculateFeePerSubtotal(fee_value, total_paid, event_total_additional_fee) {
      fees = event_total_additional_fee * $scope.number_of_travellers / total_paid;

      var subtotal = parseFloat($scope.payment_data.order_price) + fees;

      if ($scope.number_of_travellers == total_paid) {
        return (subtotal * fee_value) / 100;
      } else {
        return ((subtotal * fee_value / 100) * total_paid) / $scope.number_of_travellers
      }
    }

    function feeBaseOnPercent(fee_value) {
      var order_price = parseFloat($scope.payment_data.order_price);
      return (order_price * fee_value) / 100;
    }
    
    // Discount codes for event
    $scope.addDiscountCode = function (code = null) {
      var code_query = code !== null ? code : $scope.discount.code;
      if (code_query) {
      $.post($scope.event_booking_path + '/event_discount_code', { discount_code: code_query, order_price: $scope.payment_data.order_price }, function (rs) {
        $timeout(function () {
          if (rs.success) {
            $scope.confirm_pricings = $scope.getStorage($scope.event_store.CONFIRM_PRICING);
            var discount_new = true;
            if ($scope.confirm_pricings && $scope.confirm_pricings.hasOwnProperty('event_discount_codes')) {
              $scope.discount_code = $scope.confirm_pricings.event_discount_codes;
            }
            $scope.discount_code.forEach(discount => {
              return (discount['id'] == rs.discount['id'] ) ? discount_new = false: '';
            });
            if (discount_new) {
              $scope.ampEventProps.discount_code = $scope.discount_code
              Amplitude.logEvent('Used Discount Code', $scope.ampEventProps)
              $scope.discount_code = [rs.discount];
              $scope.confirm_pricings.event_discount_codes = $scope.discount_code;
              $scope.payment_data = $scope.confirm_pricings.payment_data;
              $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
              $scope.calculateGrandAmount(true);
              recalculatePaymentAmount($scope.number_of_travellers, true);
              $scope.payment_method = $scope.confirm_pricings.payment_data.payment_amount <= 0.0 ? $scope.payment_method = $scope.payment_methods.PAY_ALL_BY_MYSELF : $scope.payment_method;
              $scope.getInstallments();
              
              $scope.confirm_pricings = $scope.confirm_pricings_transition;
              $scope.discount_code = $scope.confirm_pricings_transition.event_discount_codes;
              $scope.confirm_pricings.payment_data.total_event_discount = $scope.payment_data.total_event_discount;
              $scope.confirm_pricings.payment_data.payment_amount = $scope.payment_data.payment_amount;
              $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
              $scope.toggleDiscountCode();

              $scope.discount = {code: ''};
            }else {
              code === null ? bootbox.alert('Discount code already exist'): '';
            }
          } else {
            code === null ? bootbox.alert(rs.message): '';
          }
        });
      });
    }
    };

    $scope.roomTypesAvailable = function (package_instance) {
      var list_room_name = []
      package_instance.room_types.forEach((room) => {
        if(room.stock > 0) {
          list_room_name.push(room.name)
        }
      })
      return list_room_name.join(', ');
    };

    // Discount codes for event
    $scope.removeDiscountCode = function (discount_code) {
      $scope.confirm_pricings = $scope.getStorage($scope.event_store.CONFIRM_PRICING);
      if ($scope.confirm_pricings && $scope.confirm_pricings.hasOwnProperty('event_discount_codes')) {
        $scope.discount_code = $scope.confirm_pricings.event_discount_codes;
      }
      var results = $scope.discount_code.filter(discounts => {
        return discounts.discount_code != discount_code;
      });
      $scope.confirm_pricings.event_discount_codes = results;
      $scope.payment_data = $scope.confirm_pricings.payment_data;
      $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
      $scope.calculateGrandAmount(true);
      recalculatePaymentAmount($scope.number_of_travellers);
      $scope.getInstallments();

      $scope.confirm_pricings = $scope.confirm_pricings_transition;
      $scope.discount_code = $scope.confirm_pricings_transition.event_discount_codes;
      $scope.confirm_pricings.payment_data.total_event_discount = $scope.payment_data.total_event_discount;
      $scope.confirm_pricings.payment_data.payment_amount = $scope.payment_data.payment_amount;
      $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
    };
    
    function EventDiscount(total_paid = null) {
      const { PER_ORDER, PER_CHECKOUT, PER_PERSON } =
        $scope.event_discount_code_apply_type;
      var event_total_discount = 0.0;
      $scope.confirm_pricings_transition = $scope.getStorage($scope.event_store.CONFIRM_PRICING);
      if ($scope.confirm_pricings_transition && $scope.confirm_pricings_transition.hasOwnProperty('event_discount_codes')) {
        $scope.confirm_pricings_transition.event_discount_codes.forEach(discount => {
          if (discount.order_min_amount && discount.order_min_amount > $scope.payment_data.discountable_order_price) {
            return $scope.removeDiscountCode(discount.discount_code);
          }
          if ($scope.payment_method == $scope.payment_methods.PAY_ALL_BY_MYSELF) {
            total_paid = $scope.number_of_travellers;
          }
          var total_discount_amount =
          (discount.apply_type == PER_ORDER &&
            calculateDiscountPerOrder(discount, total_paid)) ||
          (discount.apply_type == PER_CHECKOUT && calculateDiscountPerCheckout(discount)) ||
          (discount.apply_type == PER_PERSON && calculateDiscountPerPerson(discount, total_paid)) ||
          0.0;
          discount.amount = total_discount_amount;
          event_total_discount += discount.amount;
        });
        event_total_discount = event_total_discount > $scope.payment_data.discountable_order_price ? $scope.payment_data.discountable_order_price : event_total_discount;
        $scope.payment_data.total_event_discount = event_total_discount;
      }
      return event_total_discount;
    }

    function calculateDiscountPerOrder(discount, total_paid) {
      var amount =
        (discount.discount_type != 0 && parseFloat(discount.discount_value)) ||
        discountBaseOnPercent(parseFloat(discount.discount_value));

      if ($scope.number_of_travellers == total_paid) {
        amount = discount.discount_max_amount ?
        (parseFloat(discount.discount_max_amount) > amount ?
        amount : 
        parseFloat(discount.discount_max_amount)) :
        amount;
      } else {
        amount = discount.discount_max_amount ?
        (parseFloat(discount.discount_max_amount) > amount ?
        (amount / $scope.number_of_travellers) * total_paid :
        (parseFloat(discount.discount_max_amount)  / $scope.number_of_travellers) * total_paid) :
        (amount / $scope.number_of_travellers) * total_paid;
      }
      return amount;
    }

    function calculateDiscountPerCheckout(discount) {
      var checkoutPercent =
        parseFloat($scope.payment_data.discountable_grand_amount) /
        parseFloat($scope.payment_data.discountable_order_price);
      var amount =
        (discount.discount_type != 0 && parseFloat(discount.discount_value)) ||
        discountBaseOnPercent(parseFloat(discount.discount_value));
      return amount * checkoutPercent;
    }

    function calculateDiscountPerPerson(discount, total_paid) {
      var amount =
        (discount.discount_type != 0 && parseFloat(discount.discount_value)) ||
        discountBaseOnPercent(parseFloat(discount.discount_value));
      return amount * total_paid;
    }
    
    function discountBaseOnPercent(discount_value) {
      var order_price = parseFloat($scope.payment_data.discountable_order_price);
      return (order_price * discount_value) / 100;
    }

    function buildProductAdditionalFees() {
      // build hotel additional fees
      $.each($scope.rooms, function (id, room) {
        var total_additional_fee = 0;
        if (room.additional_fees) {
          room.additional_fees.forEach((hotel_fee) => {
            if (hotel_fee.include_in_bill) {
              total_additional_fee += parseFloat(hotel_fee.amount);
            }
          });
        }
        if (room.original_price == 0 || room.original_quantity == 0) {
          room.amount = room.unit_price + total_additional_fee;
        }else{
          room.amount = room.original_price + total_additional_fee;
        }
      });

      // build addon additional fees
      if (Object.keys($scope.addon_additional_fees).length) {
        $.each($scope.addon_data, function (addon_id, addon) {
          var total_addon_fee = 0;
          addon.additional_fees = [];
          $scope.addon_additional_fees.forEach(function (addon_fee) {
            var addon_fee_amount = 0;
            var original_addon_fee_amount = 0;
            if (addon_id == addon_fee.product_id) {
              if (addon_fee.fee_type) {
                if (addon.original_price == 0 || addon.original_quantity == 0) {
                  addon_fee_amount +=
                    (parseFloat(addon.unit_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                }else {
                  addon_fee_amount +=
                    (parseFloat(addon.unit_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                  original_addon_fee_amount +=
                    (parseFloat(addon.original_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                }
              } else {
                addon_fee_amount = parseFloat(addon_fee.fee_value);
                original_addon_fee_amount = parseFloat(addon_fee.fee_value);
              }
              if (addon_fee_amount > 0) {
                addon.additional_fees.push({
                  ...addon_fee,
                  amount: addon.original_quantity * original_addon_fee_amount + (addon.quantity - addon.original_quantity) * addon_fee_amount,
                });
              }
              if (addon_fee.include_in_bill) {
                total_addon_fee += addon.original_quantity * original_addon_fee_amount + (addon.quantity - addon.original_quantity) * addon_fee_amount;
              }
            }
          });
          addon.amount =
            parseFloat(addon.original_price * addon.original_quantity + (addon.unit_price * (addon.quantity - addon.original_quantity))) + total_addon_fee;
        });
      }else{
        $.each($scope.addon_data, function (addon_id, addon) {
          addon.amount = parseFloat(addon.original_price * addon.original_quantity + (addon.unit_price * (addon.quantity - addon.original_quantity)));
        });
      }

      // build flight additional fees
      if (Object.keys($scope.flight_additional_fees).length) {
        $.each($scope.flight_data, function (flight_id, flight) {
          var total_flight_fee = 0;
          flight.additional_fees = [];
          $scope.flight_additional_fees.forEach(function (flight_fee) {
            var flight_fee_amount = 0;
            if (flight.source_airport_id == flight_fee.airport_id) {
              if (flight_fee.fee_type == 'percent') {
                flight_fee_amount =
                  (parseFloat(flight.unit_price) *
                    parseFloat(flight_fee.fee_value)) /
                  100;
              } else {
                flight_fee_amount = parseFloat(flight_fee.fee_value);
              }
              if (flight_fee_amount > 0) {
                flight.additional_fees.push({
                  ...flight_fee,
                  amount: flight.quantity * flight_fee_amount,
                });
              }
              if (flight_fee.include_in_bill) {
                total_flight_fee += flight.quantity * flight_fee_amount;
              }
            }
          });
          flight.amount =
            parseFloat(flight.unit_price * flight.quantity) + total_flight_fee;
        });
      }else{
        $.each($scope.flight_data, function (flight_id, flight) {
          flight.amount = parseFloat(flight.unit_price * flight.quantity);
        });
      }
    }

    function buildMyProductAdditionalFees() {
      $.each($scope.my_products.rooms, function (id, room) {
        var total_additional_fee = 0;
        if (room.additional_fees) {
          room.additional_fees.forEach((hotel_fee) => {
            if (hotel_fee.include_in_bill) {
              total_additional_fee += parseFloat(hotel_fee.amount);
            }
          });
        }
        if (room.original_price == 0 && room.original_quantity == 0) {
          room.amount = room.unit_price + total_additional_fee;
        }else{
          room.amount = room.original_price + total_additional_fee
        }
      });

      if (Object.keys($scope.addon_additional_fees).length) {
        $.each($scope.my_products.addons, function (addon_id, addon) {
          var total_addon_fee = 0;
          addon.additional_fees = [];
          $scope.addon_additional_fees.forEach(function (addon_fee) {
            if (addon_id == addon_fee.product_id) {
              var fee_amount = 0;
              var original_fee_amount = 0;
              if (addon_fee.fee_type) {
                if (addon.original_price == 0 || addon.original_quantity == 0) {
                  fee_amount +=
                    (parseFloat(addon.unit_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                }else {
                  fee_amount +=
                    (parseFloat(addon.unit_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                  original_fee_amount +=
                    (parseFloat(addon.original_price) * 
                    parseFloat(addon_fee.fee_value)) /
                    100;
                }
              } else {
                fee_amount = parseFloat(addon_fee.fee_value);
                original_fee_amount = parseFloat(addon_fee.fee_value);
              }
              if (fee_amount > 0) {
                addon.additional_fees.push({
                  ...addon_fee,
                  amount: addon.quantity * fee_amount,
                });
              }
              if (addon_fee.include_in_bill) {
                total_addon_fee += addon.quantity * fee_amount;
              }
            }
          });
          addon.amount =
            parseFloat(addon.original_price * addon.original_quantity + (addon.unit_price * (addon.quantity - addon.original_quantity))) + total_addon_fee;
        });
      }else{
        $.each($scope.my_products.addons, function (addon_id, addon) {
          addon.amount = parseFloat(addon.original_price * addon.original_quantity + (addon.unit_price * (addon.quantity - addon.original_quantity)));
        });
      }

      if (Object.keys($scope.flight_additional_fees).length) {
        $.each($scope.my_products.flights, function (flight_id, flight) {
          var total_flight_fee = 0;
          flight.additional_fees = [];
          $scope.flight_additional_fees.forEach(function (flight_fee) {
            if (flight.source_airport_id == flight_fee.airport_id) {
              var fee_amount = 0;
              if (flight_fee.fee_type == 'percent') {
                fee_amount =
                  (parseFloat(flight.unit_price) *
                    parseFloat(flight_fee.fee_value)) /
                  100;
              } else {
                fee_amount = parseFloat(flight_fee.fee_value);
              }
              if (fee_amount > 0) {
                flight.additional_fees.push({
                  ...flight_fee,
                  amount: flight.quantity * fee_amount,
                });
              }
              if (flight_fee.include_in_bill) {
                total_flight_fee += flight.quantity * fee_amount;
              }
            }
          });
          flight.amount =
            parseFloat(flight.unit_price * flight.quantity) + total_flight_fee;
        });
      }else{
        $.each($scope.my_products.flights, function (flight_id, flight) {
          flight.amount = parseFloat(flight.unit_price * flight.quantity);
        });
      }
    }

    function roomMemberForCheckout() {
      var room_members = [];

      $.each($scope.rooms, function (i, room) {
        var valid_members = $.grep(room.members, function (x, i) {
          return x.name != '' && x.email != '';
        });

        var new_room = {
          product_id: room.hotel_id,
          room_number: room.room_number,
          room_type_id: room.room_type_id,
          package_id: room.package_id,
          event_customer_type_id: room.event_customer_type_id,
          members: [],
        };

        $.each(valid_members, function (j, member) {
          new_room.members.push({
            name: member.name,
            email: member.email,
            amount: member.amount,
            is_room_hotel_beds: member.is_room_hotel_beds,
            hotel_beds_unit_price: member.unit_price,
            down_payment: room
              .room_prices
              .find(pricing => pricing.number_of_occupants == valid_members.length )
              .down_payment
          });
        });
        room_members.push(new_room);
      });

      return room_members;
    }

    function sortBy(arr, k) {
      return arr
        .concat()
        .sort((a, b) => (a[k] > b[k] ? 1 : a[k] < b[k] ? -1 : 0));
    }

    function getPageUrl() {
      if (
        $scope.current_step == $scope.step_keys.package &&
        $scope.show_room_types
      ) {
        return `/booking/${$scope.event.slug}/room-types`;
      }
      return `/booking/${$scope.event.slug}/${
        $scope.step_pages[$scope.current_step].url
      }`;
    }

    function getPageTitle() {
      if (
        $scope.current_step == $scope.step_keys.package &&
        $scope.show_room_types
      ) {
        return `${$scope.event.name} | Room-Type`;
      }
      return `${$scope.event.name} | ${
        $scope.step_pages[$scope.current_step].title
      }`;
    }

    function applyOwlCarousel({ auto_select = false, trigger = false }) {
      $('.packageOwlCarousel').owlCarousel('destroy');

      $(document).ready(function () {
        if ($(window).width() < 768) {
          var owl = $('.packageOwlCarousel').owlCarousel(OWL_CAROUSEL_OPTIONS);
          owl.on('drag.owl.carousel', function (event) {
            if (event.item.index != null) {
              $scope.owl_carousel_item_started_drag = event.item.index
            }
          });
          owl.on('changed.owl.carousel', function (event) {
            $timeout(function () {
              if (event.item.index) {
                $scope.owl_carousel_item_selected = event.item.index;
              }
              selectOwlCarOuselItem(event.item.index, $scope.owl_carousel_item_started_drag);
            });
          });
          if (auto_select) {
            // Auto select first item if requested
            $timeout(function () {
              selectOwlCarOuselItem(0);
            });
          } else if (trigger) {
            // select previous item
            $timeout(function () {
              owl.trigger('to.owl.carousel', $scope.owl_carousel_item_selected);
            });
          }
        } else {
          if (auto_select) {
            // Auto select first item if requested
            $timeout(function () {
              selectOwlCarOuselItem(0);
            });
          } 
          stopCarousel();
        }
      });
    }

    function selectOwlCarOuselItem(itemIndex = null, lastItemIndex = 0) {
      if (itemIndex === null || !$scope.customer_select) return;
      const customer_select = $scope.customer_packages[$scope.customer_select];
      if (customer_select != null) {
        customer_select.forEach((package_instance, index) => {
          if (
            index == itemIndex &&
            package_instance.package_stock >= $scope.number_of_travellers
          ) {
            if (customer_select[lastItemIndex].package_id == customer_select[itemIndex].package_id) {
              return $scope.selectPackage(
                $scope.customer_select,
                package_instance,
                true,
                true
              );
            }else{
              return $scope.selectPackage(
                $scope.customer_select,
                package_instance,
                true,
                false
              );
            }
          }
        });
      }
    }

    function stopCarousel() {
      var owl = $('.packageOwlCarousel');
      owl.trigger('destroy.owl.carousel');
      owl.addClass('off');
    }

    function getSelectedPackages() {
      const selected_packages = {};
      $.each($scope.selected_packages, function (customer_type, selected) {
        if (selected.quantity > 0) selected_packages[customer_type] = $scope.selected_packages[customer_type];
      });
      $scope.setStorage($scope.event_store.SELECTED_PACKAGES, selected_packages);
      return selected_packages;
    }

    function skipRoomSelection() {
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid')
      if(guid_param != null) {
        return true
      }
      const selected_packages = getSelectedPackages();
      return Object.values(selected_packages).every((item) => {
        return (
          item.package.room_types.length === 1 &&
          item.package.room_types[0].pax === 1
        );
      });
    }

    function selectFirstCustomerTypes(first_customer_type = false) {
      if (first_customer_type && Object.keys($scope.selected_packages).length > 0) {
        $scope.customer_select = Object.keys($scope.customer_packages).find(
          (customer_type) => $scope.selected_packages[customer_type].quantity > 0
        );
        $scope.customer_select ||= 'default'
      }
      if (!$scope.customer_select) return;

      const customer_package = $scope.customer_packages[$scope.customer_select];
      if ($scope.pre_booking_has_order &&
        $scope.pre_booking_customer_hotel[$scope.customer_select] != undefined) {
          const pre_booking_hotel_ids = $scope.pre_booking_customer_hotel[$scope.customer_select].hotel_id;
          let package_selected = {}
          if (!Array.isArray(pre_booking_hotel_ids)) {
            package_selected = customer_package.find(
              (package_instance) =>
              package_instance.hotel_id == pre_booking_hotel_ids
            )
          }else{
            package_selected = customer_package.find(
              (package_instance) =>
               pre_booking_hotel_ids.includes(package_instance.hotel_id)
            )
          }
        if (package_selected) 
          $scope.selectPackage($scope.customer_select, package_selected);
      } else {
        if ($scope.selected_packages[$scope.customer_select]) {
          const first_package_selected = customer_package.find(
            (package_instance) =>
              package_instance.package_stock >=
              $scope.selected_packages[$scope.customer_select].quantity
          );
          if (first_package_selected)
            $scope.selectPackage($scope.customer_select, first_package_selected);
        }
      }
    }

    function hasMemberEachRoom() {
      return Object.values($scope.customer_hotels.default.room_type_data).every(
        (room_type) => {
          var room_types = Object.values(room_type['rooms']);
          return (
            room_types.length &&
            room_types.every((item) => item.number_of_occupants > 0)
          );
        }
      );
    }

    function skipAllocateGuest() {
      var max_of_pax = Object.values($scope.customer_hotels.default.room_type_data).reduce((total, item) => total + item.pax * item.quantity, 0);
      var is_full_capacity = max_of_pax === $scope.number_of_travellers;
      var room_type_data = Object.values($scope.customer_hotels.default.room_type_data);
      var only_one_room  = room_type_data.length == 1 && room_type_data[0].quantity == 1;
      var total_rooms    = room_type_data.reduce((sum, item) => sum + item.quantity, 0);
      var rooms_equal_travellers = total_rooms == $scope.number_of_travellers;
      var skip_allocate = only_one_room || is_full_capacity || rooms_equal_travellers;
      $scope.skip_allocate = skip_allocate;
      return {
        skip_allocate,
        number_of_occupants: rooms_equal_travellers && 1 || only_one_room && $scope.number_of_travellers || 0,
        is_full_capacity,
      };
    }

    function rebuildConfirmStep() {
      buildRoomMembers(() => {
        if ($scope.payment_method == $scope.payment_methods.PAY_MY_SHARE) {
          buildSplitOrders(() => {
            $scope.changeRoomOptions(is_confirm = true);
            if ($scope.number_of_travellers == 1 || $scope.pre_booking_data) {
              $scope.selectPaymentMethod($scope.payment_methods.PAY_ALL_BY_MYSELF);
              $scope.fetchQueryStrings();
              return $scope.goToConfirmCheckout();
            } else {
              $scope.show_payment = false;
              $scope.fetchQueryStrings();
            }
            $scope.getInstallments();
          });
        }
        if ($scope.number_of_travellers == 1 || $scope.pre_booking_data) {
          $scope.selectPaymentMethod($scope.payment_methods.PAY_ALL_BY_MYSELF);
          $scope.fetchQueryStrings();
          return $scope.goToConfirmCheckout();
        } else {
          $scope.show_payment = false;
          $scope.fetchQueryStrings();
        }
        $scope.getInstallments();
      });
    }

    function buildCustomerHotelParams() {
      const result = {};
      if ($scope.customer_hotels.length <= 0) {
        $scope.customer_hotels = $scope.getStorage($scope.event_store.CUSTOMER_HOTELS, $scope.customer_hotels);
      }
      $.each($scope.customer_hotels, function (customer_type, customer_hotel) {
        result[customer_type] = {
          package_id: customer_hotel.package_id,
          hotel_id: customer_hotel.hotel_id,
          room_type_data: buildRoomTypeParams(customer_hotel.room_type_data, customer_hotel.package_id),
        };
      });
      return result;
    }

    function buildRoomTypeParams(room_type_data, package_id) {
      const room_type = {};
      var params = new URLSearchParams(window.location.search)
      var guid_param = params.get('guid')
      $.each(room_type_data, function (room_type_id, room_type_params) {
        room_type[room_type_id] = {
          number_of_occupants: guid_param == null ? room_type_params.rooms : { 1: {number_of_occupants: 1}},
          id: room_type_params.id,
          hotel_id: room_type_params.hotel_id,
          quantity: room_type_params.quantity,
          unit_price: room_type_params.unit_price,
          is_room_hb: room_type_params.is_room_hb,
          rooms: room_type_params.rooms,
          hotelbeds_room_codes: room_type_params.hotelbeds_room_codes,
          hotelbeds_board_codes: room_type_params.hotelbeds_board_codes,
          package_id: package_id,
        };
      });
      return room_type;
    }
    
    $scope.toggleDiscountCode = function () {
      $scope.toggle_discount_code = !$scope.toggle_discount_code;
    };

    $scope.toggleHaveTickets = function (have) {
      if (have && !$scope.haveTickets) {
        $scope.haveTickets = true;
        $scope.setStorage($scope.event_store.ALREADY_HAVE_TICKETS, $scope.haveTickets);
        $scope.timerStarted = false;
        $scope.selectedTickets.forEach(ticket => {
          $scope.releaseHoldSeat(ticket, false);
        });
        $scope.selectedTickets = [];
        $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
        $scope.active_ticket_select_method = $scope.ticket_select_method.MANUALLY_SELECT_SEATS;
        $scope.setStorage($scope.event_store.TICKETS_SELECTION_METHOD, $scope.active_ticket_select_method);
        $scope.removeAllTicketsFromOrder(function(rs) {
          bootbox.alert(rs.message);
        })
      }else if (!have && $scope.haveTickets) {
        $scope.haveTickets = false;
        $scope.setStorage($scope.event_store.ALREADY_HAVE_TICKETS, $scope.haveTickets);
        $scope.timerStarted = false;
        $scope.selectedTickets.forEach(ticket => {
          $scope.releaseHoldSeat(ticket, false);
        });
        $scope.selectedTickets = [];
        $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
        $scope.active_ticket_select_method = $scope.ticket_select_method.MANUALLY_SELECT_SEATS;
        $scope.setStorage($scope.event_store.TICKETS_SELECTION_METHOD, $scope.active_ticket_select_method);
      }
    };
    
    $scope.fetchQueryStrings = function () {
    var myCodes = new URLSearchParams(window.location.search).getAll('codes[]');
      myCodes && myCodes.forEach(code => {
        $scope.addDiscountCode(code);
      });
    };
    
    $scope.fireStepTrigger = function (stepname) {
      if (!$scope.gaClientId) {
        $scope.setGAClientId()
      }
      var opt = {
        step: $scope.tracking_steps.indexOf(stepname) + 1,
        step_option: stepname,
        referrer_url: $scope.referrerURL,
        client_id: $scope.gaClientId
      };
      $.post(
        $scope.event_booking_path + '/step_tracker',
        opt,
      );
    };
    
    $scope.hydrateScope = function () {
      $scope.confirm_pricings_transition = $scope.getStorage($scope.event_store.CONFIRM_PRICING);
      if ($scope.confirm_pricings_transition && $scope.confirm_pricings_transition.hasOwnProperty('event_discount_codes')) {
        $scope.discount_code = angular.copy($scope.confirm_pricings_transition.event_discount_codes);
      }
      return $scope.confirm_pricings_transition;
    };

    $scope.loadFlightData = function (storage_flight_data) {
      $scope.flight_data = angular.copy(storage_flight_data)
      if (storage_flight_data.state_id) {
        $scope.flight_state_id = storage_flight_data.state_id;
        if (storage_flight_data.selected_airport) {
          $scope.getStateAirports(storage_flight_data.state_id, storage_flight_data.selected_airport, true);
          $scope.selected_airport = storage_flight_data.selected_airport
          $scope.getAvailableFlightsDepartureDates(true);
        } else {
          $scope.getStateAirports(storage_flight_data.state_id, true);
        }
      }
      if (storage_flight_data.departure_date && storage_flight_data.return_date && $scope.flight_datepicker) {
        $scope.getAvailableFlights(storage_flight_data.departure_date, storage_flight_data.return_date, true)
      }
    }

    $scope.updateMap = function(unavailableSeats, start = false, fadeOut = false) {
      $scope.layout = JSON.parse(JSON.stringify($scope.empty_layout));
      unavailableSeats.map(function(seat) {
        if (seat.available) {
          seat.class = 'available cursor-pointer';

        } else {
          seat.class = 'unavailable'
          seat.desc = 'unavailable';

          if (seat.held_by_you) {
            seat.class = 'held-by-you cursor-pointer';
            seat.held_by_you = true;
            seat.trigger = 'hover';
            seat.content = 'held by you!';
            seat.desc = 'held by you!';
          }
        }
        $scope.layout[seat.row][seat.column] = seat
      });
      if ($scope.ticket_product.toggle_orphan_seats) {
        $scope.orphanSeatCheck(start, fadeOut);
      }else{
        start ? $scope.initializeOwl(): '';
        fadeOut ? $('.loading').fadeOut(): '';
      }
    }

    $scope.orphanSeatCheck = function(start = false, fadeOut = false) {
      let totalAvailableSeat = 0;
      let remaningHold = $scope.number_of_travellers - $scope.selectedTickets.length;
      let active_section = {};
      $scope.seating_sections.forEach(section => {
        if (section.id == $scope.active_ticket_section) {
          active_section = section
        }
      });
      Object.keys($scope.layout).forEach(availableRow => {
        Object.keys($scope.layout[availableRow]).forEach(availableColumn => {
          if($scope.layout[availableRow][availableColumn].available == true &&
            (!$scope.layout[availableRow][availableColumn].orphan ||
              $scope.layout[availableRow][availableColumn].orphan == false)) {
            totalAvailableSeat++;
          if($scope.selectedTickets.length == $scope.max_traveller) {
            $scope.layout[availableRow][availableColumn].desc = 'You have currently selected the maximum number of seats';
          }else if(remaningHold == 0) {
            $scope.layout[availableRow][availableColumn].desc = 'You need to increase the number of travellers';
          }
          }
        });
      });
      if ((active_section && totalAvailableSeat > active_section.orphan_seats_limit && totalAvailableSeat > 2) ||
          (!active_section && totalAvailableSeat == 0)) {
        $scope.selectedTickets.length > 0 && $scope.selectedTickets.forEach(ticket => {
          if($scope.active_ticket_section == ticket.seating_section_id) {
            let rightSelectedIndex = parseInt(ticket.column);
            let leftSelectedIndex = parseInt(ticket.column);
            let rightSelectedCount = 0;
            let leftSelectedCount = 0;
            while($scope.layout[ticket.row].hasOwnProperty(rightSelectedIndex + 1) && $scope.layout[ticket.row][rightSelectedIndex + 1].available == true) {
              rightSelectedIndex++;
              rightSelectedCount++;
            }
            while($scope.layout[ticket.row].hasOwnProperty(leftSelectedIndex - 1) && $scope.layout[ticket.row][leftSelectedIndex - 1].available == true) {
              leftSelectedIndex--;
              leftSelectedCount++;
            }
            if ((leftSelectedCount == 1 && rightSelectedCount >= 0) ||
                (rightSelectedCount == 1 && leftSelectedCount >= 0)) {
              Object.keys($scope.layout).forEach(allRow => {
                Object.keys($scope.layout[allRow]).forEach(allColumn => {
                  if ($scope.layout[allRow][allColumn].available == true) {
                    $scope.layout[allRow][allColumn].orphan = true;
                    $scope.layout[allRow][allColumn].class = 'orphan';
                    $scope.layout[allRow][allColumn].desc = 'You must select the single seat'
                  }
                });
              });
              let rightRowIndex = parseInt(ticket.column);
              let leftRowIndex = parseInt(ticket.column);
              if (rightSelectedCount == 1 && leftSelectedCount >= 0) {
                while($scope.layout[ticket.row].hasOwnProperty(rightRowIndex + 1) && $scope.layout[ticket.row][rightRowIndex + 1].available == true) {
                  $scope.layout[ticket.row][rightRowIndex + 1].orphan = false;
                  $scope.layout[ticket.row][rightRowIndex + 1].class = 'available cursor-pointer';
                  if(remaningHold > 0 && $scope.selectedTickets.length != $scope.max_traveller) {
                    delete $scope.layout[ticket.row][rightRowIndex + 1].desc;
                  }else if($scope.selectedTickets.length == $scope.max_traveller) {
                    $scope.layout[ticket.row][rightRowIndex + 1].desc = 'You have currently selected the maximum number of seats';
                  }else if(remaningHold == 0 && $scope.selectedTickets.length != $scope.max_traveller) {
                      $scope.layout[ticket.row][rightRowIndex + 1].desc = 'You need to increase the number of travellers';
                  }
                  rightRowIndex++;
                }
              }
              if (leftSelectedCount == 1 && rightSelectedCount >= 0) {
                while($scope.layout[ticket.row].hasOwnProperty(leftRowIndex - 1) && $scope.layout[ticket.row][leftRowIndex - 1].available == true) {
                  $scope.layout[ticket.row][leftRowIndex - 1].orphan = false;
                  $scope.layout[ticket.row][leftRowIndex - 1].class = 'available cursor-pointer';
                  if(remaningHold > 0 && $scope.selectedTickets.length != $scope.max_traveller) {
                    delete $scope.layout[ticket.row][leftRowIndex - 1].desc;
                  }else if($scope.selectedTickets.length == $scope.max_traveller) {
                    $scope.layout[ticket.row][leftRowIndex - 1].desc = 'You have currently selected the maximum number of seats';
                  }else if ( remaningHold == 0 && $scope.selectedTickets.length != $scope.max_traveller){
                      $scope.layout[ticket.row][leftRowIndex - 1].desc = 'You need to increase the number of travellers';
                  }

                  leftRowIndex--;
                }
              }
              $scope.force_select_orphan_seat = true;
              $scope.setStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT, true);
            }else{
              $scope.force_select_orphan_seat = false;
              $scope.setStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT, false);
            }
          }else{
            let force_orphan = $scope.getStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT);
            if (force_orphan) {
              Object.keys($scope.layout).forEach(allRow => {
                Object.keys($scope.layout[allRow]).forEach(allColumn => {
                  if ($scope.layout[allRow][allColumn].available == true) {
                    $scope.layout[allRow][allColumn].orphan = true;
                    $scope.layout[allRow][allColumn].class = 'orphan';
                    $scope.layout[allRow][allColumn].desc = 'You must select the single seat';
                  }
                });
              });
            }
          }
        });
        let force_orphan = $scope.getStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT);
        if (force_orphan && $scope.selectedTickets.length != 0) {
          fadeOut ? $('.loading').fadeOut(): '';
          start ? $scope.initializeOwl(): '';
          return false;
        }
        $scope.force_select_orphan_seat = false;
        $scope.setStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT, false);
        Object.keys($scope.layout).forEach(row => {
          Object.keys($scope.layout[row]).forEach(column => {
            if ($scope.layout[row][column].available == true) {
              let rightIndex = parseInt(column);
              let leftIndex = parseInt(column);
              let rightCount = 0;
              let leftCount = 0;
              remaningHold >= 0 ? remaningHold : 0;
              
              while($scope.layout[row].hasOwnProperty(rightIndex + 1) && $scope.layout[row][rightIndex + 1].available == true) {
                rightIndex++;
                rightCount++;
              }
              while($scope.layout[row].hasOwnProperty(leftIndex - 1) && $scope.layout[row][leftIndex - 1].available == true) {
                leftIndex--;
                leftCount++;
              }
              let availableSpace = leftCount + rightCount;

              if ((leftCount == 1 && rightCount >= 1) ||
                  (rightCount == 1 && leftCount >= 1) ||
                  (remaningHold < 2 && availableSpace == 1 )) {
                $scope.layout[row][column].orphan = true;
                $scope.layout[row][column].class = 'orphan';
                $scope.layout[row][column].desc = 'You must book a nearby seat or increase the number of travellers';
              }
            }
          });
        });
      }else{
        Object.keys($scope.layout).forEach(unlockRow => {
          Object.keys($scope.layout[unlockRow]).forEach(unlockColumn => {
            if($scope.layout[unlockRow][unlockColumn].available == true) {
              $scope.layout[unlockRow][unlockColumn].orphan = false;
              $scope.layout[unlockRow][unlockColumn].class = 'available cursor-pointer';
              $scope.force_select_orphan_seat = false;
              $scope.setStorage($scope.event_store.FORCE_SELECT_ORPHAN_SEAT, false);
              delete $scope.layout[unlockRow][unlockColumn].desc;
            }
          });
        });
      }
      start ? $scope.initializeOwl(): '';
      fadeOut ? $('.loading').fadeOut(): '';
    }

    $scope.shouldDisplayTicketTypes = function() {
      return $scope.number_of_travellers > 0
    }
    
    $scope.shouldDisplaySeatingSections = function() {
      return !!$scope.active_ticket_type && $scope.shouldDisplayTicketTypes()
    }

    $scope.shouldDisplaySeatingMap = function() {
      return !!$scope.active_ticket_type && !!$scope.active_ticket_section && $scope.number_of_travellers > 0
    }

    $scope.shouldUpdateSeatingMap = function() {
      return $scope.shouldDisplaySeatingMap() && $scope.current_step == $scope.step_keys.your_party
    }

    $scope.updateSeatingMapLoop = function() {
      if ($scope.shouldDisplaySeatingMap()) {

        $scope.updateAvailableSeats(function() {

          if ($scope.shouldUpdateSeatingMap()) {

            $scope.nextSeatingMapLoad();

          }

        })

      }
    }

    $scope.updateAvailableSeats = function(after, start = false, fadeOut = false) {
      if ($scope.active_ticket_section) {
        $http.post($scope.event_booking_path+'/unavailable_seats', {
          seating_section_id: $scope.active_ticket_section,
          hold_ids: $scope.selectedTickets.map(function(seat) { return seat.hold_id })
        })
        .then(function(res) {
          if (!start && fadeOut) {
            $scope.updateMap(res.data, start, fadeOut);
          }else if (start && fadeOut) {
            $scope.updateMap(res.data, start, fadeOut);
          }else{
            $scope.updateMap(res.data);
          }
          if (after) after()
        })
        .catch(function(res) {
          $scope.data = 'Server error';
          if (after) after()
        });
      }
    };

    $scope.holdSeat = function(seat) {
      $('.loading').fadeIn();
      var opt = {
        ticket_product_id: seat.ticket_product_id,
        ticket_type_id: seat.ticket_type_id,
        seating_section_id: seat.seating_section_id,
        row: seat.row,
        column: seat.column,
      };
      $.post(
        $scope.event_booking_path + '/user_hold',
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.success) {
              $scope.selectedTickets.push(rs.seat);
              $scope.layout_hash[rs.seat.row][rs.seat.column].hold_id = rs.seat.hold_id
              $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
              $scope.groupSelectedTickets();
              $scope.updateAvailableSeats(false, false, true);
              totalSelectedTicketsCost($scope.number_of_travellers);
              // if ($scope.number_of_travellers < $scope.selectedTickets.length) {
              //   $scope.increaseTravelers();
              // }
              if ($scope.shouldDisplayTimer()) {
                $scope.startTimer()
              }
            } else {
              console.error(rs.message);
            }
          });
        }
      )
    };

    $scope.releaseHoldSeat = function(seat, fade = true) {
      !$scope.timerExpired() && fade ? $('.loading').fadeIn() : '';
      var hold_id = '';
      $scope.selectedTickets.forEach(ticket => {
        if (ticket.row == seat.row && 
          ticket.column == seat.column && 
          ticket.seating_section_id == seat.seating_section_id && 
          ticket.ticket_type_id == seat.ticket_type_id) {
          hold_id = ticket.hold_id
        }
      });
      var opt = {
        seating_section_id: seat.seating_section_id,
        row: seat.row,
        column: seat.column,
        hold_id: hold_id
      };
      $.post(
        $scope.event_booking_path + '/user_release_hold',
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.success) {
              $scope.selectedTickets = $scope.selectedTickets.filter(ticket => {
                return ticket.hold_id != hold_id;
              });
              $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
              $scope.groupSelectedTickets();
              $scope.updateAvailableSeats(false, false, true);
              totalSelectedTicketsCost($scope.number_of_travellers);
              $scope.selectedTickets.length == 0 ? $scope.timerStarted = false: '';
            } else {
              $scope.selectedTickets = $scope.selectedTickets.filter(ticket => {
                if (ticket.row != seat.row || ticket.column != seat.column || ticket.seating_section_id != seat.seating_section_id || ticket.ticket_type_id != seat.ticket_type_id) { 
                  return ticket;
                }else{
                  return false;
                }
              });
              $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
              $scope.groupSelectedTickets();
              $scope.updateAvailableSeats(false, false, true);
              totalSelectedTicketsCost($scope.number_of_travellers);
              // $scope.decreaseTravelers();
              $scope.selectedTickets.length == 0 ? $scope.timerStarted = false: '';
              console.error(rs.message);
            }
          });
        }
      )
    };

    $scope.change_active_ticket_type = function(type, section = null) {
      $('.loading').fadeIn();
      $scope.destroyOwls();
      var opt = {
        ticket_type_id: type,
      };
      $.post(
        $scope.event_booking_path + '/ticket_type',
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.success) {
              $scope.active_ticket_type = type;
              if (!section) {
                $scope.active_ticket_section = null;
                $scope.timerStarted = false;
                $scope.selectedTickets.forEach(ticket => {
                  $scope.releaseHoldSeat(ticket, false);
                });
                $scope.selectedTickets = [];
              }
              $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
              $scope.seating_sections = rs.seating_sections;
              $scope.layout_hash = rs.layout_hash;
              $scope.layout = rs.layout_hash;
              $scope.row_symbols = rs.row_symbols;
              $scope.fetchTicketTypeInfo(type)
              $scope.empty_layout = {};
              Object.keys($scope.layout_hash).forEach((row_sym) => {
                $scope.empty_layout[row_sym] = {};
                Object.keys($scope.layout_hash[row_sym]).forEach((col_sym) => {
                  $scope.empty_layout[row_sym][col_sym] = {
                    available: true,
                    class: 'available cursor-pointer',
                    click: 'holdSeat()',
                    reserved: false,
                    held_by_you: false,
                    held_by_customer: false
                  }
                });
              });
              $scope.groupSelectedTickets();
          
              $scope.current_step != $scope.step_keys.payment ? totalSelectedTicketsCost($scope.number_of_travellers): totalSelectedTicketsCost($scope.total_paid);
              $scope.setStorage($scope.event_store.SELECTED_TICKETS_TYPE, type);
              !section ? $scope.removeStorage($scope.event_store.SELECTED_TICKETS_SECTION): '';
              section ? $scope.change_active_ticket_section(section): '';

              // add tickets without seats
              if (!$scope.has_reserved_seating) {
                $scope.selectedTicketsWithoutSeats = [ {ticket_type: $scope.active_ticket_type} ];
                $scope.setStorage($scope.event_store.SELECTED_TICKETS_WITHOUT_SEATS, $scope.selectedTicketsWithoutSeats);
              }
            } else {
              console.error(rs.message)
            }
          });
        }
      );
      $('.loading').fadeOut('slow');
    };

    $scope.change_active_ticket_section = function(section) {
      if (section) {
        $('.loading').fadeIn();
        $scope.destroyOwls();
        $scope.active_ticket_section = section;
        var opt = {
          seating_section_id: section,
        };
        $.post(
          $scope.event_booking_path + '/seating_section',
          opt,
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.layout_hash = rs.layout_hash;
                $scope.layout = rs.layout_hash;
                $scope.row_symbols = rs.row_symbols;
                $scope.empty_layout = {};
                Object.keys($scope.layout_hash).forEach((row_sym) => {
                  $scope.empty_layout[row_sym] = {};
                  Object.keys($scope.layout_hash[row_sym]).forEach((col_sym) => {

                    $scope.empty_layout[row_sym][col_sym] = {
                      available: true,
                      class: 'available cursor-pointer',
                      click: 'holdSeat()',
                      reserved: false,
                      held_by_you: false,
                      held_by_customer: false
                    }
                  });
                });

                $scope.setStorage($scope.event_store.SELECTED_TICKETS_SECTION, section);
                $scope.updateAvailableSeats(false, true, true);
                $scope.updateSeatingMapLoop();
                if ($scope.active_ticket_select_method == $scope.ticket_select_method.ASSIGN_BEST_AVAILABLE_SEATS) {
                  $scope.assignBestAvailableSeats();
                }
              } else {
                console.error(rs.message)
              }
            });
          }
        );
      }
    };

    $scope.fetchTicketTypeInfo = function(type) {
      $scope.ticket_types.forEach(ticket_type => {
        if (ticket_type.id == type) {
          $scope.active_tickets_type_name = ticket_type.name;
          $scope.active_tickets_type_price = ticket_type.master_price;
        }
      });
    }

    $scope.destroyOwls = function() {
      selecteor = 0;
      while($('#carousel_'+selecteor+'').length > 0) {
        let owl = $('#carousel_'+selecteor+'');
        owl.trigger('destroy.owl.carousel');
        selecteor++;
      }
    }

    $scope.initializeOwl = function() {
      selecteor = 0;
      // document.querySelectorAll('.seat').forEach(seat => {
      //   seat.className.includes("item") ? '' : seat.className += " item";
      // });
      while($('#carousel_'+selecteor+'').length > 0) {
        let owl = $('#carousel_'+selecteor+'');
        owl.owlCarousel({
          nav:true,
          //autoWidth:true,
          navSpeed: 50,
          slideBy: 4,
          touchDrag  : false,
          mouseDrag  : false,
          navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
          ],
          navContainer: '.content-'+selecteor+' .custom-nav',
          responsive:{
            0:{
                items:6,
                touchDrag  : true,
                mouseDrag  : true,      
            },
            600:{
                items:12,
                touchDrag  : true,
                mouseDrag  : true,      
            },
            1000:{
                items:22,
                touchDrag  : false,
                mouseDrag  : false,      
            }
          }
        });
       selecteor++;
     }
    }

    $scope.calculateTooltipPosition = function(el) {
      elPosition = el.getBoundingClientRect();
      parentPosition = el.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect();
      let marginOffsetLeft = 90 - (parseInt(elPosition.left) - parseInt(parentPosition.left));
      let marginOffsetRight = 90 - (parseInt(parentPosition.right) - parseInt(elPosition.right));

      marginOffsetLeft > 0 ? el.style.setProperty('--marginLeft', marginOffsetLeft + 'px') : 
      el.style.setProperty('--marginLeft', '-' + marginOffsetRight + 'px');
    }

    $scope.calculateCalendarPosition = function(start) {
      if (start) {        
        let flightDateDeparturePosition = $('#flight_date_departure').offset();
        let flightDateDepartureHeight = $('#flight_date_departure')[0].getBoundingClientRect().height;
        let calendarXPosition = parseInt(flightDateDeparturePosition.left);
        let calendarYPosition = parseInt(flightDateDeparturePosition.top) + parseInt(flightDateDepartureHeight);

        calendarXPosition > 0 ? $('.litepicker').css({'left': calendarXPosition + 'px'}) : '';
        calendarYPosition > 0 ? $('.litepicker').css({'top': calendarYPosition + 'px'}) : '';
      }else{
        let flightDateReturn = $('#flight_date_return').offset();
        let flightDateReturnHeight = $('#flight_date_return')[0].getBoundingClientRect().height;
        let calendarXPosition = parseInt(flightDateReturn.left);
        let calendarYPosition = parseInt(flightDateReturn.top) + parseInt(flightDateReturnHeight);

        calendarXPosition > 0 ? $('.litepicker').css({'left': calendarXPosition + 'px'}) : '';
        calendarYPosition > 0 ? $('.litepicker').css({'top': calendarYPosition + 'px'}) : '';
      }
    }

    $scope.groupSelectedTickets = function() {
      $scope.selectedTickets.sort(function (a, b) {
        return a.row.localeCompare(b.row) || a.column - b.column;
      });
      $scope.groupedSelectedTickets = $scope.selectedTickets.reduce((r, a) => {
        var section_name = '';
        var row_array = [];
        var section_cost = 0;
        $scope.seating_sections.forEach(section => {
          if (section.id == a.seating_section_id) {
            section_name = section.name;
            $scope.selectedTickets.forEach(seat => {
              if (seat.row == a.row && seat.seating_section_id == a.seating_section_id) {
                var seat_obj = {};
                seat_obj = Object.assign(seat_obj, seat)
                seat_obj.unit_price = parseFloat($scope.active_tickets_type_price);
                row_array.push(seat_obj);
              }
              if (seat.seating_section_id == a.seating_section_id) {
                //section_cost += parseFloat(seat.unit_price);
                section_cost += parseFloat($scope.active_tickets_type_price);
              }
            });
          }
        });
        r[section_name] = {...r[section_name] || [], 'amount': section_cost, [a.row]: row_array };
        return r;
      }, {});
    }

    function totalSelectedTicketsCost(total_paid = null) {
      $scope.totalTicketsCost = 0.0;
      if (!$scope.has_reserved_seating) {
        $scope.selectedTicketsWithoutSeats.forEach(ticket => {
          $scope.totalTicketsCost = parseFloat($scope.active_tickets_type_price) * $scope.number_of_travellers;
        });

        if ($scope.number_of_travellers != total_paid) {
          $scope.totalTicketsCost = ($scope.totalTicketsCost / $scope.number_of_travellers) * total_paid;
        }
      } else {
        $scope.selectedTickets.forEach(seat => {
          //$scope.totalTicketsCost += parseFloat(seat.unit_price);
          $scope.totalTicketsCost += parseFloat($scope.active_tickets_type_price);
        });
        if ($scope.number_of_travellers != total_paid) {
          $scope.totalTicketsCost = ($scope.totalTicketsCost / $scope.number_of_travellers) * total_paid;
        }
        $scope.calculatedSelectedTickets = $scope.selectedTickets.reduce((r, a) => {
          var section_name = '';
          var row_array = [];
          var section_cost = 0;
          
          $scope.seating_sections.forEach(section => {
            if (section.id == a.seating_section_id) {
              section_name =  section.name;
              var counter = 0;
              $scope.selectedTickets.forEach((seat, idx) => {
                if (seat.row == a.row && seat.seating_section_id == a.seating_section_id) {
                  if(counter >= $scope.total_paid) {
                    var seat_obj = {};
                    seat_obj = Object.assign(seat_obj, seat)
                    seat_obj.unit_price = 0.0;
                    row_array.push(seat_obj);
                  } else {
                    var seat_obj = {};
                    seat_obj = Object.assign(seat_obj, seat)
                    seat_obj.unit_price = parseFloat($scope.active_tickets_type_price);
                    row_array.push(seat_obj);
                  }
                }
                if (seat.seating_section_id == a.seating_section_id && counter < $scope.total_paid) {
                  // if ($scope.number_of_travellers != total_paid) {
                  //   section_cost += (parseFloat($scope.active_tickets_type_price) / $scope.number_of_travellers) * total_paid;
                  // }else{
                  //   section_cost += parseFloat($scope.active_tickets_type_price);
                  // }
                  section_cost += parseFloat($scope.active_tickets_type_price);
                }
                counter++;
              });
            }
          });
          r[section_name] = {...r[section_name] || [], 'amount': section_cost, [a.row]: row_array };
          return r;
        }, {});
      }
      return $scope.totalTicketsCost;
    };

    $scope.expireTicketedOrder = function() {
      $scope.selectedTickets.forEach(ticket => {
        $scope.releaseHoldSeat(ticket, false);
      });
      $scope.selectedTickets = [];
      $scope.removeStorage();
      $scope.setCurrentStep($scope.step_keys.your_party);

      $.post(
        $scope.event_booking_path + '/ticket_timer_expired',
        {},
        // function (rs) {
        //   window.location.href = $scope.event_booking_path;
        // }
      );      
    }

    $scope.updateTimer = function() {
      $scope.updateTimeRemaining()

      let minutes = Math.floor($scope.timeRemaining / 60000);
      let seconds = (($scope.timeRemaining % 60000) / 1000).toFixed(0);

      $scope.displayTime = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      // $scope.$apply()
    }

    $scope.updateTimeRemaining = function() {
      let ticket = $scope.ticketClosestToExpiration()

      if (ticket) {
        $scope.timeRemaining = (
          new Date(ticket.hold_acquired_at).getTime() 
          + ticket.customer_hold_period*1000
          ) 
          - Date.now()
      }
    }

    $scope.ticketClosestToExpiration = function() {
      let sortedTickets = $scope.selectedTickets.sort(function(b, a){
        return new Date(b.hold_acquired_at) - new Date(a.hold_acquired_at);
      });
      let firstTicket = sortedTickets[0];

      return firstTicket
    }

    $scope.startTimer = function() {
      if ($scope.timerStarted) return
      $scope.timerStarted = true
      
      let promise = $interval(
        function() {
          $scope.updateTimer()
          if ($scope.timerExpired() || $scope.timerStarted && !$scope.shouldDisplayTimer()) {
            // Timer is expired... kill it and start over booking flow

            $scope.timerStarted = false;
            $interval.cancel(promise)
            $('.loading').fadeIn('slow');
            $scope.expireTicketedOrder();
          } 
        },
        1000
      )
    }

    $scope.cancelNextSeatingMapLoad = function() {
      $timeout.cancel($scope.availableSeatsLoadPromise);
    };

    $scope.nextSeatingMapLoad = function(mill) {
      mill = mill || $scope.seatingMapUpdateInterval;
      //Always make sure the last timeout is cleared before starting a new one
      $scope.cancelNextSeatingMapLoad();
      $scope.availableSeatsLoadPromise = $timeout($scope.updateSeatingMapLoop, mill);
    };

    //Always clear the timeout when the view is destroyed, otherwise it will keep polling
    $scope.$on('$destroy', function() {
      $scope.cancelNextSeatingMapLoad();
    });

    $scope.data = 'Loading...';

    $scope.assignBestAvailableSeats = function() {
      $('.loading').fadeIn();
      var opt = {
        number_of_travellers: $scope.number_of_travellers,
        seating_section_id: $scope.active_ticket_section,
        selected_seats: $scope.selectedTickets.map(function(seat) { return seat })
      };
      $.post(
        $scope.event_booking_path + '/best_available_seats',
        opt,
        function (rs) {
          $timeout(function () {
            if (rs.success) {
              $scope.selectedTickets = [];
              rs.best_available_seats.forEach(seat => {
                $scope.selectedTickets.push(seat);
                $scope.layout_hash[seat.row][seat.column].hold_id = seat.hold_id;
              });
              $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
              $scope.groupSelectedTickets();
              $scope.updateAvailableSeats(false, false, true);
              totalSelectedTicketsCost($scope.number_of_travellers);
              $scope.active_ticket_select_method = $scope.ticket_select_method.ASSIGN_BEST_AVAILABLE_SEATS;
              $scope.setStorage($scope.event_store.TICKETS_SELECTION_METHOD, $scope.active_ticket_select_method);
              if ($scope.shouldDisplayTimer()) {
                $scope.startTimer()
              }
            } else {
              $scope.manuallySelectSeats();
              $('.loading').fadeOut();
              bootbox.alert(rs.message);
            }
          });
        }
      )
    }

    $scope.manuallySelectSeats = function() {
      $scope.active_ticket_select_method = $scope.ticket_select_method.MANUALLY_SELECT_SEATS;
      $scope.setStorage($scope.event_store.TICKETS_SELECTION_METHOD, $scope.active_ticket_select_method);
      $scope.timerStarted = false;
      $scope.selectedTickets.forEach(ticket => {
        $scope.releaseHoldSeat(ticket, false);
      });
      $scope.selectedTickets = [];
      $scope.setStorage($scope.event_store.SELECTED_TICKETS, $scope.selectedTickets);
    }

    $scope.forNextAvailableSeat = function(callback, start_row = null, start_column = null) {
      seat = $scope.findNextAvailableSeat(start_row, start_column)

      return callback(seat)
    }

    $scope.findNextAvailableSeat = function(start_row = null, start_column = null) {
      available_seat = null

      $scope.iterateSeatingLayout(function(
          seat, seat_state, row_symbol, column_symbol, row
        ) {

          if (seat_state.available && !available_seat) {
            available_seat = seat
            return true;
          }

        },
        start_row,
        start_column,
        true
      )

      return available_seat
    }

    $scope.iterateSeatingLayout = function(callback, start_row = null, start_column = null, stop_at_false_callback = false) {
      let row_symbols_to_iterate = [...$scope.row_symbols],
          iteration_method = 'forEach'

      if (stop_at_false_callback) {
        iteration_method = 'some'
      }

      if(start_row) {
        let start_idx = row_symbols_to_iterate.indexOf(start_row)+1
        row_symbols_to_iterate = row_symbols_to_iterate.slice(start_idx)
      }

      row_symbols_to_iterate[iteration_method](function(row_symbol) {
        let row = $scope.layout_hash[row_symbol],
            column_symbols_to_iterate = Object.keys(row)

        if(start_column) {
          let start_idx = column_symbols_to_iterate.indexOf(start_column)+1
          column_symbols_to_iterate = column_symbols_to_iterate.slice(start_idx)
        }

        col_iterator_result = column_symbols_to_iterate[iteration_method](function(column_symbol) {
          let seat = $scope.layout_hash[row_symbol][column_symbol],
              seat_state = $scope.layout[row_symbol][column_symbol] 

          return callback(seat, seat_state, row_symbol, column_symbol, row)
        })

        return col_iterator_result
      })
    }

    $scope.shouldDisplayTimer = function() {
      return $scope.has_ticket_products && $scope.selectedTickets.length > 0
    }

    $scope.timerExpired = function() {
      return $scope.timeRemaining < 0
    }

    $scope.setGAClientId = function() {
      if (window.hasOwnProperty('ga') && 
          window.ga !== undefined && 
          typeof window.ga === "function" &&
          typeof window.ga.getAll === "function" &&
          window.ga.getAll()[0]) {

        $scope.gaClientId = window.ga.getAll()[0].get('clientId');

      }
    }

    $scope.onReady = function() {
      if (window.isDisplayLocalize && Localize) {
        Localize.on('setLanguage', function({ to }) {
          $scope.user_locale = to;
        });
      };
      if ($scope.amend_order != null) {
        if (Object.keys($scope.flight_ticket_data).length > 0) {
          $scope.flight_ticket = angular.copy($scope.flight_ticket_data)
          $scope.flight_data = angular.copy($scope.flight_ticket_data.flight_data)
          $scope.setStorage($scope.event_store.FLIGHT_DATA, $scope.flight_ticket_data);
        }
      }
      $scope.ampEventProps = {
        eventName: $scope.event.name,
        eventId: $scope.event.id,
        urlSlug: $scope.event.slug,
        urlSlugReferrer: getParams('urlslug')
      }
      Amplitude.logEvent('Amplitude loaded', $scope.ampEventProps)
      var storageTrackedStep = $scope.getStorage($scope.event_store.CURRENT_TRACKED_STEP);
      if (!storageTrackedStep) {
        Amplitude.logEvent('Viewed Your Party Page', $scope.ampEventProps)
      }
      let storage_selected_tickets = $scope.getStorage($scope.event_store.SELECTED_TICKETS);
      let storage_selected_tickets_without_seats = $scope.getStorage($scope.event_store.SELECTED_TICKETS_WITHOUT_SEATS)
      let storage_number_of_travellers = $scope.getStorage($scope.event_store.NUMBER_OF_TRAVELLERS);
      let storage_already_have_tickets = $scope.getStorage($scope.event_store.ALREADY_HAVE_TICKETS);
      let storage_selected_tickets_type = $scope.getStorage($scope.event_store.SELECTED_TICKETS_TYPE);
      let storage_tickets_selection_method = $scope.getStorage($scope.event_store.TICKETS_SELECTION_METHOD);
      let storage_selected_tickets_section = $scope.getStorage($scope.event_store.SELECTED_TICKETS_SECTION);
      let storage_selected_packages = $scope.getStorage($scope.event_store.SELECTED_PACKAGES);
      let storage_flight_data = $scope.getStorage($scope.event_store.FLIGHT_DATA);
      $scope.amplitude_device_id = Amplitude.getDeviceId();

      if ($scope.has_flight_products) {
        $scope.steps = ['Your Party', 'Package', 'Flight Selection', 'Add-on', 'Confirm', 'Payment'];
        $scope.step_keys = {
          your_party: 0,
          package: 1,
          flight_ticket: 2,
          addon: 3,
          confirm: 4,
          payment: 5,
        };
        $scope.step_pages = [
          { url: 'parties', title: 'Party' },
          { url: 'packages', title: 'Packages' },
          { url: 'flight-ticket', title: 'Flight-ticket' },
          { url: 'add-ons', title: 'Add-ons' },
          { url: 'confirm', title: 'Confirm' },
          { url: 'confirm-checkout', title: 'Confirm Checkout' },
          { url: 'checkout', title: 'Checkout' },
        ];
        $scope.tracking_steps = [
          'Your Party', 
          'Package', 
          'Package RoomType Select', 
          'Flight ticket', 
          'Add-on', 
          'Confirm', 
          'Confirm Checkout', 
          'Payment', 
          'Payment Success'
        ];
        let DateTime = luxon.DateTime;
        const dt = DateTime.local();
        $scope.startDate = dt;
      }
      if (storage_selected_tickets) {
        $scope.selectedTickets = angular.copy(storage_selected_tickets);
      }
      if (storage_selected_tickets_without_seats) {
        $scope.selectedTicketsWithoutSeats = angular.copy(storage_selected_tickets_without_seats);
      }
      if (storage_number_of_travellers) {
        $scope.number_of_travellers = angular.copy(storage_number_of_travellers);
      }
      // Sometimes when the page is initialized, $scope.number_of_travellers will not be rendered to the view
      // so update the view here
      $scope.$applyAsync();
      if (storage_selected_tickets_section) {
        $scope.change_active_ticket_type(storage_selected_tickets_type, storage_selected_tickets_section);
        $scope.fetchTicketTypeInfo(storage_selected_tickets_section);
      } else if(!storage_selected_tickets_section && storage_selected_tickets_type) {
        $scope.change_active_ticket_type(storage_selected_tickets_type);
        $scope.fetchTicketTypeInfo(storage_selected_tickets_section);
      }
      if (storage_already_have_tickets) {
        $scope.haveTickets = angular.copy(storage_already_have_tickets);
      }
      if (storage_selected_packages) {
        $scope.selected_packages = angular.copy(storage_selected_packages);
      }
      if (storage_tickets_selection_method) {
        $scope.active_ticket_select_method = angular.copy(storage_tickets_selection_method);
      }else{
        $scope.active_ticket_select_method = $scope.ticket_select_method.MANUALLY_SELECT_SEATS;
      }
      if ($scope.amend_order_travelers) {
        $scope.number_of_travellers = angular.copy($scope.amend_order_travelers);
        if ($scope.event_customer_types.length > 0) {
          $scope.selected_packages[$scope.event_customer_types[0].singular_name].quantity = angular.copy($scope.amend_order_travelers);
        }
      }

      $scope.groupSelectedTickets();
      recalculatePaymentAmount($scope.number_of_travellers);
      if (Object.keys($scope.billing_address).length > 1) {
        $scope.getBillingStates($scope.billing_address.country_id);
      }
      if (Object.keys($scope.shipping_address).length > 1) {
        $scope.getShippingStates($scope.shipping_address.country_id);
      }
      //$scope.hydrateScope();
      $scope.setCurrentTrackedStep();
      //Start polling the data from the server
      var billing_phone_input = document.querySelector("#billing_address_phone_number"),
      errorMsgBilling = document.querySelector("#error-msg-billing"),
      validMsgBilling = document.querySelector("#valid-msg-billing");
      
      var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
      var iti = window.intlTelInput(billing_phone_input, {
        initialCountry: "us",
        nationalMode: true,
        utilsScript: "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544"
      });
      var reset = function() {
        billing_phone_input.classList.remove("error");
        errorMsgBilling.innerHTML = "";
        errorMsgBilling.classList.add("hidden");
        validMsgBilling.classList.add("hidden");
      };
      billing_phone_input.addEventListener('blur', function() {
        reset();
        if (billing_phone_input.value.trim()) {
          if (iti.isValidNumber()) {
            validMsgBilling.classList.remove("hidden");
          } else {
            billing_phone_input.classList.add("error");
            var errorCode = iti.getValidationError();
            errorMsgBilling.innerHTML = errorMap[errorCode];
            errorMsgBilling.classList.remove("hidden");
          }
        }
        $scope.billing_address.phone_number = iti.getNumber();
      });
      billing_phone_input.addEventListener('change', reset);
      billing_phone_input.addEventListener('keyup', reset);
      $scope.getBillingSelectedCountry = function () {
        return iti.getSelectedCountryData();
      }

      if (document.querySelector("#shipping_address_phone_number")) {
        var shipping_phone_input = document.querySelector("#shipping_address_phone_number"),
        errorMsgShipping = document.querySelector("#error-msg-shipping"),
        validMsgShipping = document.querySelector("#valid-msg-shipping");

        var iti_shipping = window.intlTelInput(shipping_phone_input, {
          initialCountry: "us",
          nationalMode: true,
          utilsScript: "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544"
        });
        var resetShipping = function() {
          shipping_phone_input.classList.remove("error");
          errorMsgShipping.innerHTML = "";
          errorMsgShipping.classList.add("hidden");
          validMsgShipping.classList.add("hidden");
        };
        shipping_phone_input.addEventListener('blur', function() {
          resetShipping();
          if (shipping_phone_input.value.trim()) {
            if (iti_shipping.isValidNumber()) {
              validMsgShipping.classList.remove("hidden");
            } else {
              shipping_phone_input.classList.add("error");
              var errorCode = iti_shipping.getValidationError();
              errorMsgShipping.innerHTML = errorMap[errorCode];
              errorMsgShipping.classList.remove("hidden");
            }
          }
          $scope.shipping_address.phone_number = iti_shipping.getNumber();
        });
        shipping_phone_input.addEventListener('change', resetShipping);
        shipping_phone_input.addEventListener('keyup', resetShipping);
        $scope.setShippingCountryFlag = function (code) {
          return iti_shipping.setCountry(code);
        }  
      }
      if (document.querySelector("#seating_section")) {
        $("#seating_section").select2();
      }

      if (document.querySelector("#flight_date_departure") && document.querySelector("#flight_date_return")) {
        $scope.flight_datepicker = new Litepicker({
          element: document.querySelector('#flight_date_departure'),
          elementEnd: document.querySelector('#flight_date_return'),
          singleMode: false,
          allowRepick: false,
          scrollToDate: true,
          format: 'DD-MM-YYYY',
          tooltipText: {
            one: 'Day',
            other: 'Days',
          },
          tooltipNumber: (totalDays) => {
            return totalDays - 1;
          },
          lockDaysFilter: (d1, d2, pickedDates) => {
            if (pickedDates.length > 0) {
              if (pickedDates[0] && !pickedDates[1] || pickedDates[0] && pickedDates[1].dateInstance == 'Invalid Date') {
                return !$scope.available_return_date.includes(d1.format('DD-MM-YYYY'));
              }
              if (pickedDates[0] && pickedDates[1] && pickedDates[1].dateInstance != 'Invalid Date') {
                return false;
              }
            }
            return !$scope.available_departure_date.includes(d1.format('DD-MM-YYYY'));
          },
          setup: (picker) => {
            picker.on('preselect', (date1, date2) => {
              if (!date2) {
                $scope.getAvailableFlightsReturnDates(date1.format('DD-MM-YYYY'));
                $scope.calculateCalendarPosition(false);
              }
            }),
            picker.on('selected', (date1, date2) => {
              if (date1 && date2) {
                $scope.getAvailableFlights(date1.format('DD-MM-YYYY'), date2.format('DD-MM-YYYY'));
              }
            }),
            picker.on('hide', () => {

              let departureDateField = $('#flight_date_departure').val();
              let returnDateField = $('#flight_date_return').val();  
              $scope.flight_datepicker.setOptions({
                minDate: $scope.firstDepartureDate,
                maxDate: $scope.lastDepartureDate,
                startDate: $scope.firstDepartureDate,
                endDate: $scope.lastDepartureDate,
              });
              $scope.flight_datepicker.clearSelection();
              if (departureDateField.length > 0 && returnDateField.length > 0) {
                $scope.flight_datepicker.setDateRange(departureDateField, returnDateField, true);
                $('#flight_date_departure').val(departureDateField);
                $('#flight_date_return').val(returnDateField);  
              }
            });
          },
        });
        if (!$scope.pre_booking_data && storage_flight_data) {
          $scope.loadFlightData(storage_flight_data)
        }
      }

      // Update the time remaining every second
      if ($scope.shouldDisplayTimer()) {
        $scope.startTimer()
      }

      var modal = document.getElementById('myModal');
      var img = document.getElementById('preview-ticket_level_map');
      var modalImg = document.getElementById("img01");
      if (modal && img && modalImg) {
        img.onclick = function(){
            modal.style.display = "block";
            modalImg.src = this.src;
        }
        modal.onclick = function() {
            img01.className += " out";
            setTimeout(function() {
              modal.style.display = "none";
              img01.className = "modal-content";
            }, 400);
        }
      }
    }
  },
  ]);
}.call(this));
